/**
 * @param {string} props.label -> The text that is displayed to the left of the Toggle Switch
 * @param {boolean} props.disabled -> if true, the Toggle Switch will be grayed-out and not be clickable. This will prevent the `action` from running
 * @param {boolean} props.checked -> wether or not the switch is active
 * @param {function} props.action -> The callback function that runs when the Toggle Switch is clicked
 * @param {string} props.className -> Used to add any default classNames to the element. The styling of those elements should overwrite any default stylings.
 *
 * @description This is the Toggle Switch component. It should be used wherever a Toggle Switch is needed so as to maintain common styling. This Toggle Switch has a stylesheet at `/styles/components/toggleSwitch.scss` which can be used for reference.
 */

import React from 'react';
import PropTypes from 'prop-types';

function ToggleSwitch({ label, disabled, checked, action, className }) {
  return (
    <div className={`ToggleSwitch ${className}`}>
      <label className="switch">
        <input type="checkbox" disabled={disabled} checked={checked} onChange={action} />
        <span className="slider round"></span>
      </label>
      <label className="switch-label">{label}</label>
    </div>
  );
}

ToggleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  action: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ToggleSwitch;
