import React from 'react';
import MyContext from './MyContext';
import Api from './services/ApiHandler';
import LocalStore from './services/LocalStorage';
import { S3Upload, TranscoderUpload } from './services/UploaderService';
import PubNubManager from './services/PubNub';
import each from 'lodash/each';
import generateUuid from './utils/generateUuid';
import LoadingSpinner from './images/spinner.gif';

import getFormattedPlatformName from './utils/getFormattedPlatformName';

class MyProvider extends React.Component {
  constructor(props) {
    super(props);
    const userData = LocalStore.getItem('userData');
    const topics = LocalStore.getItem('topics');
    const activeGroup = LocalStore.getItem('activeGroup');
    const activeTopic = LocalStore.getItem('activeTopic');
    this.isMobileSizedScreen = window.innerWidth <= 850;

    // initialize PubNub Manager if we have an auth user stored in localstore
    // userData.id    //
    if (userData) {
      PubNubManager.init(userData.id, this.updateGlobalStateFromPubNub.bind(this));
    }

    this.state = {
      loggedIn: userData,
      messageModalText: null,
      messageModalChild: null,
      showLoadingGif: false,
      groupDeepLink: null,
      groups: null, // this will turn into an array once the sidebar has fetched a groups list
      topics: topics ? topics : [],
      videos: [],
      directMessages: [],
      directMessagesCursor: null,
      loadingDirectMessages: false,
      loadingGroup: false,
      groupIdForLoadingTopics: null,
      activeGroup: activeGroup,
      activeTopic: activeTopic,
      userData,
      notificationSettings: {},
      topbarIsOpen: true,
      isQueueRunning: false,
      mediaUploadQueue: [],
      showCreateGroupModal: false,
      showGroupInviteModal: false,
      localVideo: LocalStore.getItem('localVideo'),
      flaggedVideos: [],
    };
  }
  // Function is only used by PubNubManager
  // Updates topics global state and local store
  // TODO: move this to another file?
  async updateGlobalStateFromPubNub(message, action, eventType) {
    let directMessage;
    let topics;
    let topic;
    if (eventType === PubNubManager.EVENT_TYPES.VIDEO_EVENT) {
      topics = LocalStore.getItem('topics');
      topic = LocalStore.getItem('activeTopic');

      // update preview videos
      if (action === PubNubManager.ACTIONS.CREATE) {
        each(topics, (eachTopic, i) => {
          each(message.videoRelationships.dynamicConversations, conId => {
            if (eachTopic.id === conId) {
              // here we are resetting topic to the eachTopic (global list of topics) to handle cases where mid-upload the user navigates to another topic or group. doing this will allow the blob file to be removed locally in every case, and should stop the ERR_FILE_NOT_FOUND issue we had for so long.
              topic = eachTopic;
              topics[i].previewVideos.unshift({
                id: message.video.id,
                streams: message.video.streams,
              });
            }
          });
        });

        this.setGlobalState('topics', topics);
        this.setGlobalState('activeTopic', topic);
        LocalStore.setItem('topics', topics);
      }
    } else if (eventType === PubNubManager.EVENT_TYPES.DIRECT_MESSAGE_EVENT) {
      console.log(`MyProvider: ${eventType}, directMessageId: ${message.video.parentId}`);
      const directMessages = this.state.directMessages;

      // update preview videos
      if (action === PubNubManager.ACTIONS.CREATE) {
        each(directMessages, (dm, i) => {
          if (dm.directMessageId === message.video.parentId) {
            directMessage = directMessages[i];
            directMessages[i].previewVideos.unshift({
              id: message.video.id,
              streams: message.video.streams,
            });
          }
        });

        this.setGlobalState('directMessages', directMessages);
      }
    }

    // update videos global state
    const videos = this.state.videos;

    if (
      (message.video && topic && message.video.parentId === topic.id) ||
      (directMessage && message.video.parentId === directMessage.directMessageId)
    ) {
      // get video details from server
      const details = await Api.getVideoDetails(message.video.id);

      videos.unshift(details.data.video);

      this.setGlobalState('videos', videos);

      // if we get an event for the video we're storing locally, that means it
      // uploaded successfully, and we can stop storing it locally
      const localVid = this.state.localVideo;

      if (localVid && localVid.id === message.video.id) {
        URL.revokeObjectURL(localVid.localPlayUrl);
        LocalStore.removeItem('localVideo');
        this.setState({ localVideo: null });
      }
    }
  }

  setGlobalState(name, data) {
    this.setState({ [name]: data });
  }

  // any task that needs to 'await' setState to finish should use this function instead
  setStateSync(updates) {
    return new Promise(resolve => {
      this.setState(updates, resolve);
    });
  }

  toggleLoggedIn = bool => {
    this.setState({
      loggedIn: bool !== undefined ? bool : !this.state.loggedIn,
    });
  };

  showLoadingGif() {
    if (!this.state.showLoadingGif) {
      this.setState({ showLoadingGif: true });
    }
  }

  closeLoadingGif() {
    this.setState({ showLoadingGif: false });
  }

  setGroupDeepLink(groupDeepLink) {
    this.setState({ groupDeepLink });
  }

  setActiveGroup = async (group, redirectPath, noRedirect) => {
    let { groups, loggedIn } = this.state;

    // if the user has no groups, open create group modal
    if (!groups || !groups.length) {
      this.setState({ showCreateGroupModal: true });

      return null;
    }

    // if no group was passed in, default to first one in the list
    if (!group) {
      group = groups[0];
    }

    groups = groups.map(gp => {
      if (group.id === gp.id) {
        gp.selected = true;
      } else {
        gp.selected = false;
      }

      // update 'hasNewContent' flag on new active group
      if (gp.id === group.id) gp.hasNewContent = false;

      return gp;
    });

    this.setState({
      groups,
    });

    if (group && group.id) {
      // save active group in storage and on state
      LocalStore.setItem('activeGroup', group);
      window.dispatchEvent(new Event('edconnect:active-group:change'));
      const members = await this.fetchGroupUsers(false, null, group.id, true);
      group.members = members;

      await this.setState(
        {
          loadingGroup: true,
          activeGroup: group,
        },
        async () => {
          // once that's done, load data for the active group
          await Api.setLastVisitedForGroup(group.id);
          await this.fetchGroupDetails(group.id);
          await this.getDirectMessages();

          // set loading to false and navigate to the group page
          this.setState({ loadingGroup: false });

          if (!noRedirect) {
            // if the user is logged out for any reason, redirect to the login page
            if (!loggedIn) return this.props.history.push('/');

            // otherwise, redirect to the new active group
            this.props.history.push(redirectPath || `/group/${group.id}`);
          }
        },
      );
    }
  };

  deepLinkToGroup() {
    // otherwise, navigate to the deep link in a new tab/window
    window.open(this.state.groupDeepLink, '_blank');
  }
  fetchSidebarGroups = async () => {
    const groupsResult = await Api.getMyGroupsList();
    if (groupsResult.error || !groupsResult.data) {
      return console.log('error fetching sidebar groups: ', groupsResult.error);
    }

    try {
      const groups = groupsResult.data.groupList.items;
      this.setState({
        groups,
        videos: [],
      });
      LocalStore.setItem('groups', groupsResult);

      // if we have an active group saved in local storage, make sure the info is up to date
      const activeGroup = LocalStore.getItem('activeGroup');
      if (activeGroup) {
        const updatedActiveGroup = groups.find(group => group.id === activeGroup.id);
        LocalStore.setItem('activeGroup', updatedActiveGroup);
      }

      return groups;
    } catch (e) {
      return null;
    }
  };

  fetchGroupDetails = async id => {
    const groupDetails = await Api.getGroupInfo(id);
    LocalStore.setItem('activeGroup', groupDetails.data.getGroup);
    await this.setStateSync({
      activeGroup: groupDetails.data.getGroup,
      videos: [],
    });
  };

  async fetchPendingGroupMembers(id) {
    const { data, error } = await Api.getPendingGroupMembers(null, id);
    if (error) {
      alert(error.message);
    } else {
      const newState = this.state;
      newState.activeGroup.pendingMembers = data.pendingGroupMembers.items;
      await this.setStateSync(newState);
      LocalStore.setItem('activeGroup', this.state.activeGroup);
    }
  }
  // note* if you set includeAdmins to false then the items will have all members.. including admins
  // if you set it as true, admins come back in a separate field called admins and items has all members
  // that are NOT admins.. Admins are brought down also in the group info call.. so just leave it as true
  // for now and just concat both groups if you need both.
  fetchGroupUsers = async (includeAdmins, cursor, id, noUpdate) => {
    const { data, error } = await Api.getGroupUsers(includeAdmins, cursor, id);

    // handle errors from the server
    if (error) {
      // if we tried to get users for a group that we don't have access to, redirect to the first group in the user's list
      if (error.message && error.message.match(/Forbidden/)) this.setActiveGroup();
    }

    // end if no data comes back so the app doesn't die
    if (!data || !data.groupUsers) return;

    if (!noUpdate) {
      const updatedActiveGroup = this.state.activeGroup;
      updatedActiveGroup.members = data.groupUsers.items;

      await this.setStateSync({ activeGroup: updatedActiveGroup });
      LocalStore.setItem('activeGroup', updatedActiveGroup);
    }

    return data.groupUsers.items;
  };

  fetchTopics = async id => {
    // if this gets called multiple times for the same group, only run the first time
    if (this.state.groupIdForLoadingTopics === id) return;

    await this.setStateSync({ groupIdForLoadingTopics: id });

    try {
      const { error, data } = await Api.getGroupConversations(id);

      if (error) {
        console.error(error.message);
      }

      const { items, cursor } = data.groupConversations;

      const topics = items.map(topic => {
        // figure out a display name (do it here to avoid doing it each time we render the topics)
        let displayName = topic.name || '';
        if (displayName.length > 40) displayName = displayName.substring(0, 37) + '...';
        topic.displayName = displayName;

        return topic;
      });

      await this.setStateSync({ topics });
      LocalStore.setItem('topics', topics);

      if (cursor) {
        console.log(`cursor: ${cursor}`);
        await this.fetchMoreTopics(id, cursor);
      }

      // once we're done, set this to false so the next call to this function will run
      await this.setStateSync({ groupIdForLoadingTopics: null });
    } catch (e) {
      console.log(e);
      //
    }
  };

  fetchMoreTopics = async (id, cursor) => {
    try {
      let items;
      const { error, data } = await Api.getGroupConversations(id, cursor);
      if (error) {
        alert(error.message);
      }

      items = this.state.topics.concat(data.groupConversations.items);
      await this.setStateSync({
        topics: items,
      });
      LocalStore.setItem('topics', items);
    } catch (e) {
      //
    }
  };

  createNewGroup = async groupData => {
    const createdGroup = await Api.createGroup({
      name: groupData.name.trim(),
      description: groupData.description || null,
      appPlatform: getFormattedPlatformName().toUpperCase(),
    });

    // if the user has included a profile picture, now is the time to upload it
    if (groupData.img !== null) {
      // get the upload url for s3
      const profilePictureResponse = await Api.publicImageUrl();
      const uploadUrl = profilePictureResponse.data.publicImageUrl.uploadUrl.url;
      await S3Upload(groupData.img, uploadUrl);
      // now that we have the image back from s3, update the group to have the new profile image
      await this.updateGroup(
        createdGroup.data.createGroup.group.id,
        createdGroup.data.createGroup.group.name,
        createdGroup.data.createGroup.group.description,
        profilePictureResponse.data.publicImageUrl.downloadUrl,
      );
    }

    return await this.fetchSidebarGroups();
  };

  updateGroup = async (id, name, description, publicImageUrl) => {
    const { results, error } = await Api.updateGroup({
      id,
      name,
      description,
      publicImageUrl,
    });

    if (error) {
      alert(error.message);
    } else {
      console.log(`updated Group Results: ${results}`);
      return results;
    }
  };

  clearConversationVideos = async () => {
    await this.setStateSync({ videos: [] });
  };

  tagUsers = async (videoId, userIds) => {
    return await Api.tagUsersInVideo({
      videoId,
      userIds,
    });
  };

  untagUsers = async (videoId, userIds) => {
    return await Api.untagUsersInVideo({
      videoId,
      userIds,
    });
  };

  /**
   * @name uploadMedia
   * @description begin uploading the first media object in the queue. once the upload has completed, remove the media object from the queue, and recursively call self to attempt to upload the next media object (if exists)
   */
  uploadMedia = async () => {
    const { mediaUploadQueue } = this.state;

    // there is at least 1 media object that needs to be uploaded
    if (mediaUploadQueue.length) {
      // set the state of the queue to `running` (prevents 2 queues running at the same time)
      await this.setState({ isQueueRunning: true });
      const media = mediaUploadQueue[0];

      if (media.localMediaType === 'VIDEO') {
        // upload the video
        // NOTE: the upload url is created before the video is added to the queue, so we can access it on the video object
        await S3Upload(media.data, media.upload.url);
      } else if (media.localMediaType === 'AUDIO') {
        // upload the audio
        await TranscoderUpload(
          {
            image: media.image,
            audio: media.audio,
            uuid: media.uuid,
            duration: media.duration,
            type: 'IMAGE_AND_AUDIO',
          },
          process.env.REACT_APP_TRANSCODE_URL + '/convertImage',
        );
      } else if (media.localMediaType === 'TEXT') {
        await TranscoderUpload(
          {
            image: media.image,
            uuid: media.uuid,
            duration: media.duration,
            type: 'IMAGE',
          },
          process.env.REACT_APP_TRANSCODE_URL + '/convertImage',
        );
      }

      // now that the video is done being uploaded, remove the video from the queue
      await this.removeMediaFromUploadQueue();

      // call self to start uploading next video
      await this.uploadMedia();
    } else {
      // stop the queue from running
      await this.setState({ isQueueRunning: false });
    }

    return true; // the upload queue is empty
  };

  /**
   * @name addMediaToUploadQueue
   *
   * @params {Video} video - the video to be uploaded
   * @params {Audio} audio - the audio to be uploaded
   * @params {Text} text - the text to be uploaded
   * @example video = {
   *    topicId: UUID,
   *    topicName: String,
   *    groupId: UUID,
   *    type: String, // directMessage
   *    groupName: String,
   *    uploadDimensions: { height: Number, width: Number }
   *    data: Blob || File,
   * }
   * @example audio = {
          image: Blob || File,
          audio: Blob || File,
          mediaType: String,
          uuid: UUID,
          type: String, // directMessage
          duration: Number,
          groupName: String,
          topicName: String
        }
   * @example text = {
          image: Blob || File,
          duration: Number,
          mediaType: String,
          uuid: UUID,
          type: String, // directMessage
          duration: Number,
          groupId: String,
          topicId?: String,
          directMessageId?: String,
          uploadDimensions: { height: Number, width: Number }
          groupName: String,
          topicName: String
        }
   *
   * @description Create a video promise on the backend, save a copy of the video locally, then add the video to the upload queue. this is what is called from the Record screen when the `upload` button is pressed (either file or recorded video).
   *
   *
   * @summary When called, this function will create a video promise on the server/db, save a copy locally, add the video to the upload queue, then call the uploadMedia function to run against the queue. That function will then handle all queue video uploads
   */
  addMediaToUploadQueue = async ({ video, audio, text }) => {
    const { mediaUploadQueue, isQueueRunning } = this.state;

    const createMediaPromiseResult = await this.createVideoPromise(video || audio || text);
    let updatedMedia;

    // add info from the server's videoPromise response to the video object to be stored locally
    // we also add the upload info here (createMediaPromiseResult) so the upload queue can access it
    if (video) {
      updatedMedia = {
        ...video,
        ...createMediaPromiseResult,
        localMediaType: 'VIDEO',
        isLocal: true,
        thumbnailUrl: { url: LoadingSpinner },
      };
    } else if (audio) {
      updatedMedia = {
        ...audio,
        ...createMediaPromiseResult,
        localMediaType: 'AUDIO',
        isLocal: true,
        thumbnailUrl: { url: LoadingSpinner },
      };
    } else if (text) {
      updatedMedia = {
        ...text,
        ...createMediaPromiseResult,
        localMediaType: 'TEXT',
        isLocal: true,
        thumbnailUrl: { url: LoadingSpinner },
      };
    }

    if (video) {
      // When a user uploads a video, they are redirected to the playback screen, where they can watch it.
      // So we save the uploading video as the localVideo on globalState & localStorage for easy access
      LocalStore.setItem('localVideo', updatedMedia);
      this.setState({ localVideo: updatedMedia });
    }

    // update queue with newest video
    mediaUploadQueue.push(updatedMedia); // add video to the end of the queue
    await this.setState({ mediaUploadQueue });

    // if there is not already a queue running, start video uploading
    if (!isQueueRunning) {
      this.uploadMedia();
    }
  };

  // handles the interaction with the server to create a video Promise, and returns the result
  async createVideoPromise(video) {
    const uuid = generateUuid();

    let createVideoPromiseInput = {
      uuid,
      size: video.uploadDimensions,
      mediaType: video.mediaType,
      appPlatform: getFormattedPlatformName().toUpperCase(),
    };

    if (video.attachments) {
      createVideoPromiseInput.attachments = video.attachments;
    }

    if (video.textOnVideo) {
      createVideoPromiseInput.textOnVideo = video.textOnVideo;
    }

    // add specific input params based on video type
    if (video.type === 'directMessage') {
      createVideoPromiseInput.directMessageId = video.directMessageId;
      createVideoPromiseInput.type = video.type;
    } else {
      createVideoPromiseInput.conversationId = video.topicId;
    }

    createVideoPromiseInput.platform = 'WEB';

    // create the video promise
    const { data } = await Api.createVideoPromise(createVideoPromiseInput);

    // return the actual result
    return data.createVideoPromise;
  }

  /**
   * @name removeMediaFromUploadQueue
   * @description Remove the oldest (index 0) media from the queue after it's done uploading
   */
  removeMediaFromUploadQueue = async () => {
    const { mediaUploadQueue } = this.state;

    mediaUploadQueue.shift(); // remove media from the beginning of the queue

    await this.setState({ mediaUploadQueue });

    return true; // media has been removed from queue
  };

  toggleTopbar = () => {
    this.setState({ topbarIsOpen: !this.state.topbarIsOpen });
  };

  // gets DMs for a user's group. To paginate, call it again and tell it to use the savedCursor
  getDirectMessages = async (useSavedCursor = false) => {
    // if we're already loading dms from the server, end here so we don't send off multiple requests at once
    if (this.state.loadingDirectMessages) {
      return;
    }

    // if we're asking for the next page of DMs but we have no cursor, end here
    if (useSavedCursor && !this.state.directMessagesCursor) {
      return;
    }

    // set loading to true
    this.setState({ loadingDirectMessages: true });

    // load the requested dms
    const cursor = useSavedCursor ? this.state.directMessagesCursor : null;
    const { data, error } = await Api.getDirectMessages(
      this.state.activeGroup.id,
      cursor,
    ).catch(e => this.setState({ loadingDirectMessages: false }));

    // if server call fails, log & end here so we don't overwrite what's already on state for dms
    if (error || !data) {
      return console.log(error);
    }

    let newDms = [];
    try {
      newDms = data.myDirectMessages.directMessages;
      newDms.forEach(dm => {
        let memberNames = [];
        dm.members.forEach(member => {
          if (member.id === this.state.userData.id) return;
          let memberName = member.firstName || '';
          if (member.lastName) {
            if (member.firstName) memberName += ' ';
            memberName += member.lastName;
          }
          if (memberName) memberNames.push(memberName);
        });

        // DM name is a comma separated list of dm member names
        let displayName = memberNames.join(', ');
        if (displayName.length > 40) displayName = displayName.substring(0, 37) + '...';
        dm.displayName = displayName;
      });
    } catch (e) {
      // if we fail somewhere in there, log the error then end so we don't overwrite what's on state with an empty array
      return console.log(e);
    }

    // decide if we should append or replace
    const updatedDms = useSavedCursor
      ? // if we used a cursor, add the new results onto our existing set.
        [...this.state.directMessages, ...newDms]
      : // otherwise, replace whatever is there
        newDms;

    const cursor2 = data ? data.myDirectMessages.cursor : null;
    // update state
    const updates = {
      directMessages: updatedDms,
      directMessagesCursor: cursor2,
      loadingDirectMessages: false,
    };
    await this.setStateSync(updates);
  };

  // clears the active group and all related data from global state and local storage
  clearActiveGroup() {
    LocalStore.removeItem('activeGroup');
    LocalStore.removeItem('activeTopic');
    LocalStore.removeItem('topics');
    this.setState({
      activeGroup: null,
      activeTopic: null,
      topics: [],
      directMessages: [],
    });
  }

  // Approve or Reject pending group members. status should be either APPROVE or REJECT
  async handlePendingMember(group, user, status) {
    let updateActiveGroup = this.state.activeGroup;

    if (status === 'APPROVE') {
      // the group member is approved

      await Api.approvePendingGroupMember({ groupId: group.id, userId: user.id });

      updateActiveGroup.members.push(user);
    } else if (status === 'REJECT') {
      // the group member is rejected
      await Api.denyPendingGroupMember({ groupId: group.id, userId: user.id });
    } else {
      throw new Error('status must be either APPROVE or REJECT');
    }

    // remove the member from pendingMembers
    updateActiveGroup.pendingMembers = updateActiveGroup.pendingMembers.filter(
      mem => mem.id !== user.id,
    );

    this.setState({
      activeGroup: updateActiveGroup,
    });

    return updateActiveGroup;
  }

  setShowCreateGroupModal(status) {
    this.setState({
      showCreateGroupModal: status,
    });
  }

  setShowGroupInviteModal(status) {
    this.setState({
      showGroupInviteModal: status,
    });
  }

  async getVideoReactions(videoId) {
    const { data } = await Api.getVideoReactions({ id: videoId });

    return data.getVideoReactions.items;
  }

  async getVideoViews(videoId) {
    const { data } = await Api.getVideoViews({ id: videoId });

    return data.getVideoViews.items;
  }

  async getTaggedUsers(videoId) {
    const { data } = await Api.getTaggedUsers({ id: videoId });

    return data.taggedUsersForVideo.items;
  }

  async shareVideoToTopic(videoId, conversationId) {
    const { data } = await Api.shareVideoToTopic({ videoId, conversationId });

    return data.shareVideoToConversation;
  }

  async archiveVideo(videoId) {
    return await Api.archiveVideo(videoId);
  }

  async deleteMessage(input) {
    return await Api.deleteMessage(input);
  }

  render() {
    return (
      <MyContext.Provider
        value={{
          isMobileSizedScreen: this.isMobileSizedScreen,
          state: this.state,
          history: this.props.history,
          setGlobalState: (name, data) => this.setGlobalState(name, data),
          showLoadingGif: () => this.showLoadingGif(),
          closeLoadingGif: () => this.closeLoadingGif(),
          setGroupDeepLink: groupDeepLink => this.setGroupDeepLink(groupDeepLink),
          deepLinkToGroup: groupDeepLink => this.deepLinkToGroup(groupDeepLink),
          setActiveGroup: (group, redirectPath, noRedirect) =>
            this.setActiveGroup(group, redirectPath, noRedirect),
          toggleLoggedIn: bool => this.toggleLoggedIn(bool),
          fetchSidebarGroups: () => this.fetchSidebarGroups(),
          fetchGroupDetails: id => this.fetchGroupDetails(id),
          fetchGroupUsers: (includeAdmins, cursor, id) =>
            this.fetchGroupUsers(includeAdmins, cursor, id),
          fetchMoreTopics: (id, cursor) => this.fetchMoreTopics(id, cursor),
          fetchPendingGroupMembers: id => this.fetchPendingGroupMembers(id),
          fetchTopics: id => this.fetchTopics(id),
          createNewGroup: groupData => this.createNewGroup(groupData),
          clearConversationVideos: () => this.clearConversationVideos(),
          tagUsers: (videoId, userIds) => this.tagUsers(videoId, userIds),
          untagUsers: (videoId, userIds) => this.untagUsers(videoId, userIds),
          toggleTopbar: () => this.toggleTopbar(),
          addMediaToUploadQueue: video => this.addMediaToUploadQueue(video),
          getDirectMessages: params => this.getDirectMessages(params),
          clearActiveGroup: () => this.clearActiveGroup(),
          handlePendingMember: (groupId, userId, status) =>
            this.handlePendingMember(groupId, userId, status),
          setShowCreateGroupModal: status => this.setShowCreateGroupModal(status),
          setShowGroupInviteModal: status => this.setShowGroupInviteModal(status),
          getVideoReactions: this.getVideoReactions,
          getVideoViews: this.getVideoViews,
          getTaggedUsers: this.getTaggedUsers,
          shareVideoToTopic: this.shareVideoToTopic,
          archiveVideo: this.archiveVideo,
          deleteMessage: this.deleteMessage,
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

export default MyProvider;
