/*
    - This component is used in the Playback component. It offers a convenient place to house all of the logic
    for reaction animations. 

    PARAMS:
    - reactionTypes (array): a list of reaction types you want included in the animation. Must be one of the following:
        HEART, THUMBS_UP, LOL, EXCLAMATION, QUESTION
    - totalParticleCount (string): the number of total particles that will show on screen. Will be divided equally among all
        of the reactionTypes passed in
    - closeModal (function): you must pass in a function that can be used to close this animation modal once the animation is
        finished

    NOTE: This component has a CSS width and height of 0px in order to not cover the screen,  and it relies on the parent 
    component having a declared CSS position in order for the reaction images to appear in the right area on the screen.
*/

import React, { useState, useEffect, useRef } from 'react';

// highlighted reaction icons
import heartRedSvg from '../../images/reactions/heart-red.svg';
import thumbsUpBlueSvg from '../../images/reactions/thumbs-up-blue.svg';
import lolOrangeSvg from '../../images/reactions/lol-orange.svg';
import exclamationPurpleSvg from '../../images/reactions/exclamation-purple.svg';
import questionGreenSvg from '../../images/reactions/question-green.svg';

// helper function builds out particles
function ReactionParticle(reactionType, modalWidth, modalHeight) {
  // generate randomness start location
  const id = Math.random();
  const posX = Math.floor(Math.random() * modalWidth);
  const posY = Math.floor(Math.random() * modalHeight - modalHeight * 0.25);
  const fallSpeed = Math.floor(Math.random() * 5 + 1);
  let rotation = Math.floor(Math.random() * 360);
  let rotationSpeed = Math.floor(Math.random() * 4 + 1);

  // thumbsUp should always be right side up
  if (reactionType === 'THUMBS_UP') {
    rotation = 0;
    rotationSpeed = 0;
  }

  // build image source
  const reactionImageSrcMap = {
    HEART: heartRedSvg,
    THUMBS_UP: thumbsUpBlueSvg,
    LOL: lolOrangeSvg,
    EXCLAMATION: exclamationPurpleSvg,
    QUESTION: questionGreenSvg,
  };
  const imageSrc = reactionImageSrcMap[reactionType];

  // return an object that can be used to build the image out later
  return {
    id,
    src: imageSrc,
    posX,
    posY,
    rotation,
    rotationSpeed,
    fallSpeed,
  };
}

// component function
export default function ReactionAnimationModal({ reactionTypes, totalParticleCount, closeModal }) {
  const modalRef = useRef(null);
  const [particles, setParticles] = useState([]);
  const [animationInterval, setAnimationInterval] = useState(null);

  // when this modal loads, build the particles and put them on state
  useEffect(() => {
    const modal = modalRef.current;
    const width = modal.parentElement.clientWidth;
    const height = modal.parentElement.clientHeight;

    // build particles array
    let reactionParticles = [];
    // divide the total particle count equally among reaction types
    const numReactionTypes = reactionTypes.length;
    const particleCountPerType = Math.floor(totalParticleCount / numReactionTypes);
    reactionTypes.forEach(reactionType => {
      for (let i = 0; i < particleCountPerType; i++) {
        reactionParticles.push(new ReactionParticle(reactionType, width, height));
      }
    });

    setParticles(reactionParticles);
  }, []);

  // when the particles array gets created, run this effect
  useEffect(() => {
    // if we have an interval saved to state, end here
    if (animationInterval) return;

    // once we have particles in the array, animate them
    if (particles && particles.length) {
      animateParticles();
    }
  }, [particles]);

  function animateParticles() {
    const startTime = new Date().getTime();

    // create the animation interval
    const interval = setInterval(
      () => {
        // update particles location on screen
        const updatedParticles = particles.map(particle => {
          particle.posY += particle.fallSpeed;
          particle.rotation += particle.rotationSpeed;
          return particle;
        });
        setParticles(updatedParticles);

        // animation ends after 2 seconds
        const now = new Date().getTime();
        if (now - startTime > 2000) {
          clearInterval(interval);
          closeModal();
        }
      },
      // 60 fps
      16,
    );

    // save to state so we don't create a new interval each time we update the array
    setAnimationInterval(interval);
  }

  return (
    <div className={`reaction-animation-modal`} ref={modalRef}>
      {particles.map(({ id, src, posX, posY, rotation }) => {
        return (
          <img
            key={id}
            src={src}
            alt="reaction emoji"
            // these styles are dynamic, so updating the values on state "animates" the image
            style={{ top: `${posY}px`, left: `${posX}px`, transform: `rotate(${rotation}deg)` }}
          />
        );
      })}
    </div>
  );
}
