import React from 'react';
import MyContext from '../../MyContext';
import PropTypes from 'prop-types';
import SmallModal from '../SmallModal';
import Api from '../../services/ApiHandler';
import Button from '../Button';
import ToggleSwitch from '../ToggleSwitch';
import FileUpload from '../FileUpload';
import InputField from '../InputField';
import { S3Upload } from '../../services/UploaderService';
import UserHelper from '../../services/UserHelpers';
import LocalStore from '../../services/LocalStorage';
import { withRouter } from 'react-router';
import LtiService from '../../services/LtiService';

const USER_PROFILE_SETTINGS = {
  usersVideosCanBeShared: 'My Posts Can Be Shared',
};

class UserProfileSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: props.showModal,
      profileImage: null,
      name: '',
      settings: {},
      user: {},
      authToken: '',
      file: null,
      loading: false,
      awaitingValidation: false,
    };

    this.updateSettings = this.updateSettings.bind(this);
    this.toggleSetting = this.toggleSetting.bind(this);
    this.logout = this.logout.bind(this);
  }

  async componentDidUpdate() {
    if (this.props.showModal !== this.state.showModal) {
      this.setState({
        showModal: this.props.showModal,
      });
    }

    if (this.props.showModal && !this.state.showModal) {
      const { data, error } = await Api.me();
      if (error) {
        alert(error.message);
      } else {
        this.setState({
          id: data.me.id,
          profileImage: data.me.profileImageUrl && data.me.profileImageUrl.url,
          name: `${data.me.firstName} ${data.me.lastName}`,
          email: data.me.email,
          phoneNumber: data.me.phoneNumber,
          settings: data.me.settings,
          user: data.me,
        });
      }
    }
  }

  logout() {
    // logging out
    this.setState(
      {
        id: null,
        profileImage: null,
        name: '',
        email: '',
        settings: {},
      },
      () => {
        LocalStore.removeItem('JWT');
        LocalStore.removeItem('userData');
        LocalStore.removeItem('groups');
        LocalStore.removeItem('topics');
        LocalStore.removeItem('activeGroup');
        this.context.setGlobalState('showSidebar', null);
        this.context.setGlobalState('loggedIn', false);
        this.props.closeModal();
        this.props.history.push('/');
      },
    );

    LtiService.logout();
  }

  setTheState(key, val) {
    this.setState({
      [key]: val,
    });
  }

  async toggleSetting(event, key, i) {
    let newState = this.state;
    newState.settings[key] = event.target.checked;
    this.setState(newState);
    const input = {
      ...this.state.settings,
    };
    delete input.__typename;
    const { data, error } = await Api.setMyUserSettings(input);
    if (error) {
      alert(error.message);
    } else {
      newState = this.state;
      newState.settings = data.setMyUserSettings;
      this.setState(newState);
    }
  }

  async updateSettings() {
    this.setState({
      loading: true,
    });

    if (!this.state.awaitingValidation) {
      let stopExecution = false;

      if (this.state.phoneNumber !== this.state.user.phoneNumber) {
        // only phone number has changed
        await Api.authenticate({
          phoneNumber: this.state.phoneNumber,
        });
        stopExecution = true;
      } else if (this.state.email !== this.state.user.email) {
        // only email has changed
        await Api.authenticateWithEmail({
          email: this.state.email,
          name: this.state.name,
        });
        stopExecution = true;
      }

      if (stopExecution) {
        this.setState({ awaitingValidation: true, loading: false });

        return;
      }
    }

    // By the time this point in the code is hit, the user will have an authorization code on state that they received via phone or email. this code will be sent to the server, and allows them to change their phone number or email (it is a verification step).
    // if they do not have a code at this point, it's because they are not modifying their email or phone, and are just modifying part of their name, their profile picture, or their "my posts are sharable" setting.

    let updatedProfileImage;
    if (this.state.file) {
      const profilePictureResponse = await Api.publicImageUrl();
      const uploadUrl = profilePictureResponse.data.publicImageUrl.uploadUrl.url;
      await S3Upload(this.state.file, uploadUrl);
      updatedProfileImage = profilePictureResponse.data.publicImageUrl.downloadUrl;
    }

    const hasPhoneChanged = this.state.phoneNumber !== this.state.user.phoneNumber;
    const hasEmailChanged = this.state.email !== this.state.user.email;

    const { name } = this.state;

    // the `name.substr` stuff to generate the users first and last name split the name by the first whitespace. so a name like `john doe` would become `['john', 'doe']`, but a name like `john doe smith` would become `['john', 'doe smith']`
    const { data, error } = await Api.updateUser({
      firstName: name.substr(0, name.indexOf(' ')),
      lastName: name.substr(name.indexOf(' ') + 1),
      email: hasEmailChanged ? this.state.email : undefined,
      phoneNumber: hasPhoneChanged ? this.state.phoneNumber : undefined,
      authToken: hasEmailChanged || hasPhoneChanged ? this.state.authToken : undefined,
      profileImage: updatedProfileImage ? updatedProfileImage : this.state.profileImage,
    });

    if (error) {
      alert(error.message);
    } else {
      this.context.setGlobalState('userData', data.updateUser);
      this.context.setGlobalState('userName', UserHelper.getUserName(data.updateUser));
      this.context.setGlobalState(
        'profileImage',
        updatedProfileImage ? updatedProfileImage : this.state.profileImage,
      );
      LocalStore.setItem('userData', data.updateUser);

      this.setState({
        loading: false,
        awaitingValidation: false,
        authToken: '',
        user: {
          ...this.state.user,
          email: this.state.email,
          phoneNumber: this.state.phoneNumber,
        },
      });

      this.props.closeModal();
    }
  }

  render() {
    let settings;

    settings = Object.keys(this.state.settings).map((key, i) => {
      if (key !== '__typename') {
        return (
          <ToggleSwitch
            key={i}
            label={USER_PROFILE_SETTINGS[key]}
            checked={this.state.settings[key]}
            action={e => this.toggleSetting(e, key, i)}
          />
        );
      }
      return null;
    });

    return (
      <SmallModal
        isOpen={this.state.showModal}
        close={this.props.closeModal}
        shouldOverlayClose={false}
        tall
      >
        <div className="UserSettingsModal">
          <Button label="Logout" className="logout" action={this.logout} />

          {!this.state.awaitingValidation ? (
            <React.Fragment>
              <FileUpload
                originalImg={this.state.profileImage ? this.state.profileImage : ''}
                onChange={img => this.setTheState('file', img)}
                className="profile-picture"
                rounded
              />

              <InputField
                label="Name"
                value={this.state.name}
                onChange={val => this.setTheState('name', val)}
              />

              <InputField
                label="Phone Number"
                value={this.state.phoneNumber}
                type="phone"
                onChange={val => this.setTheState('phoneNumber', val)}
                disabled={this.state.email !== this.state.user.email} // disable if email has changed
              />

              <InputField
                label="Email"
                value={this.state.email}
                type="email"
                onChange={val => this.setTheState('email', val)}
                disabled={this.state.phoneNumber !== this.state.user.phoneNumber} // disable if phone has changed
              />

              <div className="profile-toggle-settings">{settings}</div>

              <div className="button-container">
                <Button
                  label="Update Profile"
                  loading={this.state.loading}
                  className="user-settings-button"
                  action={this.updateSettings}
                />
                <a
                  className="report-problem"
                  href={
                    process.env.REACT_APP_PLATFORM === 'say'
                      ? 'mailto:support@say.co?Subject=Contact Say!'
                      : 'mailto:support@edconnect.app?Subject=Contact EdConnect!'
                  }
                >
                  Report a Problem
                </a>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <br />
              <p className="copy">
                We have sent a verification code to the{' '}
                {this.state.phoneNumber !== this.state.user.phoneNumber
                  ? 'phone number'
                  : 'email address'}{' '}
                you updated.
              </p>
              <br />
              <p className="copy">
                Please enter that code below to confirm that you want to update your contact
                information.
              </p>

              {/* For some reason that will never be known to me, this line is required to prevent the input field from prefilling with the users email address or phone number (whichever was changed). I think react just needs to evaluate the state to realize it's actually empty, and to not fill the input field. so this works, but it's gross.
              
              We should look at the inputfield component to see if the problem is inside there.  */}
              <p style={{ display: 'none' }}>{this.state.authToken}</p>

              <InputField
                label="Verification Code"
                value={this.state.authToken || ''}
                onFocus={evnt => (evnt.target.value = this.state.authToken)}
                autoFocus
                autoComplete="off"
                onChange={val => this.setTheState('authToken', val)}
              />

              <div className="button-container row">
                <div
                  className="cancel"
                  onClick={() => {
                    this.setState({ awaitingValidation: false });
                    this.props.closeModal();
                  }}
                >
                  <p>&#10005;</p>
                  <p>Cancel</p>
                </div>

                <Button
                  label="Update Profile"
                  loading={this.state.loading}
                  className="user-settings-button"
                  action={this.updateSettings}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </SmallModal>
    );
  }
}

UserProfileSettings.contextType = MyContext;

UserProfileSettings.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default withRouter(UserProfileSettings);
