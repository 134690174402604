import React, { useState, useEffect } from 'react';

import LocalStorage from '../../services/LocalStorage';

import NotificationSettings from '../modals/NotificationSettings';

import SmallModal from '../SmallModal';
import Button from '../Button';

import { ReactComponent as Profile } from '../../images/Profile.svg';
import { ReactComponent as Topics } from '../../images/Topics.svg';
import { ReactComponent as Messages } from '../../images/Messages.svg';
import { ReactComponent as Record } from '../../images/video-controls/Record.svg';

import getFormattedPlatformName from '../../utils/getFormattedPlatformName';

function OnboardingTutorial() {
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(1);
  const [requestNotifications, setRequestNotifications] = useState(false);

  // run on component mount. check to see if we need to render the tutorial modals
  useEffect(() => {
    (() => {
      // if needed, open the onboarding tutorial
      const hasCompletedTutorial = LocalStorage.getItem('tutorialStatus');

      if (hasCompletedTutorial !== 'DONE') {
        setShowTutorial(true);
      }
    })();
  }, []);

  useEffect(() => {
    // if needed, open the onboarding tutorial
    const hasCompletedTutorial = LocalStorage.getItem('tutorialStatus');

    if (hasCompletedTutorial === 'NOTIFICATIONS') {
      setRequestNotifications(true);
    }
  }, [showTutorial]);

  if (showTutorial) {
    return (
      <SmallModal
        position={0}
        shouldOverlayClose={false}
        isOpen={showTutorial}
        close={() => setShowTutorial(false)}
        noWidth
      >
        <div className="OnboardingModal">
          {tutorialStep === 1 ? (
            <div className="step 1">
              <h3 className="title">Welcome to {getFormattedPlatformName()}!</h3>

              <Profile />

              <p>
                To get started, join an existing group (by invitation) or create a new group of your
                own and invite collaborators.
              </p>

              <Button
                label="Next"
                action={() => {
                  LocalStorage.setItem('tutorialStatus', 'IN_PROGRESS');
                  setTutorialStep(2);
                }}
              />
            </div>
          ) : tutorialStep === 2 ? (
            <div className="step 2">
              <h3 className="title">Topics</h3>

              <Topics />

              <p>
                Inside each group you’ll find topics. Jump into an existing topic or start a new one
                and organize the conversation around a theme of your own.
              </p>

              <Button
                label="Next"
                action={() => {
                  setTutorialStep(3);
                }}
              />
            </div>
          ) : tutorialStep === 3 ? (
            <div className="step 3">
              <h3 className="title">Direct Messages</h3>

              <Messages />

              <p>
                Send video messages directly to a specific recipient for one-on-one collaboration.
              </p>

              <Button
                label="Next"
                action={() => {
                  setTutorialStep(4);
                }}
              />
            </div>
          ) : (
            <div className="step 4">
              <h3 className="title">Record and share videos</h3>

              <Record />

              <p>
                Record, preview, and share video messages to stay connected and keep the
                conversation real.
              </p>

              <Button
                label="Get Started"
                action={() => {
                  // no more tutorial steps. close tutorial
                  LocalStorage.setItem('tutorialStatus', 'NOTIFICATIONS');
                  setTutorialStep(1);
                  setShowTutorial(false);
                }}
              />
            </div>
          )}
        </div>
      </SmallModal>
    );
  }

  if (requestNotifications) {
    return (
      <NotificationSettings
        isOpen={requestNotifications}
        close={() => {
          LocalStorage.setItem('tutorialStatus', 'DONE');
          setRequestNotifications(false);
        }}
        shouldOverlayClose={true}
      />
    );
  }

  return null;
}

export default OnboardingTutorial;
