import React, { useState, useEffect, useCallback } from 'react';

function RecordingTimeCounter({ isRecording, isPaused }) {
  const [recordingTime, setRecordingTime] = useState(0);

  const updateTime = useCallback(() => {
    if (isRecording && !isPaused) {
      setRecordingTime(recordingTime + 1);
    }
  }, [recordingTime, isRecording, isPaused]);

  useEffect(() => {
    setTimeout(() => {
      updateTime();
    }, 1000);
  }, [recordingTime]);

  useEffect(() => {
    if (isRecording && !isPaused) {
      updateTime();
    }
  }, [isRecording, isPaused]);

  function formatTime() {
    const date = new Date(0);
    date.setSeconds(recordingTime);

    return date.toISOString().substr(14, 5);
  }

  return (
    <div className="RecordingTimeCounter">
      <span className="dot" />
      <p>{formatTime()}</p>
    </div>
  );
}

export default RecordingTimeCounter;
