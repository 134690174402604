import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import MyContext from './MyContext';

import OnboardingTutorial from './components/modals/OnboardingTutorial';
import Sidebar from './components/Sidebar';
import Landing from './routes/Landing';
import PreAuthLanding from './routes/PreAuthLanding';
import GroupSettings from './routes/GroupSettings';
import Record from './routes/Record';
import FileUpload from './components/FileUpload';
import Group from './routes/Group';
import Playback from './routes/Playback';
import DynamicLink from './components/DynamicLink';
import chrome from './images/chrome-512.png';

class App extends Component {
  componentDidMount() {
    const { userData } = this.context.state;

    if (process.env.NODE_ENV === 'production' && userData) {
      Sentry.configureScope(scope => {
        scope.setUser({
          id: userData.id,
          name: `${userData.firstName} ${userData.lastName}`,
          email: userData.email,
        });
      });
    }
  }

  render() {
    const { isQueueRunning, mediaUploadQueue, loggedIn } = this.context.state;

    // if the queue is running, prompt the user if they try to leave the page
    if (isQueueRunning && !window.onbeforeunload) {
      window.onbeforeunload = () => true;
    } else if (!isQueueRunning && window.onbeforeunload) {
      window.onbeforeunload = null;
    }
    const preAuthIndex = window.location.pathname.indexOf('JWT');

    // if user tries to access a route while unauthenticated
    if (
      !loggedIn &&
      window.location.pathname !== '/' &&
      !window.location.pathname.includes('edconnect.app/dl/') // don't redirect if user clicked on an invite link
    ) {
      // redirect to home route
      //TODO: need to figure out a better way to do this.. possibly check on the routes and not here?
      // window.location.href = '/';
    }

    const isChrome = window.navigator.appVersion.indexOf('Chrome') > -1;

    const isUploadingDM = mediaUploadQueue[0] && !!mediaUploadQueue[0].type;

    // keep names short to maintain styling
    let topicDisplayName = mediaUploadQueue[0] ? mediaUploadQueue[0].topicName : null;
    if (topicDisplayName && topicDisplayName.length > 20)
      topicDisplayName = topicDisplayName.substring(0, 17) + '...';
    let groupDisplayName = mediaUploadQueue[0] ? mediaUploadQueue[0].groupName : null;
    if (groupDisplayName && groupDisplayName.length > 20)
      groupDisplayName = groupDisplayName.substring(0, 17) + '...';

    return (
      <div className="App">
        <div className="flags">
          {!isChrome && (
            <div className="chrome-flag">
              <img alt="chrome" className="not-chrome-image" src={chrome} height="25" width="25" />
              <p>Works better with Chrome!</p>
            </div>
          )}

          {isQueueRunning && (
            <div className="top-bar">
              <p className="count">{mediaUploadQueue.length} videos remaining...</p>
              <p>
                {isUploadingDM
                  ? `Video uploading to direct message`
                  : `Video uploading to ${topicDisplayName} in ${groupDisplayName}`}
              </p>
            </div>
          )}
        </div>

        <Sidebar />

        {loggedIn && <OnboardingTutorial />}

        <main className="Main">
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/dl/:groupId" component={DynamicLink} />
            <Route exact path="/group/:groupId" component={Group} />
            <Route exact path="/group/:groupId/settings" component={GroupSettings} />
            <Route exact path="/group/:groupId/directMessage/:directMessageId" component={Group} />
            <Route exact path="/group/:groupId/conversation/:conversationId" component={Group} />
            <Route
              exact
              path="/group/:groupId/topic/:topicId/video/:videoId?"
              component={Playback}
            />
            <Route
              exact
              path="/JWT/:jwt/group/:groupId/topic/:topicId/video/:videoId"
              component={PreAuthLanding}
            />
            <Route exact path="/JWT/:jwt/group/:groupId" component={PreAuthLanding} />
            <Route exact path="/JWT/:jwt" component={PreAuthLanding} />
            <Route
              exact
              path="/group/:groupId/directMessage/:directMessageId/video/:videoId?"
              component={Playback}
            />
            <Route path="/group/:groupId/topic/:topicId/record" component={Record} />
            <Route
              path="/group/:groupId/directMessage/:directMessageId/record"
              component={Record}
            />
            <Route path="/upload" component={FileUpload} />

            {/* The catch all route has to be the last route listed here */}
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </main>
      </div>
    );
  }
}

App.contextType = MyContext;

export default App;
