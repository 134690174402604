import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Api from '../services/ApiHandler';
import MyContext from '../MyContext';
import LocalStore from '../services/LocalStorage';

class DynamicLink extends Component {
  async joinGroup(groupId) {
    const { data } = await Api.joinGroup(groupId);
    if (data) {
      if (data.joinGroup === 'ADDED_FULL_MEMBERSHIP' || data.joinGroup === 'ALREADY_FULL_MEMBER') {
        this.props.history.push(`/group/${groupId}`);
      } else {
        this.props.history.push(`/`);
      }
    }
  }

  render() {
    const { groupId } = this.props.match.params;

    if (groupId && this.context.state.loggedIn) {
      // navigate to the group
      this.joinGroup(groupId);
    } else if (groupId && !this.context.state.loggedIn) {
      // if there is a groupId add to local store
      LocalStore.setItem('DynamicLinkGroup', groupId);
      // navigate to landing page
      this.props.history.push('/');
    } else {
      this.props.history.push('/');
    }

    const page = <div></div>;

    return page;
  }
}

DynamicLink.contextType = MyContext;

export default withRouter(DynamicLink);
