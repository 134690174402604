import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DetectRTC from 'detectrtc';

import { formatHowLongAgo } from '../utils/helpers';

import MyContext from '../MyContext';
import { ViewContext } from '../providers';

import SmallModal from './SmallModal';
import FileUpload from './FileUpload';
import InputField from './InputField';
import Button from './Button';
import Dropdown from './Dropdown';
import ImagePreview from './ImagePreview';

import PlusIcon from '../images/plus-icon.png';
import UserProfileSettings from './modals/UserProfileSettings';
import LocalStore from '../services/LocalStorage';
import NotificationSettings from './modals/NotificationSettings';

import { ReactComponent as SettingsIcon } from '../images/menu-icons/settings.svg';
import { ReactComponent as BellFull } from '../images/menu-icons/bell-full.svg';
import Api from '../services/ApiHandler';

import getFormattedPlatformName from '../utils/getFormattedPlatformName';

function Sidebar() {
  const context = React.useContext(MyContext);
  const {
    state: { groups, userData, activeGroup, showCreateGroupModal, showGroupInviteModal },
    fetchSidebarGroups,
    createNewGroup,
    setActiveGroup,
    setShowCreateGroupModal,
    setShowGroupInviteModal,
  } = context;

  const { showSidebar, setShowSidebar } = React.useContext(ViewContext);

  const notificationDropdownRef = React.useRef();
  const [showNotifications, setShowNotifications] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [showNotificationSettingsModal, setShowNotificationSettingsModal] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);
  const [groupImg, setGroupImg] = React.useState();
  const [groupName, setGroupName] = React.useState('');
  const [groupDescription, setGroupDescription] = React.useState('');
  const [newGroupLoading, setNewGroupLoading] = React.useState(false);

  const handleMouseEvent = React.useCallback(
    event => {
      if (
        notificationDropdownRef.current &&
        !notificationDropdownRef.current.contains(event.target) &&
        notificationDropdownRef.current.className.includes('visible')
      ) {
        setShowNotifications(false);
      }
    },
    [showNotifications],
  );

  // add an event listener to handle closing the notifications dropdown when you click away
  React.useEffect(() => {
    document.addEventListener('mousedown', handleMouseEvent);

    // handle unmount event
    return () => {
      document.removeEventListener('mousedown', handleMouseEvent);
    };
  }, []);

  React.useEffect(() => {
    const getGroups = async () => {
      const isLoggedIn = !!LocalStore.getItem('JWT');

      // if the user is logged in, attempt to fetch groups if none are found on context
      if (isLoggedIn && !groups) {
        await fetchSidebarGroups();

        // get notifications from server
        const result = await Api.getNotifications();

        if (result.data) {
          setNotifications(result.data.notifications.items);
        }
      }
    };

    getGroups();
  }, [groups, fetchSidebarGroups]);

  const openCreateGroupModal = React.useCallback(() => {
    setShowCreateGroupModal(true);
  }, []);

  const closeCreateGroupModal = React.useCallback(() => {
    setShowCreateGroupModal(false);
  }, []);

  const openUserProfileModal = React.useCallback(() => {
    setShowProfile(true);
  }, []);

  const groupsMap =
    groups &&
    groups.map((group, ind) => {
      // truncate group name if too long
      const maxlength = 25;
      if (group.name.length >= maxlength) {
        group.name = group.name.substring(0, maxlength - 3) + '...';
      }

      const unwatchedContentBadge = group.hasNewContent ? (
        <div className="unwatched-content-badge"></div>
      ) : null;

      return (
        <li
          key={ind}
          className={`group-item ${
            group.selected || (activeGroup && activeGroup.id === group.id) ? 'selected' : ''
          }`}
          id="group-item"
          onClick={() => {
            if (DetectRTC.isMobileDevice) {
              setShowSidebar(false);
            }

            setActiveGroup(group);
          }}
        >
          {unwatchedContentBadge}
          <ImagePreview className="group-img" url={group.publicImageUrl} alt="" />
          <p className="group-name">{group.name}</p>
        </li>
      );
    });

  async function createGroup() {
    setNewGroupLoading(true);

    const groups = await createNewGroup({
      name: groupName.trim(),
      img: groupImg,
      description: groupDescription,
    });

    // hide the modal, stop loading
    setNewGroupLoading(false);
    setShowCreateGroupModal(false);
    setGroupImg(null);
    setGroupDescription('');
    setGroupName('');

    if (DetectRTC.isMobileDevice) {
      setShowSidebar(false);
    }

    // redirect to the new group page
    await setActiveGroup(groups[0]);
    setShowGroupInviteModal(true);
  }

  return (
    <React.Fragment>
      <SmallModal
        isOpen={showCreateGroupModal}
        close={closeCreateGroupModal}
        shouldOverlayClose={true}
      >
        <div className="new-group-modal">
          <h1 className="modal-title">New Group</h1>
          <FileUpload onChange={img => setGroupImg(img)} />
          <InputField
            label="Group Name"
            placeholder="e.g. Physics 101"
            required={true}
            value={groupName}
            onChange={setGroupName}
          />
          <InputField
            multiline
            label="Description"
            value={groupDescription}
            onChange={setGroupDescription}
          />
          <Button
            className="modal-button"
            label="Create Group"
            disabled={!groupName || !groupName.trim()}
            loading={newGroupLoading}
            action={createGroup}
          />
        </div>
      </SmallModal>

      {showGroupInviteModal &&
        activeGroup &&
        activeGroup.inviteLink &&
        activeGroup.inviteLink.link && (
          <SmallModal
            isOpen={
              showGroupInviteModal &&
              activeGroup &&
              activeGroup.inviteLink &&
              activeGroup.inviteLink.link
            }
            close={() => setShowGroupInviteModal(false)}
            shouldOverlayClose={true}
          >
            <div className="group-invite-modal">
              <h1 className="modal-title">Share an invite link</h1>
              <p className="modal-subtitle">
                Anyone can use this link to join {activeGroup.name} on {getFormattedPlatformName()}
              </p>
              <InputField value={activeGroup.inviteLink.link} label="Invite Link" copiable />
              <div className="bottom-nav-buttons">
                <Button
                  className={`group-invite-modal-button done-button`}
                  action={() => setShowGroupInviteModal(false)}
                  label="Done"
                />
              </div>
            </div>
          </SmallModal>
        )}

      {showNotificationSettingsModal && (
        <NotificationSettings
          isOpen={showNotificationSettingsModal}
          close={() => setShowNotificationSettingsModal(false)}
          shouldOverlayClose={true}
        />
      )}

      <UserProfileSettings showModal={showProfile} closeModal={() => setShowProfile(false)} />

      <section className={`Sidebar ${showSidebar ? 'show' : 'hide'}`}>
        <div className="grid-item">
          <div className="user-profile" onClick={openUserProfileModal}>
            {userData ? (
              <React.Fragment>
                <img
                  className="profile-img"
                  src={userData.profileImageUrl ? userData.profileImageUrl.url : ''}
                  alt=""
                />
                <p className="user-name">
                  {userData.firstName} {userData.lastName}
                </p>
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="grid-item centered">
          <div className="group-container">
            <h4>Groups</h4>
            <ul className="group-list">
              <li className="group-item" onClick={openCreateGroupModal}>
                <div className="new-group-button">
                  <img className="group-img" src={PlusIcon} alt="add new group" />
                </div>
                <p className="group-name">Add New Group</p>
              </li>

              {groupsMap}
            </ul>
          </div>
        </div>

        <div className="grid-item overflow centered">
          <Dropdown
            className="notifications-dropdown"
            showDropdown={showNotifications}
            trigger={
              <div
                className="notifications"
                onClick={() => setShowNotifications(!showNotifications)}
              >
                <BellFull className="notifications-icon" />
                <p>Notifications</p>
              </div>
            }
          >
            <ul
              ref={notificationDropdownRef}
              className={`notifications-list ${showNotifications ? 'visible' : 'hidden'}`}
            >
              <SettingsIcon
                title="Notification Settings"
                className="settings-icon"
                onClick={event => {
                  event.stopPropagation();
                  setShowNotifications(false);
                  setShowNotificationSettingsModal(true);
                }}
              />

              {notifications.length ? (
                notifications.map(notif => {
                  let redirectPath = '';

                  switch (notif.eventType) {
                    case 'FLAGGED_VIDEO':
                    case 'PENDING_GROUP_MEMBERS_NOTIFICATION':
                      redirectPath = `/group/${notif.groupId}/settings`;
                      break;
                    case 'VIDEO':
                    case 'TAGGED_IN_VIDEO':
                    case 'REACTION_TO_VIDEO':
                    case 'POST':
                      redirectPath = `/group/${notif.groupId}/topic/${notif.conversationId}/video/${notif.initiatingVideoId}`;
                      break;
                    case 'DIRECT_MESSAGE':
                      redirectPath = `/group/${notif.groupId}/directMessage/${notif.directMessageId}/video/${notif.initiatingVideoId}`;
                      break;
                    default:
                      redirectPath = '/';
                  }

                  return (
                    <li
                      key={notif.eventId}
                      className="notification-item"
                      onClick={async () => {
                        const newGroup = context.state.groups.find(
                          group => group.id === notif.groupId,
                        );
                        setShowNotifications(false);

                        if (DetectRTC.isMobileDevice) {
                          setShowSidebar(false);
                        }

                        await setActiveGroup(newGroup, redirectPath);
                      }}
                    >
                      {notif.initiatingUserProfileUrl ? (
                        <img src={notif.initiatingUserProfileUrl} alt="user profile" />
                      ) : (
                        <div className="no-img" />
                      )}
                      <div className="text-container">
                        <p className="label">{notif.text}</p>
                        <p className="timestamp">{formatHowLongAgo(notif.createdAt)}</p>
                      </div>
                    </li>
                  );
                })
              ) : (
                <p className="no-notifications">You have no new notifications.</p>
              )}
            </ul>
          </Dropdown>
        </div>
      </section>
    </React.Fragment>
  );
}

Sidebar.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      publicImageUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.string,
      hasNewContent: PropTypes.bool,
      isPending: PropTypes.bool,
    }),
  ),
};

export default withRouter(Sidebar);
