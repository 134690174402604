import React from 'react';

import ViewProvider from './ViewProvider';
export { ViewContext } from './ViewProvider';

import ConversationProvider from './ConversationProvider';
export { ConversationContext } from './ConversationProvider';

function MergeProviders({ children }) {
  return (
    <ViewProvider>
      <ConversationProvider>{children}</ConversationProvider>
    </ViewProvider>
  );
}

export default MergeProviders;
