import React, { useRef, useEffect } from 'react';

// live-preview of current recording. the stream is coming directly from the ReactMediaRecorder
export const VideoPreview = ({ stream, videoCapture }) => {
  const videoRef = useRef(null);

  // if the stream has been updated (happens near real-time), add the new frames to the preview
  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }

  return (
    <video
      ref={videoRef}
      className={`VideoPreview${
        videoCapture && videoCapture.deviceId !== 'SCREEN_CAPTURE' ? ' mirror' : ''
      }`}
      autoPlay={true}
      muted={true}
    />
  );
};
