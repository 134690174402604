import React, { useState, useEffect, useCallback, useContext } from 'react';

import MyContext from '../MyContext';

import Api from '../services/ApiHandler';
import { S3Upload } from '../services/UploaderService';

import FileUpload from '../components/FileUpload';
import InputField from '../components/InputField';
import Button from '../components/Button';
import ToggleSwitch from '../components/ToggleSwitch';
import SmallModal from '../components/SmallModal';

import TopicSettings from '../components/modals/TopicSettings';

import LoadingImage from '../images/loading.gif';
import { ReactComponent as BackArrow } from '../images/back-arrow.svg';
import { ReactComponent as LeaveIcon } from '../images/leave-group.svg';
import AddNewButton from '../images/buttons/AddNewButton.png';

const SETTINGS_VIEWS = {
  NOTIFICATIONS: 'NOTIFICATIONS',
  MEMBER: 'MEMBER',
  ADMIN: 'ADMIN',
};

const PENDING_MEMBER_ACTIONS = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
};

const GroupSettings = props => {
  const context = useContext(MyContext);

  const [fetchingGroup, setFetchingGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [settingsView, setSettingsView] = useState(SETTINGS_VIEWS.MEMBER);
  const [members, setMembers] = useState([]);
  const [pendingMembers, setPendingMembers] = useState([]);
  const [showLeaveGroupModal, setShowLeaveGroupModal] = useState(false);
  const [showTopicModal, setShowTopicModal] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState({});

  // ADMIN SETTINGS
  const [adminApproves, setAdminApproves] = useState(false);
  const [adminCreates, setAdminCreates] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [addAdmins, setAddAdmins] = useState([]);
  const [removeAdmins, setRemoveAdmins] = useState([]);
  const [showAdminModal, setShowAdminModal] = useState(false);

  // NOTIFICATION SETTINGS
  const [anyVideo, setAnyVideo] = useState(false);
  const [adminVideo, setAdminVideo] = useState(false);
  const [videoReaction, setVideoReaction] = useState(false);
  const [videoTag, setVideoTag] = useState(false);

  const {
    state: { activeGroup, userData, topics },
    clearActiveGroup,
    fetchSidebarGroups,
    fetchPendingGroupMembers,
    handlePendingMember,
    setActiveGroup,
  } = context;

  // fetch the group details
  useEffect(() => {
    (async () => {
      setFetchingGroup(true);

      // if the active group isn't the same as the group in the params, that means the group in the params isn't
      // one we have access to, so the app redirected us to one of our groups instead (first one in our list). So
      // we'll update the url to match that
      if (activeGroup.id !== props.match.params.groupId) {
        return props.history.replace(`/group/${activeGroup.id}`);
      }

      if (activeGroup.isAdmin) {
        await fetchPendingGroupMembers(activeGroup.id);
        setPendingMembers(activeGroup.pendingMembers || []);
      }

      setFetchingGroup(false);

      if (!activeGroup || !activeGroup.userSettings) {
        setAnyVideo(false);
        setAdminVideo(false);
        setVideoReaction(false);
        setVideoTag(false);
      } else {
        setAnyVideo(activeGroup.userSettings.notificationsForAllVideos);
        setAdminVideo(activeGroup.userSettings.notificationsForHostVideos);
        setVideoReaction(activeGroup.userSettings.notificationsForReactions);
        setVideoTag(activeGroup.userSettings.notificationsForTagged);
      }
    })();
  }, []);

  // if the activeGroup changes, update our local state data accordingly
  useEffect(() => {
    // if no active group (could happen when a user leaves a group) redirect to home page
    if (!activeGroup) return props.history.replace('/');

    // save group info on state
    setImage(activeGroup.publicImageUrl);
    setName(activeGroup.name);
    setDescription(activeGroup.description);
    setIsAdmin(activeGroup.isAdmin);

    setMembers(activeGroup.members || []);

    // SET TOGGLE SETTINGS
    if (activeGroup.settings) {
      setAdminApproves(activeGroup.settings.adminMustApproveGroupMembers);
      setAdminCreates(activeGroup.settings.onlyAdminCanCreateConversations);
    }

    setAdmins(activeGroup.adminUsers || []);
    setAddAdmins(activeGroup.adminUsers || []);
  }, [activeGroup]);

  // runs whenever the state of a pending member is changed (approve or denied)
  const handlePendingMemberCb = useCallback(
    async (group, user, status) => {
      const response = await handlePendingMember(group, user, status);

      if (response.adminUsers && response.members) {
        setMembers([...response.adminUsers, ...response.members]);
      }

      setPendingMembers(response.pendingMembers);
    },
    [handlePendingMember, setMembers, setPendingMembers],
  );

  const backToGroups = useCallback(() => {
    return props.history.goBack();
  }, [props.history]);

  const leaveGroup = useCallback(async () => {
    setLoading(true);

    const { error } = await Api.removeUserFromGroup({
      groupId: activeGroup.id,
      userId: userData.id,
    });

    // handle any errors that come back
    if (error) {
      setLoading(false);
      setShowLeaveGroupModal(false);
      return alert('Something went wrong, please try again later');
    }

    // refresh the app data
    clearActiveGroup();
    await fetchSidebarGroups();

    await setActiveGroup();
    return null;
  }, [setLoading, clearActiveGroup, fetchSidebarGroups, context.history, activeGroup, userData]);

  const updateGroup = useCallback(async () => {
    setLoading(true);

    let imageUrl = '';
    if (image && image !== activeGroup.publicImageUrl) {
      // update image
      const profilePictureResponse = await Api.publicImageUrl();
      const uploadUrl = profilePictureResponse.data.publicImageUrl.uploadUrl.url;

      await S3Upload(image, uploadUrl);
      imageUrl = profilePictureResponse.data.publicImageUrl.downloadUrl;
    }

    const { error } = await Api.updateGroup({
      id: activeGroup.id,
      name: name || undefined,
      description: description || undefined,
      publicImageUrl: imageUrl ? imageUrl : image,
    });

    if (error) {
      alert(error.message);
    } else {
      await context.fetchSidebarGroups();
    }

    setLoading(false);
  }, [setLoading, image, name, description, activeGroup, activeGroup, context]);

  const updateUserStatus = useCallback(
    (checked, user) => {
      if (checked) {
        // if the user was not previously an admin, and they are being selected, add them to addAdmins

        setAddAdmins([...addAdmins, user]);
        setRemoveAdmins(removeAdmins.filter(usr => usr !== user));
      } else {
        // if the user is already an admin, and they are being unselected, add them to removeAdmins
        setAddAdmins(addAdmins.filter(usr => usr !== user));
        setRemoveAdmins([...removeAdmins, user]);
      }
    },
    [addAdmins, removeAdmins],
  );

  const updateAdmins = useCallback(async () => {
    setLoading(true);
    // remove any users from addAdmins that are already in admins
    const newAddAdmins = addAdmins.filter(usr => !admins.includes(usr));

    // remove any users from removeAdmins that are not already in admins
    const newRemoveAdmins = removeAdmins.filter(usr => !admins.includes(usr));

    // there are admins that need to be added
    if (newAddAdmins.length) {
      await Api.addGroupAdmins({
        groupId: activeGroup.id,
        userIds: newAddAdmins.map(usr => usr.id),
      });
    }

    // there are admins that need to be removed
    if (newRemoveAdmins.length) {
      // make sure there will still be at least 1 admin remaining
      if (admins.length - newRemoveAdmins.length <= 0) {
        return alert('You cannot remove all of the admins.');
      } else {
        await Api.removeGroupAdmins({
          groupId: activeGroup.id,
          userIds: newRemoveAdmins.map(usr => usr.id),
        });
      }
    }

    // update the new admins arrays
    const newAdmins = [...admins, ...newAddAdmins].filter(usr => !newRemoveAdmins.includes(usr));

    setAdmins(newAdmins);
    setAddAdmins(newAdmins);
    setRemoveAdmins([]);
    setLoading(false);
    setShowAdminModal(false);
  }, [activeGroup, addAdmins, admins, removeAdmins]);

  // update settings when a toggle switch is clicked
  const updateSettings = useCallback(
    async (adminApproves, adminCreates) => {
      setLoading(true);

      const input = {
        id: activeGroup.id,
        adminMustApproveGroupMembers: adminApproves,
        onlyAdminCanCreateConversations: adminCreates,
      };

      const { error } = await Api.setAdminGroupSettings(input);

      if (error) {
        alert(error.message);
      }

      setLoading(false);
    },
    [Api, adminApproves, adminCreates, activeGroup],
  );

  // update notification settings when a toggle switch is clicked
  const updateNotifications = useCallback(
    async (anyVideo, adminVideo, videoReaction, videoTag) => {
      const input = {
        id: activeGroup.id,
        notificationsForAllVideos: anyVideo,
        notificationsForHostVideos: adminVideo,
        notificationsForReactions: videoReaction,
        notificationsForTagged: videoTag,
      };

      await Api.setUserGroupSettings(input);
    },
    [anyVideo, adminVideo, videoReaction, videoTag],
  );

  if (!activeGroup) {
    return null;
  }

  // if user is the only admin, & there are other members in the group, certain actions might be limited, like leaving the group
  // if the user is the only member of the group, then they can leave the group without any problems
  const groupHasOtherMembers =
    members && members[0] && (members.length > 1 || members[0].id !== userData.id);
  const isOnlyAdmin =
    admins && admins.length === 1 && admins[0].id === userData.id && groupHasOtherMembers;

  return fetchingGroup ? (
    <div className="GroupSettings">
      <div className="loading">
        <img src={LoadingImage} alt="loading" />
      </div>
    </div>
  ) : (
    <div className="GroupSettings">
      <SmallModal
        isOpen={showAdminModal}
        close={() => setShowAdminModal(false)}
        shouldOverlayClose={true}
      >
        <div className="AddAdminModal">
          <div className="title">Group Admins</div>
          {members.length ? (
            <div className="list">
              <ul>
                {members.length ? (
                  members.map((user, ind) => {
                    const isSelected =
                      (addAdmins.includes(user) || admins.find(admin => admin.id === user.id)) &&
                      !removeAdmins.includes(user);

                    return (
                      <li className="user" key={ind}>
                        {user.profileImageUrl ? (
                          <img src={user.profileImageUrl.url} alt="User Profile" />
                        ) : (
                          <div className="no-img" />
                        )}
                        <p>
                          {user.firstName} {user.lastName}
                        </p>
                        <div className="select">
                          <input
                            className="member-check"
                            type="checkbox"
                            checked={isSelected}
                            title={
                              isOnlyAdmin
                                ? 'Please assign a new group admin before removing yourself'
                                : null
                            }
                            onChange={evnt => updateUserStatus(evnt.target.checked, user)}
                          />
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <p className="empty-list">This group has no members.</p>
                )}
              </ul>
            </div>
          ) : (
            ''
          )}
          <Button
            label="Update Admins"
            loading={loading}
            disabled={loading}
            action={updateAdmins}
          />
        </div>
      </SmallModal>

      <SmallModal
        isOpen={showLeaveGroupModal}
        close={() => setShowLeaveGroupModal(false)}
        shouldOverlayClose={true}
      >
        <div className="LeaveGroupModal">
          <h3 className="sub-title">You are about to leave the group</h3>
          <h1 className="title">{activeGroup.name}</h1>
          <h3 className="sub-title">Are you sure?</h3>
          <div className="button-container">
            <Button
              label="Cancel"
              action={() => setShowLeaveGroupModal(false)}
              loading={loading}
              disabled={loading}
            />
            <Button
              label="Leave Group"
              specificity="critical"
              action={leaveGroup}
              loading={loading}
              disabled={loading}
            />
          </div>
        </div>
      </SmallModal>

      {showTopicModal ? (
        <TopicSettings
          showModal={showTopicModal}
          toggleModal={() => setShowTopicModal(!showTopicModal)}
          topicId={selectedTopic.id}
        />
      ) : (
        <div></div>
      )}

      <div className="container meta">
        <div className="back-button">
          <BackArrow onClick={backToGroups} />
          <p onClick={backToGroups}>Back to Group</p>
        </div>

        <div className="title">
          <h3>Group Settings</h3>
          <div className="underline" />
        </div>

        <FileUpload
          disabled={!isAdmin}
          className="upload-file"
          originalImg={image}
          onChange={setImage}
        />

        <div className="group-info">
          <InputField label="Group Name" value={name} onChange={setName} disabled={!isAdmin} />

          <InputField
            label="Description"
            className="description"
            value={description}
            onChange={setDescription}
            multiline={true}
            disabled={!isAdmin}
          />

          <InputField
            label="Invite Link"
            value={activeGroup && activeGroup.inviteLink && activeGroup.inviteLink.link}
            copiable
          />

          <div className="sub-title">
            <h1>Group Notifications</h1>
            <div className="underline" />
          </div>

          <div className="slack-btn-container">
            <a
              href="https://slack.com/oauth/v2/authorize?client_id=804758257940.1039028677060&scope=commands,incoming-webhook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Add to Slack"
                height="40"
                width="139"
                src="https://platform.slack-edge.com/img/add_to_slack.png"
                srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
              />
            </a>
          </div>
          <InputField
            label="Slack Subscribe"
            value={`/edconnect subscribe ${activeGroup.id}`}
            copiable
          />
        </div>
        <div className="actions">
          <div
            className={`leave-group ${isOnlyAdmin ? 'leave-group-disabled' : ''}`}
            onClick={() => {
              return isOnlyAdmin ? null : setShowLeaveGroupModal(true);
            }}
          >
            <p className="hover-title">
              You are the only admin! You must add another before leaving
            </p>
            <LeaveIcon />
            <p>Leave Group</p>
          </div>
          <Button
            className="update-btn"
            label="Update"
            disabled={!isAdmin || loading}
            action={updateGroup}
            loading={loading}
          />
        </div>
      </div>
      <div className="container lists">
        <div className="tab-bar">
          <p
            className={`${settingsView === SETTINGS_VIEWS.NOTIFICATIONS ? 'current' : ''}`}
            onClick={() => setSettingsView(SETTINGS_VIEWS.NOTIFICATIONS)}
          >
            Notifications
          </p>
          <p
            className={`${settingsView === SETTINGS_VIEWS.MEMBER ? 'current' : ''}`}
            onClick={() => setSettingsView(SETTINGS_VIEWS.MEMBER)}
          >
            Member
          </p>
          {isAdmin ? (
            <p
              className={`${settingsView === SETTINGS_VIEWS.ADMIN ? 'current' : ''}`}
              onClick={() => setSettingsView(SETTINGS_VIEWS.ADMIN)}
            >
              Admin
            </p>
          ) : (
            ''
          )}
        </div>

        {settingsView === SETTINGS_VIEWS.NOTIFICATIONS ? (
          <div className="list-containers">
            <div className="grouping notifications">
              <h3 className="title">Notifications</h3>
              <h3 className="sub-title">Send me a push notification when...</h3>
              <div className="list">
                <ul>
                  <li>
                    <ToggleSwitch
                      label="Any video is posted to the group"
                      disabled={loading}
                      checked={anyVideo}
                      action={event => {
                        setAnyVideo(event.target.checked);
                        updateNotifications(
                          event.target.checked,
                          adminVideo,
                          videoReaction,
                          videoTag,
                        );
                      }}
                    />
                  </li>
                  <li>
                    <ToggleSwitch
                      label="A group admin posts in the group"
                      disabled={loading}
                      checked={adminVideo}
                      action={event => {
                        setAdminVideo(event.target.checked);
                        updateNotifications(
                          anyVideo,
                          event.target.checked,
                          videoReaction,
                          videoTag,
                        );
                      }}
                    />
                  </li>
                  <li>
                    <ToggleSwitch
                      label="Someone reacts to my video"
                      disabled={loading}
                      checked={videoReaction}
                      action={event => {
                        setVideoReaction(event.target.checked);
                        updateNotifications(anyVideo, adminVideo, event.target.checked, videoTag);
                      }}
                    />
                  </li>
                  <li>
                    <ToggleSwitch
                      label="Someone tags me in a video"
                      disabled={loading}
                      checked={videoTag}
                      action={event => {
                        setVideoTag(event.target.checked);
                        updateNotifications(
                          anyVideo,
                          adminVideo,
                          videoReaction,
                          event.target.checked,
                        );
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : settingsView === SETTINGS_VIEWS.MEMBER ? (
          <div className="list-containers">
            <div className="grouping members">
              <h3 className="title">Members</h3>
              <div className="list">
                <ul>
                  {members.length ? (
                    members.map((user, ind) => (
                      <li className="user" key={ind}>
                        {user.profileImageUrl ? (
                          <img src={user.profileImageUrl.url} alt="User Profile" />
                        ) : (
                          <div className="no-img" />
                        )}
                        <p>
                          {user.firstName} {user.lastName}
                        </p>
                      </li>
                    ))
                  ) : (
                    <p className="empty-list">This group has no members.</p>
                  )}
                </ul>
              </div>
            </div>

            <div className="grouping topics">
              <h3 className="title">Topics</h3>
              <div className="list">
                <ul>
                  {topics.length ? (
                    topics.map((topic, ind) => (
                      <li
                        className="topic"
                        key={ind}
                        onClick={() => {
                          // open topic settings
                          setSelectedTopic(topic);
                          setShowTopicModal(true);
                        }}
                      >
                        {topic.publicImageUrl ? (
                          <img src={topic.publicImageUrl} alt="Topic" />
                        ) : (
                          <div className="no-img" />
                        )}
                        <p>{topic.name}</p>
                      </li>
                    ))
                  ) : (
                    <p className="empty-list">This group has no topics.</p>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="admin-settings">
            <div className="toggles">
              <ToggleSwitch
                label="Admin approves new members"
                disabled={loading}
                checked={adminApproves}
                action={event => {
                  setAdminApproves(event.target.checked);
                  updateSettings(event.target.checked, adminCreates);
                }}
              />

              <ToggleSwitch
                label="Only admins can create topics"
                disabled={loading}
                checked={adminCreates}
                action={event => {
                  setAdminCreates(event.target.checked);
                  updateSettings(adminApproves, event.target.checked);
                }}
              />
            </div>

            <div className="list-containers">
              <div className="grouping admins">
                <h3 className="title">Group Admins</h3>
                <div className="list">
                  <ul>
                    {/* ADD NEW ADMIN */}
                    {admins.length ? (
                      <li
                        className="user add-admin"
                        onClick={() => {
                          setShowAdminModal(true);
                        }}
                      >
                        <img src={AddNewButton} alt="Add New Admin" />
                        <p>Update Group Admins</p>
                      </li>
                    ) : (
                      ''
                    )}

                    {admins.length ? (
                      admins.map((user, ind) => (
                        <li className="user" key={ind}>
                          {user.profileImageUrl ? (
                            <img src={user.profileImageUrl.url} alt="User Profile" />
                          ) : (
                            <div className="no-img" />
                          )}
                          <p>
                            {user.firstName} {user.lastName}
                          </p>
                        </li>
                      ))
                    ) : (
                      <p className="empty-list">This group has no admins.</p>
                    )}

                    {!admins.length ? (
                      <li
                        className="user add-admin"
                        onClick={() => {
                          setShowAdminModal(true);
                        }}
                      >
                        <img src={AddNewButton} alt="Add New Admin" />
                        <p>Add Group Admin</p>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>
              </div>

              <div className="grouping approvals">
                <h3 className="title">Members to Approve</h3>
                <div className="list">
                  <ul>
                    {pendingMembers.length ? (
                      pendingMembers.map((pendingMember, ind) => (
                        <li className="user" key={ind}>
                          {pendingMember.publicImageUrl ? (
                            <img
                              src={
                                pendingMember.profileImageUrl && pendingMember.profileImageUrl.url
                              }
                              alt="Pending Member"
                            />
                          ) : (
                            <div className="no-img" />
                          )}
                          <p>
                            {pendingMember.firstName} {pendingMember.lastName}
                          </p>
                          <div className="controls">
                            <div
                              className="action reject"
                              onClick={() => {
                                handlePendingMemberCb(
                                  activeGroup,
                                  pendingMember,
                                  PENDING_MEMBER_ACTIONS.REJECT,
                                );
                              }}
                            >
                              &#10006;
                            </div>
                            <div
                              className="action approve"
                              onClick={() => {
                                handlePendingMemberCb(
                                  activeGroup,
                                  pendingMember,
                                  PENDING_MEMBER_ACTIONS.APPROVE,
                                );
                              }}
                            >
                              &#10003;
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <p className="empty-list">This group has no pending members.</p>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupSettings;
