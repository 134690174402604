import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

import Api from '../services/ApiHandler';

import MyContext from '../MyContext';

const ConversationContext = React.createContext();

function ConversationProvider({ children }) {
  const {
    state: { activeGroup },
  } = useContext(MyContext);

  const currentPath = useRouteMatch('/group/:groupId/conversation/:conversationId');

  const [currentConversation, setCurrentConversation] = useState(null);
  const [conversationFolders, setConversationFolders] = useState([]);
  const [conversationVideos, setConversationVideos] = useState([]);

  const fetchConversationFolders = useCallback(async parentId => {
    const conversations = await Api.conversationFolders(parentId);

    setConversationFolders(conversations.data.conversationFolders.items);
  }, []);

  const fetchConversationVideos = useCallback(async parentId => {
    const videos = await Api.conversationVideos(parentId);

    setConversationVideos(videos.data.conversationVideos.items);
  }, []);

  const addNewConversation = useCallback(
    conversation => {
      let conversations = [...conversationFolders];
      conversations.push(conversation);

      setConversationFolders(conversations);
    },
    [conversationFolders],
  );

  // when url changes (via changing active group, reloading the page, initial page load, clicking on a folder, etc), set currentConversation accordingly
  useEffect(() => {
    let conversationId = currentPath?.params?.conversationId;

    setCurrentConversation(conversationId || activeGroup?.id);
  }, [currentPath, currentConversation, activeGroup]);

  // when currentConversation (active group or folder) changes, fetch data accordingly
  useEffect(() => {
    if (currentConversation && typeof currentConversation === 'string') {
      if (currentConversation.includes(':DynamicConversations')) {
        fetchConversationVideos(currentConversation);
      } else {
        setConversationVideos([]);
      }

      fetchConversationFolders(currentConversation);
    }
  }, [currentConversation]);

  return (
    <ConversationContext.Provider
      value={{
        conversationFolders,
        conversationVideos,
        currentConversation,
        addNewConversation,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
}

export { ConversationContext };

export default ConversationProvider;
