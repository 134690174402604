import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { ViewContext } from '../providers';

function Breadcrumb() {
  const { breadcrumbPath, selectBreadcrumbItem } = useContext(ViewContext);
  const [path, setPath] = useState([]);

  const updateLocalPath = useCallback(
    newPath => {
      setPath(newPath);
    },
    [setPath],
  );

  useEffect(() => {
    updateLocalPath(breadcrumbPath);
  }, [breadcrumbPath]);

  return (
    <div className="Breadcrumb">
      <ul>
        {path.map((part, ind, arr) => (
          <React.Fragment key={part.path}>
            <li>
              <Link to={part.path} onClick={() => selectBreadcrumbItem(part.path)}>
                {part.label}
              </Link>
            </li>
            {ind !== arr.length - 1 ? (
              <li>
                <span className="middle-dot">&#183;</span>
              </li>
            ) : (
              ''
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
}

export default Breadcrumb;
