import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import MyProvider from './MyProvider';
import App from './App';

import MergeProviders from './providers';

import './styles/index.scss';

// Navigating to a new page is a 'PUSH', Using the browser's back/forward buttons is a 'POP'
// On a PUSH we scroll to the top of the page, on a POP we do nothing & let the browser take the
// user back to their previous scroll location for that page
function handleScroll(_, navigationType) {
  if (navigationType === 'PUSH') {
    window.scroll(0, 0);
  }
}

function getHistory() {
  const history = createBrowserHistory();
  history.listen(handleScroll);
  return history;
}

export const history = getHistory();

if (process.env.NODE_ENV === 'production') {
  const platformEnvironment = window.location.origin.includes('https://dev.')
    ? 'development'
    : 'production';

  Sentry.init({
    dsn: 'https://f068cbcb91424578a64c19e1ab571e9b@o415272.ingest.sentry.io/5306092',
    environment: platformEnvironment,
  });

  Sentry.configureScope(scope => {
    scope.setTag('platform', process.env.REACT_APP_PLATFORM);
  });
}

ReactDOM.render(
  <Router onChange={handleScroll} history={history}>
    <MyProvider history={history}>
      <MergeProviders>
        <App />
      </MergeProviders>
    </MyProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
