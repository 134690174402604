import React, { useState, useEffect, useCallback } from 'react';

import SmallModal from '../SmallModal';
import InputField from '../InputField';
import Button from '../Button';

import { ReactComponent as WatchIcon } from '../../images/playback-icons/icon-watch.svg';
import { ReactComponent as HeartIcon } from '../../images/playback-icons/icon-heart.svg';
import { ReactComponent as TagIcon } from '../../images/playback-icons/icon-tag.svg';
import { ReactComponent as ShareIcon } from '../../images/playback-icons/icon-share.svg';
import { ReactComponent as InviteIcon } from '../../images/playback-icons/icon-invite.svg';
import { ReactComponent as ProfileIcon } from '../../images/playback-icons/icon-profile.svg';
import { ReactComponent as DeleteIcon } from '../../images/buttons/close.svg';

import { ReactComponent as HeartReaction } from '../../images/reactions/reaction-heart.svg';
import { ReactComponent as LikeReaction } from '../../images/reactions/reaction-like.svg';
import { ReactComponent as ExclaimReaction } from '../../images/reactions/reaction-exclaim.svg';
import { ReactComponent as QuestionReaction } from '../../images/reactions/reaction-question.svg';
import { ReactComponent as LolReaction } from '../../images/reactions/reaction-lol.svg';

function VideoOptionsModal({
  isOpen,
  closeModal,
  videoReactions,
  videoViews,
  inviteUrl,
  videoTags,
  groupMembers,
  tagUsers,
  untagUsers,
  videoId,
  topics,
  shareVideoToTopic,
  isShareable,
  archiveVideo,
  deleteMessage,
  isAdmin,
  directMessage,
}) {
  function sortUsers(a, b) {
    const aName = `${a.firstName} ${a.lastName}`;
    const bName = `${b.firstName} ${b.lastName}`;

    if (aName > bName) return 1;
    if (aName < bName) return -1;
    return 0;
  }

  const [currentView, setCurrentView] = useState('options');
  const [taggedSearch, setTaggedSearch] = useState('');
  const [tagged, setTagged] = useState(videoTags.sort(sortUsers));
  const [untagged, setUntagged] = useState(groupMembers);

  const [filteredTagged, setFilteredTagged] = useState(null);
  const [filteredUntagged, setFilteredUntagged] = useState(null);

  // searchbar function to filter users by name
  const updateTaggedLists = useCallback(() => {
    const newTagged = tagged.filter(user =>
      user.name.toLowerCase().includes(taggedSearch.toLowerCase()),
    );

    const newUntagged = untagged.filter(user =>
      user.name.toLowerCase().includes(taggedSearch.toLowerCase()),
    );

    setFilteredTagged(newTagged);
    setFilteredUntagged(newUntagged);
  }, [tagged, untagged, taggedSearch]);

  useEffect(() => {
    const taggedIds = tagged.map(user => user.id);
    const filteredGroupMembers = groupMembers.filter(user => !taggedIds.includes(user.id));

    setUntagged(filteredGroupMembers.sort(sortUsers));
  }, [groupMembers, videoTags]);

  useEffect(() => {
    if (taggedSearch) {
      updateTaggedLists();
    } else {
      setFilteredTagged(null);
      setFilteredUntagged(null);
    }
  }, [taggedSearch]);

  const tagUser = useCallback(
    user => {
      setUntagged(untagged.filter(usr => usr.id !== user.id));

      let newTagged = tagged;
      newTagged.push(user);
      setTagged(newTagged.sort(sortUsers));

      setTaggedSearch('');
      setFilteredTagged(null);
      setFilteredUntagged(null);

      tagUsers(videoId, [user.id]);
    },
    [tagged, untagged],
  );

  const untagUser = useCallback(
    user => {
      setTagged(tagged.filter(usr => usr.id !== user.id));

      let newUntagged = untagged;
      newUntagged.push(user);
      setUntagged(newUntagged.sort(sortUsers));

      setTaggedSearch('');
      setFilteredTagged(null);
      setFilteredUntagged(null);

      untagUsers(videoId, [user.id]);
    },
    [tagged, untagged],
  );

  return (
    <SmallModal isOpen={isOpen} close={closeModal}>
      <div className="VideoOptionsModal">
        <div className="header">
          {currentView !== 'options' && (
            <button className="back" onClick={() => setCurrentView('options')}>
              &#x3c; Back
            </button>
          )}

          <h1 className="title">{currentView.charAt(0).toUpperCase() + currentView.slice(1)}</h1>
        </div>

        {/* INITIAL OPTIONS */}
        <ul className={`options ${currentView === 'options' ? 'show' : 'hide'}`}>
          <li className="option" onClick={() => setCurrentView('views')}>
            <div className="meta">
              <WatchIcon className="icon" />
              <p className="label">Video Views</p>
            </div>

            <p className="count">{videoViews.length}</p>
          </li>

          <li className="option" onClick={() => setCurrentView('reactions')}>
            <div className="meta">
              <HeartIcon className="icon small" />
              <p className="label">Video Reactions</p>
            </div>

            <p className="count">{videoReactions.length}</p>
          </li>

          <li className="option" onClick={() => setCurrentView('tags')}>
            <div className="meta">
              <TagIcon className="icon" />
              <p className="label">Video Tags</p>
            </div>

            <p className="count">{tagged.length}</p>
          </li>

          {isShareable && (
            <li className="option" onClick={() => setCurrentView('share')}>
              <div className="meta">
                <ShareIcon className="icon" />
                <p className="label">Share Video</p>
              </div>
            </li>
          )}

          <li className="option" onClick={() => setCurrentView('invite')}>
            <div className="meta">
              <InviteIcon className="icon" />
              <p className="label">Invite to Group</p>
            </div>
          </li>

          {(directMessage ? directMessage.message.authorId === directMessage.userId : isAdmin) && (
            <li className="option delete" onClick={() => setCurrentView('delete')}>
              <div className="meta">
                <DeleteIcon className="icon" />
                <p className="label">Delete Video</p>
              </div>
            </li>
          )}
        </ul>

        {/* VIDEO VIEWS */}
        <ul className={`options ${currentView === 'views' ? 'show' : 'hide right'}`}>
          {videoViews.map((view, ind) => (
            <li key={ind} className="option noclick">
              <div className="meta">
                {view.user.profileImageUrl && view.user.profileImageUrl.url ? (
                  <img
                    src={view.user.profileImageUrl.url}
                    alt="user profile"
                    className="icon large"
                  />
                ) : (
                  <ProfileIcon className="icon large" />
                )}
                <p className="label">
                  {view.user.firstName} {view.user.lastName}
                </p>
              </div>
            </li>
          ))}
        </ul>

        {/* VIDEO REACTIONS */}
        <ul className={`options ${currentView === 'reactions' ? 'show' : 'hide right'}`}>
          {videoReactions.map((reaction, ind) => (
            <li key={ind} className="option noclick">
              <div className="meta">
                {reaction.reactionType === 'HEART' && <HeartReaction className="icon large" />}
                {reaction.reactionType === 'EXCLAMATION' && (
                  <ExclaimReaction className="icon large" />
                )}
                {reaction.reactionType === 'THUMBS_UP' && <LikeReaction className="icon large" />}
                {reaction.reactionType === 'LOL' && <LolReaction className="icon large" />}
                {reaction.reactionType === 'QUESTION' && (
                  <QuestionReaction className="icon large" />
                )}

                <p className="label">
                  {reaction.user.firstName} {reaction.user.lastName}
                </p>
              </div>
            </li>
          ))}
        </ul>

        {/* VIDEO TAGS */}
        <div className={`view ${currentView === 'tags' ? 'show' : 'hide'}`}>
          <InputField placeholder="Search" value={taggedSearch} onChange={setTaggedSearch} />

          <p className="list-label">Tagged to Video</p>
          <ul className="options">
            {(filteredTagged ? filteredTagged : tagged).map((User, ind) => {
              return (
                <li key={ind} className="option noclick">
                  <div className="meta">
                    {User.profileImageUrl && User.profileImageUrl.url ? (
                      <img
                        src={User.profileImageUrl.url}
                        alt="user profile"
                        className="icon large"
                      />
                    ) : (
                      <ProfileIcon className="icon large" />
                    )}

                    <p className="label">
                      {User.firstName} {User.lastName}
                    </p>
                  </div>

                  <Button className="tag-btn untag" label="Untag" action={() => untagUser(User)} />
                </li>
              );
            })}
          </ul>

          <p className="list-label">Not Tagged to Video</p>
          <ul className="options">
            {(filteredUntagged ? filteredUntagged : untagged).map((User, ind) => {
              return (
                <li key={ind} className="option noclick">
                  <div className="meta">
                    {User.profileImageUrl && User.profileImageUrl.url ? (
                      <img
                        src={User.profileImageUrl.url}
                        alt="user profile"
                        className="icon large"
                      />
                    ) : (
                      <ProfileIcon className="icon large" />
                    )}
                    <p className="label">
                      {User.firstName} {User.lastName}
                    </p>
                  </div>

                  <Button className="tag-btn tag" label="Tag" action={() => tagUser(User)} />
                </li>
              );
            })}
          </ul>
        </div>

        {/* SHARE VIDEO */}
        {isShareable && (
          <div className={`view ${currentView === 'share' ? 'show' : 'hide'}`}>
            <p className="list-label">Available Topics</p>
            <ul className="options">
              {topics.map((topic, ind) => (
                <li key={ind} className="option noclick">
                  <div className="meta">
                    {topic.publicImageUrl ? (
                      <img
                        src={topic.publicImageUrl}
                        alt="topic"
                        style={{ height: '25px', width: '25px', borderRadius: '5px' }}
                      />
                    ) : (
                      <ProfileIcon className="icon large" />
                    )}

                    <p className="label">{topic.displayName}</p>
                  </div>

                  <Button
                    className="tag-btn tag"
                    label="Share"
                    action={() => shareVideoToTopic(videoId, topic.id)}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* INVITE TO GROUP */}
        <div className={`view ${currentView === 'invite' ? 'show' : 'hide'}`}>
          <ul className="options">
            <li className="option noclick wide">
              <InputField copiable label="Invite URL" value={inviteUrl} />
            </li>
          </ul>
        </div>

        {/* DELETE VIDEO */}
        {(directMessage ? directMessage.message.authorId === directMessage.userId : isAdmin) && (
          <div className={`view ${currentView === 'delete' ? 'show' : 'hide'}`}>
            <ul className="options">
              <li className="option noclick">
                <div className="meta wide">
                  <p className="label center">Are you sure you want to delete this video?</p>
                </div>
              </li>

              <li className="option noclick norow">
                <div className="meta wide">
                  <Button
                    className="confirm-btn"
                    label="Cancel"
                    action={() => setCurrentView('options')}
                  />
                  <Button
                    className="confirm-btn delete"
                    label="Delete"
                    action={async () => {
                      if (directMessage) {
                        await deleteMessage({
                          videoId: directMessage.message.video.id,
                          messageId: directMessage.message.messageId,
                          directMessageId: directMessage.directMessageId,
                        });
                      } else {
                        await archiveVideo(videoId);
                      }

                      closeModal({ videoDeleted: true });
                    }}
                  />
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </SmallModal>
  );
}

export default VideoOptionsModal;
