import Api from './ApiHandler';
import { isValidPhoneNumber } from 'react-phone-number-input';

const LtiService = {
  ltiUser: undefined,
  course: undefined,
  enrolledUsers: [],
  group: undefined,
  loggedOut: false,

  isLti() {
    // if Lti cookie exists
    if (this.ltiUser !== undefined) {
      return true;
    }

    try {
      if (!this.loggedOut) {
        this.ltiUser = JSON.parse(this.getCookie('user'));
        if (this.ltiUser !== undefined) {
          this.course = this.getCookie('course');
          this.enrolledUsers = JSON.parse(this.getCookie('enrolledUsers'));
          return true;
        }
      }
    } catch (e) {
      console.log(`LtiService: ${e}`);
    }

    return false;
  },
  async login() {
    if (!this.ltiUser.isInstructor) {
      console.log('Logging in user ' + this.ltiUser.email);
      try {
        return await Api.ltiLogin({
          ltiSecret: process.env.REACT_APP_LTI_SECRET,
          name: this.ltiUser.name,
          email: this.ltiUser.email,
        });
      } catch (e) {
        return {
          data: null,
          error: e,
        };
      }
    }

    return {
      data: null,
      error: null,
    };
  },
  logout() {
    this.loggedOut = true;
    this.ltiUser = undefined;
    this.ltiUser = undefined;
    this.course = undefined;
    this.enrolledUsers = [];
    this.group = undefined;
  },
  async createGroupByCourse() {
    if (this.isLti() && this.ltiUser.isInstructor) {
      let response = {};
      try {
        response = await Api.getMyGroupsList();
        if (response.data.groupList.items.length > 0) {
          console.log('Group already exists');
          this.group = response.data.groupList.items.filter(group => group.name === this.course);
          console.log('GroupId: ' + this.group.id);
        }

        if (this.group === undefined) {
          console.log('Creating group for course ' + this.course);
          response = await Api.createGroup({
            name: this.course,
            description: null,
          });

          console.log('Created groupId: ' + response.data.createGroup.group.id);
          this.group = response.data.createGroup.group;
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  async createUsers() {
    if (this.isLti() && this.ltiUser.isInstructor) {
      if (this.group === undefined) {
        console.error('No valid course group.  Cannot create and add users.');
        return;
      }
      for (let student of this.enrolledUsers) {
        if (student.phoneNumber !== undefined && !isValidPhoneNumber(student.phoneNumber.trim())) {
          student.phoneNumber = '+1' + student.phoneNumber.trim();
          if (!isValidPhoneNumber(student.phoneNumber.trim())) {
            console.error('Invalid user phone number ' + student.phoneNumber.trim());
            continue;
          }
        }

        let response = {};
        try {
          console.log('Creating user for student ' + student.email);
          response = await Api.createLtiUser({
            name: student.name,
            email: student.email,
            phoneNumber: student.phoneNumber,
          });

          if (response !== null) {
            await this.addUserToGroup(response.data.createUser);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  },
  async addUserToGroup(groupUser) {
    try {
      console.log('Adding user ' + groupUser.id + ' to group ' + this.group.id);
      let response = await Api.addUserToGroup({
        groupId: this.group.id,
        userId: groupUser.id,
      });

      console.log(response);
    } catch (e) {
      console.error(e);
    }
  },
  getCookie(name) {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
      let [k, v] = el.split('=');
      cookie[k.trim()] = v;
    });

    return unescape(cookie[name]);
  },
};

export default LtiService;
