import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImagePreview = ({ url, alt, className, ...props }) => {
  const [imageUrl, setImageUrl] = useState(url);

  return imageUrl ? (
    <img
      className={`ImagePreview ${className}`}
      src={typeof imageUrl === 'object' ? imageUrl.url : imageUrl}
      onError={() => {
        setImageUrl(null);
      }}
      alt={alt}
      {...props}
    />
  ) : (
    <div className={`ImagePreview broken ${className}`} {...props} />
  );
};

ImagePreview.propTypes = {
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default ImagePreview;
