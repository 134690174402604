import React, { useState } from 'react';

import SmallModal from '../SmallModal';
import InputField from '../InputField';
import Button from '../Button';

const AttachUrlModal = ({ isOpen, close, attachedUrl }) => {
  const [url, setUrl] = useState(attachedUrl || 'https://');
  const [isValid, setIsValid] = useState(false);
  const [hasProperFormatting, setHasProperFormatting] = useState(false);

  const validateUrl = checkOnlyFormatting => {
    const pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

    if (url.match(pattern)) {
      setHasProperFormatting(true);

      if (checkOnlyFormatting) {
        return true;
      }

      setIsValid(true);
      return true;
    }

    setHasProperFormatting(false);
    setIsValid(false);
    return false;
  };

  return (
    <SmallModal
      isOpen={isOpen}
      close={() => {
        close();
      }}
    >
      <div className="AttachUrlModal">
        <h1 className="title">Attach link to this video</h1>

        <p className="sub-title">
          You can optionally add a URL to this video that will show up when the video is being
          watched.
        </p>

        <div className="preview-url">
          {isValid ? <iframe src={url} /> : <p>Website Preview</p>}
          <div className="iframe-cover" />
        </div>

        <p className="mini-text">
          Some sites will not have a preview. As long as the URL is correct, you can still attach
          it.
        </p>

        <div className="actions">
          <InputField
            value={url}
            className="attach-url-input"
            onChange={newValue => {
              if (isValid) {
                setIsValid(false);
              }

              validateUrl(true);

              // if the user selects all the text and then deletes is, delete everything but the 'https://'
              if (newValue.length <= 1) {
                setUrl('https://' + newValue);
                return;
              }

              // stop the user from removing the 'https://' (it's required for the iframe)
              if (newValue.substr(0, 8) !== 'https://') {
                return;
              }

              setUrl(newValue);
            }}
          />

          <div className="buttons">
            <Button
              label="Preview Site"
              disabled={!hasProperFormatting}
              action={() => validateUrl()}
            />

            <Button
              label="Attach URL"
              disabled={!hasProperFormatting}
              action={() => {
                const valid = validateUrl();

                if (!valid) {
                  close();
                } else {
                  close(url);
                }
              }}
            />
          </div>
        </div>
      </div>
    </SmallModal>
  );
};

export default AttachUrlModal;
