/**
 * @param {function} props.onChange -> The function that runs when a new file has been chosed.
 * @param {string} props.originalImg -> if there is an image that exists for this show it otherwise use default icon image and label
 * @param {boolean} props.disabled -> if true, the file upload component is non-functional
 * @param {boolean} props.rounded -> if true, the component is fully rounded, not just with rounded corners
 * @description This is the file upload component. It should be used anywhere an image needs to be uploaded (profile pictures, group cover photos, etc).
 */

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import CameraIcon from '../images/camera-icon.png';

const FileUpload = ({ onChange, originalImg, className, disabled, rounded }) => {
  const [files, setFiles] = useState([]);
  const [displayImg, setDisplayImg] = useState(originalImg);

  const uploadFiles = useCallback(
    event => {
      // if the user has already selected a file, then re-opens the file browser but clicks `cancel`, event.target.files will have a length of 0 (because no file was selected). in this case, we don't want to remove the file they previously selected, so we simply prevent the setFiles function from overwriting the files array declared above
      if (files.length > event.target.files.length) {
        return;
      }

      if (onChange) {
        onChange(event.target.files[0]);
      }

      setFiles(Array.from(event.target.files));
    },
    [files, setFiles],
  );

  return (
    <div className={`FileUpload ${className}`}>
      <label
        htmlFor="file-actual"
        className={`file-pretty${files.length || originalImg ? ' full' : ''}${
          rounded ? ' rounded' : ''
        }`}
      >
        {displayImg ? (
          <img
            src={displayImg}
            className="file-image"
            width="200"
            height="150"
            alt="upload image"
            onError={() => {
              setDisplayImg(null);
            }}
          />
        ) : (
          <div className="no-file-preview">
            <img src={CameraIcon} className="file-pretty-img" alt="choose a file to upload" />
            <p className="file-pretty-plus">+</p>
          </div>
        )}
      </label>

      <input
        type="file"
        className="file-actual"
        id="file-actual"
        onChange={uploadFiles}
        multiple={false}
        disabled={disabled}
        accept="image/*"
      />

      {/* if there is a file to display, render the file above the default `choose image` upload */}
      {files.length ? (
        <div className="file-preview">
          {files.map(file => {
            return (
              <React.Fragment key={file}>
                {/* this label overlays the rest of the content, but is invisible. when clicked, open the file browser for the user to change images if they wish */}
                <label htmlFor="file-actual" className="file-preview-change-img" />
                <img
                  src={URL.createObjectURL(file)}
                  height="200"
                  width="200"
                  className="file-image"
                  alt="user upload"
                />
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

FileUpload.propTypes = {
  onChange: PropTypes.func,
  originalImg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
};

export default FileUpload;
