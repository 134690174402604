import React from 'react';
import PropTypes from 'prop-types';

function Dropdown({ trigger, children, showDropdown, className }) {
  return (
    <div className={`Dropdown ${className}`}>
      <div className="dropdown-trigger">{trigger}</div>
      <div className={`dropdown-content ${showDropdown ? 'active' : 'inactive'}`}>{children}</div>
    </div>
  );
}

Dropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  showDropdown: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Dropdown;
