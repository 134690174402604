import Queries from './Queries';
import Mutations from './Mutations';
import ApolloClient from 'apollo-boost';
import LocalStorage from './LocalStorage';
import { InMemoryCache } from 'apollo-cache-inmemory';
import getFormattedPlatformName from '../utils/getFormattedPlatformName';

let JWT = LocalStorage.getItem('JWT');
const CachePolicies = {
  CACHE_FIRST: 'cache-first',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  CACHE_ONLY: 'cache-only',
  NO_CACHE: 'no-cache',
};
const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_URL,
  cache: new InMemoryCache(),
  request: async operation => {
    if (!JWT) {
      JWT = LocalStorage.getItem('JWT');
    }

    const token = `Bearer ${JWT}`;

    operation.setContext({
      headers: {
        'apollographql-client-name': 'say-web',
        'apollographql-client-version': '1.0.0',
        authorization: token,
      },
    });
  },
});

const Api = {
  query: (query, variables, cachePolicy = {}) => {
    return apolloClient
      .query({
        query,
        variables,
        fetchPolicy: cachePolicy ? cachePolicy : CachePolicies.CACHE_AND_NETWORK,
      })
      .catch(err => {
        console.log('error in query: ', query.loc.source, 'variables: ', variables);
        console.log(err);
        return { error: err };
      });
  },
  mutation: (mutation, variables = {}) => {
    return apolloClient
      .mutate({
        mutation,
        variables,
      })
      .catch(err => {
        console.log('error in mutation: ', mutation.loc.source, 'variables: ', variables);
        console.log(err);
        return { error: err };
      });
  },
  getGroupInfo(id) {
    return Api.query(
      Queries.GET_GROUP,
      { input: { id, appPlatform: getFormattedPlatformName().toUpperCase() } },
      CachePolicies.NETWORK_ONLY,
    );
  },
  getGroupUsers(includeAdmins, cursor, id) {
    return Api.query(
      Queries.GET_GROUP_USERS,
      { includeAdmins, cursor, id },
      CachePolicies.NETWORK_ONLY,
    );
  },
  getPendingGroupMembers(cursor = null, groupId) {
    return Api.query(
      Queries.GET_PENDING_GROUP_MEMBERS,
      { cursor: null, groupId },
      CachePolicies.NETWORK_ONLY,
    );
  },
  denyPendingGroupMember(input) {
    // input { groupId, userId }
    return Api.mutation(Mutations.DENY_PENDING_GROUP_MEMBER, { input });
  },
  approvePendingGroupMember(input) {
    // input { groupId, userId }
    return Api.mutation(Mutations.APPROVE_PENDING_GROUP_MEMBER, { input });
  },
  getGroupByVanityPath(vanityPath) {
    return Api.query(Queries.GET_GROUP_BY_VANITY_PATH, { vanityPath });
  },
  getGroupConversations(id, cursor) {
    return Api.query(Queries.GET_GROUP_CONVERSATIONS, { id, cursor }, CachePolicies.NETWORK_ONLY);
  },
  conversationFolders(parentId) {
    return Api.query(Queries.CONVERSATION_FOLDERS, { parentId }, CachePolicies.NETWORK_ONLY);
  },
  authenticate(input) {
    // input needs phoneNumber
    return Api.mutation(Mutations.AUTHENTICATE, {
      input: {
        ...input,
        appPlatform: getFormattedPlatformName().toUpperCase(),
      },
    });
  },
  authenticateWithEmail(input) {
    // input needs email
    return Api.mutation(Mutations.AUTHENTICATE_WITH_EMAIL, {
      input: {
        ...input,
        appPlatform: getFormattedPlatformName().toUpperCase(),
      },
    });
  },
  login(input) {
    // input needs { phoneNumber, authToken }
    return Api.mutation(Mutations.LOGIN, { input });
  },
  me() {
    return Api.query(Queries.ME, null, CachePolicies.NETWORK_ONLY);
  },
  getMyGroupsList() {
    return Api.query(Queries.GET_MY_GROUPS_LIST, null, CachePolicies.NETWORK_ONLY);
  },
  createConversation(input) {
    // input needs {name, groupId, initialVideoId (optional )}
    return Api.mutation(Mutations.CREATE_CONVERSATION, { input });
  },
  updateConversation(input) {
    // input { id, name, publicImageUrl }
    return Api.mutation(Mutations.UPDATE_CONVERSATION, { input });
  },
  getConversationDetails(id, cursor) {
    return Api.query(Queries.GET_CONVERSATION_DETAILS, { id, cursor }, CachePolicies.NETWORK_ONLY);
  },
  archiveConversation(id) {
    return Api.mutation(Mutations.ARCHIVE_CONVERSATION, { id });
  },
  watchVideo(videoId) {
    return Api.mutation(Mutations.WATCH_VIDEO, { id: videoId });
  },
  archiveVideo(videoId) {
    return Api.mutation(Mutations.ARCHIVE_VIDEO, { videoId });
  },
  deleteMessage(input) {
    return Api.mutation(Mutations.DELETE_MESSAGE, { input });
  },
  flagVideo(videoId, flagType = 'INAPPROPRIATE') {
    const flagVideoInput = {
      videoId,
      flagType,
    };
    return Api.mutation(Mutations.FLAG_VIDEO, { input: flagVideoInput });
  },
  unflagVideo(input) {
    // input needs videoId
    return Api.mutation(Mutations.UNFLAG_VIDEO, { input });
  },
  getFlaggedGroupVideos(groupId) {
    return Api.query(Queries.GROUP_FLAGGED_VIDEOS, { id: groupId });
  },
  joinGroup(groupId) {
    return Api.mutation(Mutations.JOIN_GROUP, { id: groupId });
  },
  createGroup(input) {
    // input needs {name}
    return Api.mutation(Mutations.CREATE_GROUP, { input });
  },
  updateGroup(input) {
    // input { id, name, descriptions, publicImageUrl }
    return Api.mutation(Mutations.UPDATE_GROUP, { input });
  },
  addUserToGroup(input) {
    // input needs {userId, groupId}
    return Api.mutation(Mutations.ADD_USER_TO_GROUP, { input });
  },
  blockUserFromGroup(input) {
    // input needs {userId, groupId)
    return Api.mutation(Mutations.BLOCK_USER_FROM_GROUP, { input });
  },
  addGroupAdmins(input) {
    // input needs { groupId, userIds(array)}
    return Api.mutation(Mutations.ADD_GROUP_ADMINS, { input });
  },
  removeUserFromGroup(input) {
    // input needs { groupId, userId }
    return Api.mutation(Mutations.REMOVE_USER_FROM_GROUP, { input });
  },
  removeGroupAdmins(input) {
    // input needs groupId, userIds: []
    return Api.mutation(Mutations.REMOVE_ADMIN_USERS, { input });
  },
  addReaction(input) {
    // input needs { videoId, reactionType, offset }
    return Api.mutation(Mutations.ADD_REACTION, { input });
  },
  removeReaction(input) {
    // input needs { videoId, reactionType }
    return Api.mutation(Mutations.REMOVE_REACTION, { input });
  },
  makePendingVideoAction(input) {
    return Api.mutation(Mutations.MAKE_PENDING_VIDEO_ACTION, {
      vidAction: input.action,
      id: input.videoId,
    });
  },
  updateUser(input) {
    return Api.mutation(Mutations.UPDATE_USER, { input });
  },
  publicImageUrl() {
    return Api.query(Queries.PUBLIC_IMAGE_URL);
  },
  setMyUserSettings(input) {
    //
    return Api.mutation(Mutations.SET_MY_USER_SETTINGS, { input });
  },
  setUserGroupSettings(input) {
    // input = { id, notificationsForAllVideos, notificationsForHostVideos, notificationsForReactions, notificationsForTagged }
    return Api.mutation(Mutations.SET_USER_GROUP_SETTINGS, { input });
  },
  setConversationSettings(input) {
    // input = { id, adminMustApproveNewVideos, onlyAdminCanPostVideos }
    return Api.mutation(Mutations.SET_CONVERSATION_SETTINGS, { input });
  },
  conversationVideos(id, cursor = null) {
    return Api.query(Queries.CONVERSATION_VIDEOS, { id, cursor }, CachePolicies.NETWORK_ONLY);
  },
  setAdminGroupSettings(input) {
    // input = { id, onlyAdminCanCreateConversations, adminMustApproveGroupMembers }
    return Api.mutation(Mutations.SET_ADMIN_GROUP_SETTINGS, { input });
  },
  createVideoPromise(input) {
    console.log(`createVideoPromise payload: ${JSON.stringify(input)}`);
    return Api.mutation(Mutations.CREATE_VIDEO_PROMISE, { input });
  },
  tagUsersInVideo(input) {
    return Api.mutation(Mutations.TAG_USERS_IN_VIDEO, { input });
  },
  untagUsersInVideo(input) {
    return Api.mutation(Mutations.UNTAG_USERS_IN_VIDEO, { input });
  },
  createDirectMessage(input) {
    // input is userIds:[],  groupId
    return Api.mutation(Mutations.CREATE_DIRECT_MESSAGE, { input });
  },
  createMessage(input) {
    // input = { directMessageId, message, type }
    return Api.mutation(Mutations.CREATE_MESSAGE, { input });
  },
  getDirectMessages(id, cursor) {
    // groupId
    return Api.query(Queries.GET_DIRECT_MESSAGES, { groupId: id, cursor }, CachePolicies.NO_CACHE);
  },
  getVideoDetails(id) {
    return Api.query(Queries.GET_VIDEO_DETAILS, { id }, CachePolicies.NETWORK_ONLY);
  },
  hideDirectMessage(input) {
    return Api.mutation(Mutations.HIDE_DIRECT_MESSAGE, { input });
  },
  setLastVisitedForGroup(groupId) {
    return Api.mutation(Mutations.SET_LAST_VISITED_FOR_GROUP, { groupId });
  },
  getDeviceTokens() {
    return Api.query(Queries.DEVICE_TOKENS, null, CachePolicies.NETWORK_ONLY);
  },
  registerDeviceToken(input) {
    // input  = { userId, token, tokenType }
    return Api.mutation(Mutations.REGISTER_DEVICE_TOKEN, { input });
  },
  removeDeviceToken(input) {
    // input = { token }
    return Api.mutation(Mutations.REMOVE_DEVICE_TOKEN, { input });
  },
  getNotifications() {
    return Api.query(Queries.GET_NOTIFICATIONS);
  },
  pinTopic(topicId) {
    const input = { id: topicId };
    return Api.mutation(Mutations.PIN_TOPIC, { input });
  },
  unpinTopic(topicId) {
    return Api.mutation(Mutations.UNPIN_TOPIC, { id: topicId });
  },
  createLtiUser(input) {
    // input = { name, email, phoneNumber }
    return Api.mutation(Mutations.CREATE_LTI_USER, { input });
  },
  ltiLogin(input) {
    return Api.mutation(Mutations.LTI_LOGIN, { input });
  },
  getVideoReactions(input) {
    return Api.query(Queries.GET_VIDEO_REACTIONS, input);
  },
  getVideoViews(input) {
    return Api.query(Queries.GET_VIDEO_VIEWS, input);
  },
  getTaggedUsers(input) {
    return Api.query(Queries.GET_TAGGED_USERS, input);
  },
  shareVideoToTopic(input) {
    return Api.mutation(Mutations.SHARE_VIDEO_TO_CONVERSATION, { input });
  },
};

export default Api;
