import React from 'react';

import SmallModal from '../SmallModal';
import Button from '../Button';

import { ReactComponent as NoCamera } from '../../images/no-photo.svg';

import getFormattedPlatformName from '../../utils/getFormattedPlatformName';

function RequestPermissions(props) {
  return (
    <SmallModal {...props}>
      <div className="RequestPermissionsModal">
        <h4 className="title">Permission Request</h4>
        <div className="empty-video">
          <span className="empty" />

          <NoCamera />

          <div className="not-active">
            <p className="not-active-text">Your microphone and camera are not active.</p>
          </div>
        </div>

        <h4 className="copy-title">
          In order for you to record a video, you will need to grant {getFormattedPlatformName()}{' '}
          access to your microphone and webcam.
        </h4>

        <p className="copy-body">
          You can do that by clicking the button below, then clicking <strong>Allow</strong> in the
          browser popup!
        </p>

        <Button
          label="Allow Microphone and Camera Access"
          action={async () => {
            // request browser device permissions
            try {
              await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true,
              });

              // user has granted permissions
              props.close(true);
            } catch (err) {
              // user has denied permissions
              props.close(true);
            }
          }}
        />
      </div>
    </SmallModal>
  );
}

export default RequestPermissions;
