import React, { createContext, useState, useCallback, useEffect } from 'react';
import DetectRTC from 'detectrtc';

import LocalStorage from '../services/LocalStorage';

const ViewContext = createContext();

// if you add a new sort type, make sure to also update the preview page to include functionality for the set sort type
const sortByOptions = {
  mostRecent: 'Most Recent',
  alphabetical: 'Alphabetical',
};

const viewTypes = {
  collapsed: 'COLLAPSED',
  expanded: 'EXPANDED',
};

function ViewProvider({ children }) {
  const [sortBy, setSortBy] = useState(sortByOptions.mostRecent);
  const [viewType, setViewType] = useState(viewTypes.expanded);
  const [showSidebar, setShowSidebar] = useState(true);
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);
  const [browserInfo, setBrowserInfo] = useState(false);

  // update viewType based on local storage preset
  // update sidebar based on local storage
  useEffect(() => {
    let currentView = LocalStorage.getItem('viewType');
    let currentSidebarPosition = LocalStorage.getItem('showSidebar');

    // if no current preset, make one
    if (!currentView) {
      currentView = viewTypes.expanded; // set up default view type
      LocalStorage.setItem('viewType', currentView);
    }

    // if no current preset, make one
    if (typeof currentSidebarPosition !== 'boolean') {
      currentSidebarPosition = true; // show sidebar by default
      LocalStorage.setItem('showSidebar', currentSidebarPosition);
    }

    setViewType(currentView);
    setShowSidebar(currentSidebarPosition);

    function checkActiveGroup() {
      const activeGroup = LocalStorage.getItem('activeGroup');

      if (activeGroup) {
        setNewBreadcrumbPath({
          path: `/group/${activeGroup.id}`,
          label: 'Home',
        });
      }
    }

    window.addEventListener('edconnect:active-group:change', checkActiveGroup);

    // set default breadcrumb path based on active group
    checkActiveGroup();

    DetectRTC.load(() => {
      setBrowserInfo({
        isMobile: DetectRTC.isMobileDevice,
      });
    });

    return () => {
      window.removeEventListener('edconnect:active-group:change', checkActiveGroup);
    };
  }, []);

  // update viewType both in the current session and in local storage
  const updateViewType = useCallback(
    newType => {
      setViewType(newType);

      LocalStorage.setItem('viewType', newType);
    },
    [setViewType],
  );

  // update viewType both in the current session and in local storage
  const updateShowSidebar = useCallback(
    newPosition => {
      setShowSidebar(newPosition);

      LocalStorage.setItem('showSidebar', newPosition);
    },
    [setShowSidebar],
  );

  const addBreadcrumbItem = useCallback(
    pathObj => {
      let path = [...breadcrumbPath];

      path.push(pathObj);

      setBreadcrumbPath(path);
    },
    [breadcrumbPath, setBreadcrumbPath],
  );

  const selectBreadcrumbItem = useCallback(
    pathStr => {
      const path = [...breadcrumbPath];
      const index = path.findIndex(crumb => crumb.path === pathStr);

      if (index >= 0) {
        setBreadcrumbPath(path.splice(0, index + 1));
      }
    },
    [breadcrumbPath, setBreadcrumbPath],
  );

  const setNewBreadcrumbPath = useCallback(
    pathObj => {
      setBreadcrumbPath([pathObj]);
    },
    [setBreadcrumbPath],
  );

  return (
    <ViewContext.Provider
      value={{
        sortByOptions,
        sortBy,
        setSortBy,
        viewTypes,
        viewType,
        setViewType: updateViewType,
        showSidebar,
        setShowSidebar: updateShowSidebar,
        breadcrumbPath,
        addBreadcrumbItem,
        selectBreadcrumbItem,
        setNewBreadcrumbPath,
        browserInfo,
      }}
    >
      {children}
    </ViewContext.Provider>
  );
}

export { ViewContext };

export default ViewProvider;
