import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import validateEmail from '../utils/validateEmail';

import Api from '../services/ApiHandler';
import LocalStorage from '../services/LocalStorage';
import UserHelper from '../services/UserHelpers';
import LtiService from '../services/LtiService';

import MyContext from '../MyContext';
import { ViewContext } from '../providers';

import Dropdown from '../components/Dropdown';
import InputField from '../components/InputField';
import Button from '../components/Button';

import EdConnectLogo from '../images/branding/edconnect-logo.png';
import SayLogo from '../images/branding/say-logo.png';

import { ReactComponent as DropdownArrow } from '../images/multimedia-option.svg';

import getFormattedPlatformName from '../utils/getFormattedPlatformName';

function Landing() {
  const context = useContext(MyContext);
  const { showSidebar, setShowSidebar } = useContext(ViewContext);

  const nameInput = useRef();
  const phoneInput = useRef();
  const emailInput = useRef();
  const codeInput = useRef();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const [hasRequestedCode, setHasRequestedCode] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [authType, setAuthType] = useState('Mobile Phone');

  useEffect(() => {
    setShowSidebar(false);
  }, []);

  useEffect(() => {
    (async function() {
      // if user is logged in, they should be redirected to the group page
      if (context.state.loggedIn) {
        // Lti setup
        if (LtiService.isLti()) {
          await LtiService.createGroupByCourse();
          await LtiService.createUsers();
        }

        if (!context.state.groups) {
          await context.fetchSidebarGroups();
        }

        // redirects to the first group (if user has no groups, it'll stay on the landing page)
        context.setActiveGroup();
      } else {
        if (LtiService.isLti()) {
          const { data, error } = await LtiService.login();

          if (error) {
            console.error(error);
          }

          if (data) {
            setLoading(true);
            // save data to global state and local store
            await LocalStorage.setItem('userData', data.ltiLogin.user);
            await LocalStorage.setItem('JWT', data.ltiLogin.token);

            context.setGlobalState('userData', data.ltiLogin.user);
            context.setGlobalState('userName', UserHelper.getUserName(data.ltiLogin.user));
            context.toggleLoggedIn();
            setShowSidebar(!showSidebar);
            await context.fetchSidebarGroups();

            const firstGroup = context.state.groups ? context.state.groups[0] : null;
            await context.setActiveGroup(firstGroup);

            setLoading(false);
          }
        }
      }
    })();
  }, [context.state.loggedIn]);

  const authenticate = useCallback(async () => {
    // === check for invalid inputs === //
    let errors = [];

    // must have name
    if (!name.trim() || name.trim().split(' ').length < 2) {
      errors.push('name');
    }

    if (authType === 'Mobile Phone') {
      if (!phone || !isValidPhoneNumber(phone.trim())) {
        errors.push('phone');
      }
    } else {
      if (!email.trim() || !validateEmail(email.trim())) {
        errors.push('email');
      }
    }

    if (errors.length) {
      setErrors(errors);
      if (errors.includes('name')) {
        nameInput.current.focus();
      } else if (errors.includes('phone')) {
        phoneInput.current.focus();
      } else if (errors.includes('email')) {
        emailInput.current.focus();
      }

      return;
    }

    setLoading(true);

    // request code from server
    let error;

    if (phone) {
      const { error: err } = await Api.authenticate({
        phoneNumber: phone.trim(),
      });
      error = err;
    } else {
      const { error: err } = await Api.authenticateWithEmail({
        email: email.trim(),
        name: name.trim(),
      });
      error = err;
    }

    setLoading(false);

    if (error) {
      return alert(error.message);
    }

    setCode('');
    setHasRequestedCode(true);
  }, [phone, name, email, nameInput.current, phoneInput.current, emailInput.current]);

  const login = useCallback(async () => {
    if (!code.trim() || code.trim().length !== 6) {
      setErrors(['auth code']);
      codeInput.current.focus();
      return;
    }

    setLoading(true);

    const { data, error } = await Api.login({
      phoneNumber: phone.trim(),
      authToken: code,
      name: name.trim(),
      email: email.trim(),
    });

    if (error) {
      setCode('');
      setErrors(['auth code']);
      setLoading(false);
      codeInput.current.focus();
      return;
    }

    // save data to global state and local store
    await LocalStorage.setItem('userData', data.webLogin.user);
    await LocalStorage.setItem('JWT', data.webLogin.token);

    context.setGlobalState('userData', data.webLogin.user);
    context.setGlobalState('userName', UserHelper.getUserName(data.webLogin.user));

    try {
      context.setGlobalState('profileImage', data.webLogin.user.profileImageUrl.url);
    } catch (e) {
      //
    }

    // do we have a group set aside from dynamic link?
    const groupId = await LocalStorage.getItem('DynamicLinkGroup');

    if (groupId) {
      const { error } = await Api.joinGroup(groupId);

      if (error) {
        console.log(error);
      }

      await LocalStorage.removeItem('DynamicLinkGroup');
    }

    context.toggleLoggedIn();
    setShowSidebar(!showSidebar);
    await context.fetchSidebarGroups();

    const firstGroup = context.state.groups ? context.state.groups[0] : null;
    await context.setActiveGroup(firstGroup);

    setLoading(false);
  }, [code, codeInput.current]);

  return (
    <div className="LandingPage">
      {!context.state.loggedIn ? (
        <React.Fragment>
          <div className="container branding">
            <div className="background-tilted" />
            <div className="content">
              {process.env.REACT_APP_PLATFORM === 'say' ? (
                <img src={SayLogo} alt="Say | Virtual Classroom" className="logo" />
              ) : (
                <img src={EdConnectLogo} alt="EdConnect | Virtual Classroom" className="logo" />
              )}
              <div className="separator" />
              <p className="copy">
                Stay connected and keep the conversation real with {getFormattedPlatformName()}.
              </p>
            </div>
          </div>
          <div className="container login">
            <div className="form">
              {!hasRequestedCode ? (
                <React.Fragment>
                  <h3 className="sign-in">Sign In</h3>
                  <InputField
                    error={!!errors.includes('name')}
                    value={name}
                    onChange={name => {
                      setName(name);
                      setErrors(errors.filter(err => err !== 'name'));
                    }}
                    required
                    label="First & Last Name"
                    placeholder="First Last"
                    ref={nameInput}
                  />
                  <Dropdown
                    showDropdown={showDropdown}
                    className="authentication-dropdown"
                    trigger={
                      <InputField
                        label="Send authentication code to:"
                        value={authType}
                        className="input-dropdown"
                        iconRight={<DropdownArrow className="dropdown-arrow" />}
                        onFocus={() => setShowDropdown(true)}
                      />
                    }
                  >
                    <ul>
                      <li
                        tabIndex={0}
                        onClick={() => {
                          setShowDropdown(false);
                          setAuthType('Mobile Phone');
                        }}
                        onKeyDown={evnt => {
                          if (evnt.key === 'Enter') {
                            setShowDropdown(false);
                            setAuthType('Mobile Phone');
                          }
                        }}
                      >
                        Mobile Phone
                      </li>
                      <li
                        tabIndex={0}
                        onClick={() => {
                          setShowDropdown(false);
                          setAuthType('Email');
                        }}
                        onKeyDown={evnt => {
                          if (evnt.key === 'Enter') {
                            setShowDropdown(false);
                            setAuthType('Email');
                          }
                        }}
                      >
                        Email
                      </li>
                    </ul>
                  </Dropdown>
                  {authType === 'Mobile Phone' ? (
                    <InputField
                      ref={phoneInput}
                      error={!!errors.includes('phone')}
                      aria-invalid={!!errors.includes('phone')}
                      value={phone}
                      onChange={phone => {
                        setPhone(phone);
                        setErrors(errors.filter(err => err !== 'phone'));
                      }}
                      required
                      label="Phone Number"
                      type="phone"
                    />
                  ) : (
                    <InputField
                      ref={emailInput}
                      error={!!errors.includes('email')}
                      aria-invalid={!!errors.includes('email')}
                      value={email}
                      onChange={email => {
                        setEmail(email);
                        setErrors(errors.filter(err => err !== 'email'));
                      }}
                      required
                      label="Email"
                      type="email"
                    />
                  )}
                  <Button
                    action={authenticate}
                    disabled={(!phone && !email) || !name || !name.trim() || loading}
                    loading={loading}
                    label="Send Code"
                    className="auth-btn"
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <React.Fragment>
                    <h3 className="sign-in">Authenticate</h3>
                    <InputField
                      error={
                        errors.includes('auth code') && 'Enter the 6-digit number you received'
                      }
                      label={`Enter the code sent to ${phone || email}`}
                      className="login-code"
                      value={code}
                      onChange={authCode => {
                        setCode(authCode);
                        setErrors(errors.filter(err => err !== 'auth code'));
                      }}
                      onFocus={evnt => (evnt.target.value = code)}
                      required
                      ref={codeInput}
                      autoFocus
                      autoComplete="off"
                    />

                    <Button
                      action={login}
                      disabled={!code || loading}
                      label="Submit"
                      className="auth-btn"
                      loading={loading}
                    />
                  </React.Fragment>
                </React.Fragment>
              )}
            </div>
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
    </div>
  );
}

export default Landing;
