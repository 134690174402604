import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { S3Upload } from '../services/UploaderService';
import Api from '../services/ApiHandler';

import MyContext from '../MyContext';
import { ViewContext } from '../providers';

import SmallModal from './SmallModal';
import Button from './Button';
import FileUpload from './FileUpload';
import InputField from './InputField';
import ImagePreview from './ImagePreview';

import TopicSettings from './modals/TopicSettings';
import AddContent from './modals/AddContent';

import { formatTimeDisplay, formatHowLongAgo } from '../utils/helpers';

import { ReactComponent as Elipses } from '../images/menu-icons/elipses-no-border.svg';
import { ReactComponent as Folder } from '../images/menu-icons/folder.svg';

const VIEWS = {
  TOPICS: 'Topic',
  DIRECT_MESSAGES: 'Direct Message',
};

function GroupMessagePreview({
  view = 'TOPICS',
  currentConversation,
  conversations = [],
  videos = [],
  members = [],
}) {
  const context = useContext(MyContext);
  const {
    sortByOptions,
    sortBy,
    viewTypes,
    viewType,
    addBreadcrumbItem,
    browserInfo: { isMobile },
  } = useContext(ViewContext);

  const history = useHistory();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [showTopicSettingsModal, setShowTopicSettingsModal] = useState(null);
  const [showDMSettingsModal, setShowDMSettingsModal] = useState(false);

  const [showAddContentModal, setShowAddContentModal] = useState(false);

  // settings for new topic
  const [showCreateTopicModal, setShowCreateTopicModal] = useState(false);
  const [topicName, setTopicName] = useState('');
  const [topicImg, setTopicImg] = useState(null);

  // settings for new direct message
  const [showCreateDMModal, setShowCreateDMModal] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);

  function openNewConversationModal() {
    if (view === 'TOPICS') {
      setShowCreateTopicModal(true);
    } else {
      setShowCreateDMModal(true);
    }
  }

  async function createConversation() {
    setLoading(true);

    if (view === 'TOPICS') {
      const { data } = await Api.createConversation({
        name: topicName.trim(),
        groupId: context.state.activeGroup.id,
      });

      const uploadUrl = data.createConversation.publicImageUploadUrl.uploadUrl.url;
      const downloadUrl = data.createConversation.publicImageUploadUrl.downloadUrl;

      if (topicImg) {
        await S3Upload(topicImg, uploadUrl);

        await Api.updateConversation({
          id: data.createConversation.conversation.id,
          name: topicName.trim(),
          publicImageUrl: downloadUrl || null,
        });
      }

      setTopicName('');
      setTopicImg(null);
      setShowCreateTopicModal(false);
    } else {
      // create new DM
      let selected = [...selectedMembers];
      selected.push(context.state.userData.id);

      setSelectedMembers(selected);

      const { error } = await Api.createDirectMessage({
        userIds: selected,
        groupId: context.state.activeGroup.id,
      });

      if (error) {
        console.log(`error: ${error}`);
      }

      await context.getDirectMessages();

      setSelectedMembers([]);

      setShowCreateDMModal(false);
    }

    setLoading(false);
  }

  function openConversationSettings(conversation) {
    if (view === 'TOPICS') {
      setShowTopicSettingsModal(conversation.id);
    } else {
      setShowDMSettingsModal(conversation);
    }
  }

  function handleCardClick(conversation, video) {
    const conversationType = view === 'TOPICS' ? 'topic' : 'directMessage';
    const conversationId = view === 'TOPICS' ? conversation.id : conversation.directMessageId;

    if (conversation && video) {
      history.push(
        `/group/${params.groupId}/${conversationType}/${conversationId}/video/${video.id}`,
      );
    } else {
      history.push(`/group/${params.groupId}/${conversationType}/${conversationId}/record`);
    }
  }

  function handleFolderClick(conversation) {
    if (conversation.__typename === 'DirectMessage') {
      history.push(
        `/group/${params.groupId}/directMessage/${conversation.directMessageId}/video/${conversation.previewVideos[0]?.id}`,
      );
    } else {
      const path = `/group/${params.groupId}/conversation/${conversation.id}`;

      addBreadcrumbItem({
        label: conversation.name,
        path,
      });
      history.push(path);
    }
  }

  async function hideDirectMessage(conversationId) {
    setLoading(true);

    const directMessage = context.state.directMessages.find(
      directMessage => conversationId === directMessage.directMessageId,
    );

    await Api.hideDirectMessage({
      directMessageId: directMessage.directMessageId,
      groupId: directMessage.groupId,
    });

    await context.getDirectMessages();

    setLoading(false);
    setShowDMSettingsModal(null);
  }

  function handleMemberSelection(isSelected, member) {
    const selected = [...selectedMembers];

    if (isSelected) {
      selected.push(member.id);
    } else {
      const selectedInd = selected.findIndex(mem => mem === member.id);

      if (selectedInd !== -1) {
        selected.splice(selectedInd, 1);
      }
    }

    setSelectedMembers(selected);
  }

  useEffect(() => {
    if (!context.state.activeGroup?.inviteLink) {
      context.fetchGroupDetails(context.state.activeGroup.id);
    }
  }, []);

  return (
    <div className="GroupMessagePreview">
      {showTopicSettingsModal && (
        <TopicSettings
          showModal={!!showTopicSettingsModal}
          toggleModal={() => setShowTopicSettingsModal(null)}
          topicId={showTopicSettingsModal}
        />
      )}

      {showDMSettingsModal && (
        <SmallModal
          isOpen={!!showDMSettingsModal}
          close={() => setShowDMSettingsModal(null)}
          shouldOverlayClose={true}
        >
          <div className="ConfirmDeleteDMModal">
            <div className="title">
              Would you like to delete your messages with {showDMSettingsModal.displayName}?
            </div>

            <div className="actions">
              <Button
                label="No, keep this DM"
                specificity="critical"
                action={() => {
                  setShowDMSettingsModal(null);
                }}
                disabled={loading}
              />
              <Button
                label="Yes, delete this DM"
                action={() => {
                  hideDirectMessage(showDMSettingsModal.directMessageId);
                }}
                disabled={loading}
              />
            </div>
          </div>
        </SmallModal>
      )}

      {showAddContentModal && (
        <AddContent
          close={() => setShowAddContentModal(false)}
          viewType={view}
          inviteUrl={context.state.activeGroup?.inviteLink?.link}
          groupId={context.state.activeGroup?.id}
        />
      )}

      {showCreateTopicModal && (
        <SmallModal
          isOpen={showCreateTopicModal}
          close={() => setShowCreateTopicModal(false)}
          shouldOverlayClose={true}
        >
          <div className="CreateTopicModal">
            <h1 className="modal-title">Create Topic</h1>
            <FileUpload onChange={setTopicImg} />

            <InputField label="Topic Name" value={topicName} onChange={setTopicName} />

            <Button
              label="Create Topic"
              className="modal-button"
              disabled={!topicName.trim()}
              loading={loading}
              action={createConversation}
            />
          </div>
        </SmallModal>
      )}

      {showCreateDMModal && (
        <SmallModal
          isOpen={showCreateDMModal}
          close={() => setShowCreateDMModal(false)}
          shouldOverlayClose={true}
        >
          <div className="CreateDMModal">
            <h1 className="modal-title">Who would you like to talk to?</h1>

            <ul className="list">
              {members.map((member, ind) => {
                const isSelected = !!selectedMembers.find(mem => mem === member.id);

                // a user cannot have a DM with themselves. remove them from the list
                if (context.state.userData.id === member.id) {
                  return;
                }

                return (
                  <li key={ind} className="item">
                    <div
                      className="meta"
                      onClick={() => handleMemberSelection(!isSelected, member)}
                    >
                      {member.profileImageUrl ? (
                        <img
                          className="img"
                          src={member.profileImageUrl.url}
                          alt="member profile"
                        />
                      ) : (
                        <div className="img empty" />
                      )}

                      <p className="name">
                        {member.firstName} {member.lastName}
                      </p>
                    </div>

                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isSelected}
                      onChange={evnt => handleMemberSelection(evnt.target.checked, member)}
                    />
                  </li>
                );
              })}
            </ul>

            <div className="actions">
              <Button
                label="Cancel"
                specificity="critical"
                className="modal-button"
                loading={loading}
                action={() => setShowCreateDMModal(false)}
              />
              <Button
                label="Create"
                className="modal-button"
                disabled={!selectedMembers.length}
                loading={loading}
                action={createConversation}
              />
            </div>
          </div>
        </SmallModal>
      )}

      <div className="new-conversation-container">
        <Button
          className="add-content-btn"
          label={!isMobile ? 'Add Content' : ''}
          iconLeft={() => <div className="plus-icon" />}
          action={() => setShowAddContentModal(true)}
        />
      </div>

      <div className="conversation-container">
        {videos.length ? (
          <div className="conversation">
            <div className={`cards ${viewType === viewTypes.expanded ? 'expanded' : 'collapsed'}`}>
              {videos
                .sort((a, b) => {
                  // pinned conversations should always stay at the top
                  if (a.isPinned) return 0;

                  if (sortBy === sortByOptions.mostRecent) {
                    if (a.lastVideoDate < b.lastVideoDate) return 1;
                    if (a.lastVideoDate > b.lastVideoDate) return -1;
                    return 0;
                  } else {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                  }
                })
                .map((video, ind) => {
                  return (
                    <div
                      className={`card${!video.isWatched ? ' unread' : ''}`}
                      key={ind}
                      onClick={() => handleCardClick({ id: currentConversation }, video)}
                    >
                      <img
                        className="preview-giphy"
                        src={video.streams[0] ? video.streams[0].previewGifUrl.url : null}
                        alt="video preview"
                      />
                      <div className="overlay">
                        <p className="timestamp">{formatHowLongAgo(video.publishedAt)}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : null}

        {conversations
          .sort((a, b) => {
            // pinned conversations should always stay at the top
            if (a.isPinned) return 0;

            if (sortBy === sortByOptions.mostRecent) {
              if (a.lastVideoDate < b.lastVideoDate) return 1;
              if (a.lastVideoDate > b.lastVideoDate) return -1;
              return 0;
            } else {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            }
          })
          .map((conversation, ind) => {
            let displayImage;

            if (conversation.publicImageUrl) {
              displayImage = conversation.publicImageUrl;
            } else if (conversation.members && conversation.members.length === 2) {
              const otherUser = conversation.members.find(
                member => member.id !== context.state.userData.id,
              );

              if (otherUser.profileImageUrl) {
                displayImage = otherUser.profileImageUrl.url;
              }
            }

            return (
              <div className="conversation" key={ind}>
                <div className="meta-container">
                  <div
                    className="conversation-image"
                    onClick={() => handleFolderClick(conversation)}
                  >
                    <Folder />
                    <ImagePreview
                      url={displayImage}
                      alt="Conversation Cover"
                      className="profile-picture"
                    />
                  </div>
                  <div className="meta" onClick={() => handleFolderClick(conversation)}>
                    <p className="title">{conversation.name || conversation.displayName}</p>
                    <p className="timestamp">
                      {conversation.isPinned
                        ? 'Pinned'
                        : formatTimeDisplay(conversation.lastVideoDate)}
                    </p>
                  </div>
                  <div className="options" onClick={() => openConversationSettings(conversation)}>
                    <Elipses />
                  </div>
                </div>
                <div
                  className={`cards ${viewType === viewTypes.expanded ? 'expanded' : 'collapsed'}`}
                >
                  <div className="card" onClick={() => handleCardClick(conversation)}>
                    <div className="overlay full">
                      <p className="label">Record Video</p>
                    </div>
                  </div>

                  {conversation.previewVideos
                    .filter(video => {
                      if (video.streams && video.streams.length) {
                        return video;
                      }

                      return false;
                    })
                    .map((video, vidIndex) => {
                      let previewGifUrl = null;
                      if (
                        video.streams[0] &&
                        video.streams[0].previewGifUrl &&
                        video.streams[0].previewGifUrl.url
                      ) {
                        previewGifUrl = video.streams[0].previewGifUrl.url;
                      }
                      return (
                        <div
                          className={`card${!video.isWatched ? ' unread' : ''}`}
                          key={vidIndex}
                          onClick={() => handleCardClick(conversation, video)}
                        >
                          <img className="preview-giphy" src={previewGifUrl} alt="video preview" />
                          <div className="overlay">
                            <p className="timestamp">{formatHowLongAgo(video.publishedAt)}</p>
                          </div>

                          {vidIndex >= 10 && conversation.previewVideos.length - 1 === vidIndex && (
                            <div className="overlay full">
                              <p className="label">View All</p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default GroupMessagePreview;
