import React, { useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import MyContext from '../MyContext';
import { ViewContext } from '../providers';

import Dropdown from './Dropdown';
import Breadcrumb from './Breadcrumb';

import { ReactComponent as ShareIcon } from '../images/share.svg';
import { ReactComponent as LightningIcon } from '../images/lightning.svg';
import { ReactComponent as PaperAirplaneIcon } from '../images/paper-airplane.svg';
import { ReactComponent as DropdownArrow } from '../images/multimedia-option.svg';

import { ReactComponent as CollapsedMenu } from '../images/menu-icons/collapsed-menu.svg';
import { ReactComponent as ExpandedMenu } from '../images/menu-icons/expanded-menu.svg';
import { ReactComponent as InfoIcon } from '../images/menu-icons/info.svg';

import { ReactComponent as LeftChevron } from '../images/navigation-icons/left-chevron.svg';

const Header = ({ activeGroup, memberCount, setSelectedView, selectedView, hasNewDM }) => {
  const context = useContext(MyContext);
  const {
    sortByOptions,
    sortBy,
    setSortBy,
    viewTypes,
    viewType,
    setViewType,
    showSidebar,
    setShowSidebar,
  } = useContext(ViewContext);
  const history = useHistory();
  const params = useParams();

  const [showDropdown, setShowDropdown] = useState(false);

  const { setShowGroupInviteModal } = context;

  // if we don't have an active group, don't display the group header
  if (!activeGroup || !Object.keys(activeGroup).length) return null;

  return (
    <header className="Header">
      <div className="metadata">
        <div className="hamburger-container" onClick={() => setShowSidebar(!showSidebar)}>
          <LeftChevron />
        </div>
        <div className="group-info">
          <h2 className="group-name">{activeGroup.name || 'Loading...'}</h2>
          <p className="group-members">{memberCount + ` member${memberCount > 1 ? 's' : ''}`}</p>
        </div>
        <div className="spacer" />
        <div className="group-actions">
          <div className="button group-invite" onClick={() => setShowGroupInviteModal(true)}>
            <ShareIcon className="icon" />
            <p>Invite Someone</p>
          </div>
          <div
            className="button group-settings"
            onClick={() => {
              history.push(`/group/${params.groupId}/settings`);
            }}
          >
            <InfoIcon className="icon" />
            <p className="action-label">Group Settings</p>
          </div>
        </div>
      </div>
      <div className="action-container">
        <div className="actions">
          <div
            className={`button topics ${selectedView === 'TOPICS' ? 'selected' : 'not-selected'}`}
            onClick={() => setSelectedView('TOPICS')}
          >
            <LightningIcon className={`icon ${selectedView === 'TOPICS' ? '' : 'gray-svg'}`} />
            <div className="action-label">Topics</div>
          </div>
          <div
            className={`button direct-messages ${
              selectedView === 'DIRECT_MESSAGES' ? 'selected' : 'not-selected'
            }`}
            onClick={() => setSelectedView('DIRECT_MESSAGES')}
          >
            <PaperAirplaneIcon
              className={`icon ${selectedView === 'DIRECT_MESSAGES' ? '' : 'gray-svg'}`}
            />

            <div className="action-label">
              Direct Messages {hasNewDM ? <span className="unread" /> : null}
            </div>
          </div>
        </div>
      </div>
      <div className="navigation">
        <Breadcrumb />
      </div>

      <div className="filters">
        <Dropdown
          showDropdown={showDropdown}
          className="filter-dropdown"
          trigger={
            <div className="filter-type" onClick={() => setShowDropdown(true)}>
              <p>{sortBy}</p>
              <DropdownArrow className="dropdown-arrow" />
            </div>
          }
        >
          <ul>
            {Object.values(sortByOptions).map((sortBy, index) => (
              <li
                key={index}
                tabIndex={0}
                onClick={() => {
                  setShowDropdown(false);
                  setSortBy(sortBy);
                }}
                onKeyDown={evnt => {
                  if (evnt.key === 'Enter' || evnt.key === ' ') {
                    setShowDropdown(false);
                    setSortBy(sortBy);
                  }
                }}
              >
                {sortBy}
              </li>
            ))}
          </ul>
        </Dropdown>
        <div className="view-type-container">
          {viewType === viewTypes.collapsed ? (
            <div className="view collapsed" onClick={() => setViewType(viewTypes.expanded)}>
              <CollapsedMenu />

              <p className="view-text">Collapsed</p>
            </div>
          ) : (
            <div className="view expanded" onClick={() => setViewType(viewTypes.collapsed)}>
              <ExpandedMenu />

              <p className="view-text">Expanded</p>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
