/**
 * @param {string} props.label -> The text that is displayed inside of the Button
 * @param {string} props.specificity -> The visual level of the button. default is `primary`, and that makes the button dark blue. `secondary` is a light blue, which would be used in the `Settings` button on the group page, for example
 * @param {boolean} props.disabled -> if true, the button will be grayed-out and not be clickable. This will prevent the `action` from running
 * @param {function} props.action -> The callback function that runs when the button is clicked
 * @param {string} props.className -> Used to add any default classNames to the element. The styling of those elements should overwrite any default stylings.
 * @param {func} props.iconLeft -> The optional icon that displays before the label text.
 * @param {func} props.iconRight -> The optional icon that displays after the label text.
 *
 * @description This is the button component. It should be used wherever a button is needed so as to maintain common styling. This button has a stylesheet at `/styles/components/button.scss` which can be used for reference. The button takes 100% width of it's containing element.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../images/loading.gif';

function Button({
  label,
  specificity,
  disabled,
  action,
  className,
  iconLeft,
  iconRight,
  loading = false,
}) {
  return (
    <React.Fragment>
      <button
        disabled={disabled}
        className={`Button ${specificity} ${disabled ? 'disabled' : ''} ${className || ''}`}
        onClick={action}
      >
        {loading ? (
          <div className="loading-container">
            <img
              width="25"
              height="25"
              style={{ animation: `spin 3s linear infinite` }}
              src={Loading}
              alt="loading"
            />
          </div>
        ) : (
          <div className="button-content">
            {iconLeft ? iconLeft() : ''} {label} {iconRight ? iconRight() : ''}
          </div>
        )}
      </button>
    </React.Fragment>
  );
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  specificity: PropTypes.oneOf(['primary', 'secondary', 'critical']),
  disabled: PropTypes.bool,
  action: PropTypes.func.isRequired,
  className: PropTypes.string,
  iconLeft: PropTypes.func,
  iconRight: PropTypes.func,
};

Button.defaultProps = {
  specificity: 'primary',
};

export default Button;
