const User = {
  // format and return how we want the display username to be
  getUserName(user) {
    let firstName;
    let lastName;
    lastName = user.lastName ? user.lastName : '';
    firstName = user.firstName ? user.firstName : '';
    return `${firstName} ${lastName}`;
  },
};

export default User;
