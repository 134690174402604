import { useCallback, useEffect, useRef, useState } from 'react';
import { RecordRTCPromisesHandler, MediaStreamRecorder, StereoAudioRecorder } from 'recordrtc';
import DetectRTC from 'detectrtc';

const RecorderErrors = {
  AbortError: 'media_aborted',
  NotAllowedError: 'permission_denied',
  NotFoundError: 'no_specified_media_found',
  NotReadableError: 'media_in_use',
  OverconstrainedError: 'invalid_media_constraints',
  TypeError: 'no_constraints',
  NONE: '',
  NO_RECORDER: 'recorder_error',
};

const RTCRecorder = ({
  render,
  audio = null,
  video = null,
  mediaType,
  onStop = () => null,
  mediaRecorderOptions = null,
}) => {
  const mediaRecorder = useRef(null);

  const mediaStream = useRef(null);
  const [status, setStatus] = useState('idle');

  const mediaBlobUrls = useRef([]);
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
  const [error, setError] = useState('NONE');

  const showVideo = useRef(video && video.deviceId !== 'SCREEN_CAPTURE');
  const showScreen = useRef(video && video.deviceId === 'SCREEN_CAPTURE');

  const showDevice = useRef(null);
  const soundDevice = useRef(null);

  const getMediaStream = useCallback((vid, aud) => {
    setStatus('acquiring_media');

    DetectRTC.load(async () => {
      if (
        !DetectRTC.isWebsiteHasMicrophonePermissions ||
        !DetectRTC.isWebsiteHasWebcamPermissions
      ) {
        return null;
      }

      try {
        const video = vid || showDevice.current;
        const audio = aud || soundDevice.current;

        // if the user has chosen to upload a file, don't attempt to start a stream
        if (((mediaType === 'video' && !video) || !video.deviceId) && !audio.deviceId) {
          return;
        }

        // start recording screen by opening up the screen capture modal provided by the browser
        if (video && video.deviceId === 'SCREEN_CAPTURE') {
          const stream = await window.navigator.mediaDevices.getDisplayMedia({
            video: {
              height: 1080,
              width: 1920,
            },
          });

          if (audio) {
            const audioStream = await window.navigator.mediaDevices.getUserMedia({
              audio: {
                deviceId: audio.deviceId,
              },
            });

            // attach the default audio input to the stream
            audioStream.getAudioTracks().forEach(audioTrack => stream.addTrack(audioTrack));
          }

          mediaStream.current = stream;
        } else {
          // the user has requested a device change, but not a screen share. request the specified device and start streaming from that device

          // with this video tag, we could set the deviceId to `exact`, but not setting that allows the code to default to their default device if the specified device is unavailable
          const stream = await window.navigator.mediaDevices.getUserMedia({
            audio: audio
              ? {
                  deviceId: audio.deviceId,
                }
              : undefined,
            video:
              mediaType === 'video'
                ? {
                    deviceId: video.deviceId,
                    width: 1920,
                    height: 1080,
                  }
                : undefined,
          });

          mediaStream.current = stream;
        }

        setStatus('idle');
        return;
      } catch (error) {
        setError(error.name);
        setStatus('idle');
      }
    });
  }, []);

  // ========== MEDIA RECORDER HANDLERS ========== //

  // event triggered when the video stops recording. create the mp4 file, and generate the local blob url
  const handleGetBlob = useCallback(
    async blob => {
      // convert mediaChunks into an mp4 blob
      if (!blob) {
        if (!mediaRecorder.current) {
          return;
        }

        blob = await mediaRecorder.current.getBlob();
      }

      // get local url of blob for video preview
      const url = URL.createObjectURL(blob);

      // get height and width of video track
      const tracks = mediaStream.current.getVideoTracks();
      const settings = tracks[0].getConstraints();

      setStatus('stopped');
      const newBlob = {
        blob: blob,
        url: url,
        size: {
          height: settings.height,
          width: settings.width,
        },
      };

      setMediaBlobUrl(newBlob);

      // add the blob url to the array that's passed up to the parent component
      mediaBlobUrls.current.push(url);

      onStop(newBlob);
    },
    [onStop],
  );

  const stopRecording = useCallback(async () => {
    // stop the input stream from the media device. this will also remove the indicator light. this is what we want, but it also makes the live-preview display a black image, so depending on UI designs, we may need to do something different with this.

    if (mediaStream.current) {
      setStatus('stopping');
      const tracks = mediaStream.current.getVideoTracks();
      tracks[0].stop();
    }

    // stop the current media recording. this will save the current video file as an mp4 by running the onRecordingStop event
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      setStatus('stopping');

      if (mediaRecorder.current.stop) {
        mediaRecorder.current.stop(blob => {
          handleGetBlob(blob);
        });
      } else if (mediaRecorder.current.stopRecording) {
        await mediaRecorder.current.stopRecording();

        handleGetBlob();
      }

      // this return lets the caller know that there was an active session that was being recorded. this is used when switching input devices mid-recording
      return {
        interruptedRecording: true,
      };
    }

    return {
      interruptedRecording: false,
    };
  }, []);

  const pauseRecording = useCallback(async () => {
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      setStatus('pausing');

      if (mediaRecorder.current.pauseRecording) {
        mediaRecorder.current.pauseRecording();
      }
    }
  }, []);

  const resumeRecording = useCallback(async () => {
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      setStatus('resuming');

      if (mediaRecorder.current.resumeRecording) {
        mediaRecorder.current.resumeRecording();
      }
    }
  }, []);

  const clearRecording = useCallback(() => {
    // stop a recording if one is currently running
    stopRecording();

    // remove media streams from state
    mediaRecorder.current = null;
    // mediaChunks.current = [];
    mediaStream.current = null;
    mediaBlobUrls.current = [];
    setMediaBlobUrl(null);

    // re-set the input streams
    getMediaStream();
  }, [stopRecording, getMediaStream]);

  // manually start a recording
  const startRecording = useCallback(
    async (vid, aud, mediaType) => {
      setError('NONE');
      if (!mediaStream.current) {
        await getMediaStream(vid, aud);
      }

      // once a media stream has been created, attach it to a Media Recorder to actually save the input stream
      if (mediaStream.current) {
        // set the constraints of the mediaStream
        const tracks = mediaStream.current.getVideoTracks();

        const settings = tracks[0].getCapabilities
          ? tracks[0].getCapabilities()
          : tracks[0].getSettings();

        tracks[0].applyConstraints({
          height: settings.height.max,
          width: settings.width.max,
        });

        if (DetectRTC.isVideoSupportsStreamCapturing) {
          mediaRecorder.current = await new RecordRTCPromisesHandler(mediaStream.current, {
            recorderType: mediaType === 'audio' ? StereoAudioRecorder : MediaStreamRecorder,
            disableLogs: true,
            type: mediaType,
            desiredSampRate: 44100,
            mimeType: mediaType === 'audio' ? 'audio/wav' : 'video/mp4',
          });

          await mediaRecorder.current.startRecording();
        } else {
          throw new Error(
            'Your browser/device does not support video recording. Please consider switching to Google Chrome.',
          );
        }

        setStatus('recording');
      }
    },
    [getMediaStream],
  );

  // runs when the user has changed input devices
  useEffect(() => {
    // if there is no input device, or if the device that is currently being streamed is the same as the new device, do nothing

    if (
      video &&
      showDevice.current &&
      showDevice.current.deviceId === video.deviceId &&
      audio &&
      soundDevice.current &&
      soundDevice.current.deviceId === audio.deviceId
    ) {
      return;
    }

    stopRecording();

    // switch media devices
    showDevice.current = video;
    soundDevice.current = audio;
    getMediaStream();
  }, [video, audio, getMediaStream, stopRecording]);

  // validate screen capturing devices/browsers. I didn't write/modify any of this code, it all came from the original react-media-recorder package
  useEffect(() => {
    if (showScreen.current) {
      if (!window.navigator.mediaDevices.getDisplayMedia) {
        throw new Error("This browser doesn't support screen capturing");
      }
    }

    const checkConstraints = mediaType => {
      if (!mediaType) {
        return null;
      }

      const supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();

      const unSupportedConstraints = Object.keys(mediaType).filter(
        constraint => !supportedMediaConstraints[constraint],
      );

      if (unSupportedConstraints.length > 0) {
        console.error(
          `The constraints ${unSupportedConstraints.join(
            ',',
          )} doesn't support on this browser. Please check your RTCRecorder component.`,
        );
      }
    };

    if (typeof audio === 'object') {
      checkConstraints(audio);
    }
    if (typeof showVideo.current === 'object') {
      checkConstraints(showVideo.current);
    }

    if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
      if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
        console.error(
          `The specified MIME type you supplied for MediaRecorder doesn't support this browser`,
        );
      }
    }
  }, [getMediaStream, mediaRecorderOptions, audio]);

  // when unmounting the recorder, remove any currently running streams or recordings. this will disable the cameras and turn off the LED indicator (if applicable)
  useEffect(() => {
    return () => {
      if (mediaStream.current) {
        const tracks = mediaStream.current.getVideoTracks();
        tracks[0].stop();
      }

      if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
        mediaRecorder.current.stopRecording();
      }
    };
  }, []);

  return render({
    error: RecorderErrors[error],
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    clearRecording,
    mediaBlobUrl,
    mediaBlobUrls: mediaBlobUrls.current,
    status,
    previewStream: mediaStream.current
      ? new MediaStream(mediaStream.current.getVideoTracks())
      : null,
  });
};

export default RTCRecorder;
