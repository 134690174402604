import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { Slider, Direction } from 'react-player-controls';
import moment from 'moment';
import { Parser } from 'm3u8-parser';
import DetectRTC from 'detectrtc';

import { formatTimeDisplay } from '../utils/helpers';

import MyContext from '../MyContext';
import Api from '../services/ApiHandler';

import TagUsers from '../components/modals/TagUsers';
import Button from '../components/Button';
import Timeline from '../components/Timeline';
import ReactionAnimationModal from '../components/modals/ReactionAnimationModal';
import VideoOptionsModal from '../components/modals/VideoOptionsModal';

import BackArrow from '../images/back-arrow.svg';

import { ReactComponent as ReplyIcon } from '../images/video-controls/video-camera.svg';
import { ReactComponent as PlayButton } from '../images/video-controls/play-button.svg';
import { ReactComponent as SmileReaction } from '../images/reactions/smile-reaction.svg';
import { ReactComponent as ElipsisReaction } from '../images/menu-icons/elipses.svg';
import { ReactComponent as Back15 } from '../images/video-controls/back-15.svg';
import { ReactComponent as Forward15 } from '../images/video-controls/forward-15.svg';

// reaction icons
import heartSvg from '../images/reactions/heart.svg';
import thumbsUpSvg from '../images/reactions/thumbs-up.svg';
import lolSvg from '../images/reactions/lol.svg';
import exclamationSvg from '../images/reactions/exclamation.svg';
import questionSvg from '../images/reactions/question.svg';

function Playback(props) {
  const [showElipsisModal, setShowElipsisModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [showReactionOptions, setShowReactionOptions] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [timeline, setTimeline] = useState([]);
  const [timelineCursor, setTimelineCursor] = useState(null);
  const [playingVideo, setPlayingVideo] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const playingVideoRef = useRef(null);
  const playbackComponentRef = React.useRef(null);
  const context = useContext(MyContext);
  const [newReactionType, setNewReactionType] = useState(null);
  const [videoReactionTypes, setVideoReactionTypes] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [timelineUpdateAvailable, setTimelineUpdateAvailable] = useState(false);

  // VIDEO SETTINGS DATA
  const [videoViews, setVideoViews] = useState([]);
  const [videoReactions, setVideoReactions] = useState([]);
  const [videoTags, setVideoTags] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);

  const { params } = props.match;
  const { state } = context;

  let parent;

  const topic = params.topicId && state.topics.find(topic => topic.id === params.topicId);
  const directMessage =
    params.directMessageId &&
    state.directMessages.find(dm => dm.directMessageId === params.directMessageId);
  parent = topic || directMessage || null;

  const backToGroups = useCallback(() => {
    context.clearConversationVideos();

    return props.history.push({
      pathname: `/group/${params.groupId}`,
      state: { view: directMessage ? 'DIRECT_MESSAGES' : 'TOPICS' },
    });
  });

  const changeVideo = useCallback(
    async video => {
      if (!video) return;

      const { groupId, topicId, directMessageId } = params;

      try {
        // if videoId isn't in the url yet, add it so that the url is sharable
        if (!params.videoId || params.videoId !== video.id) {
          if (directMessage) {
            props.history.push(
              `/group/${groupId}/directMessage/${directMessageId}/video/${video.id}`,
            );
          } else {
            props.history.push(`/group/${groupId}/topic/${topicId}/video/${video.id}`);
          }
        }

        const reactions = await context.getVideoReactions(video.id);
        const views = await context.getVideoViews(video.id);
        const tags = await context.getTaggedUsers(video.id);
        const groupUsers = await context.fetchGroupUsers(false, null, state.activeGroup.id);

        setVideoReactions(reactions);
        setVideoViews(views);
        setVideoTags(tags);
        setGroupMembers(groupUsers);

        // mark video as watched
        markVideoAsWatched(video);

        // animate reactions if there are any
        if (video.reactionCounts) {
          // see which types are represented in the reactions object and add them to the reactionTypes array
          let reactionTypes = [];
          for (let key in video.reactionCounts) {
            if (video.reactionCounts[key] > 0) {
              reactionTypes.push(key);
            }
          }

          // save that array on state to start the animation
          setVideoReactionTypes(reactionTypes);
        }

        // set the passed in video as the playing video
        setMaxTime(video.targetDuration);
        setPlayingVideo(video);
        setIsPlaying(true);
        playingVideoRef.current.playbackRate = playbackRate;
      } catch (e) {
        //
        console.log(`changeVideo: ${e}`);
      }
    },
    [setPlayingVideo, props.history, params],
  );

  // toggle the playback rate between normal and double speeds
  const changePlaybackRate = useCallback(() => {
    const PLAYBACK_RATES = [1, 1.5, 2];
    const index = PLAYBACK_RATES.findIndex(rate => rate === playbackRate);

    let newRate;

    if (index + 1 < PLAYBACK_RATES.length) {
      newRate = PLAYBACK_RATES[index + 1];
    } else {
      newRate = PLAYBACK_RATES[0];
    }

    playingVideoRef.current.playbackRate = newRate;
    setPlaybackRate(newRate);
  }, [playbackRate]);

  async function getTimelineVideos() {
    // make sure we have information about whether the current user is an admin of the group or not
    let activeGroup = {};

    if (context.state.activeGroup && context.state.activeGroup.isAdmin === undefined) {
      activeGroup = await context.fetchGroupDetails(context.state.activeGroup.id);
    }

    setIsAdmin(
      (context.state.activeGroup && context.state.activeGroup.isAdmin) ||
        (activeGroup && activeGroup.isAdmin),
    );

    const parentId = params.directMessageId || params.topicId;
    const { data } = await Api.conversationVideos(parentId);
    let videos = data.conversationVideos.items;

    // if we have a local video saved, add it into the timeline if appropriate
    const localVideo = context.state.localVideo;
    if (localVideo) {
      // this code determines whether or not we should show the local video in the timeline. if the videos topicId is the topicId we're currently viewing, we should include it in the timeline.

      // the old code for this was a single-line statement that always evaluated to `true` because a video (and our params object) only ever has a topicId OR a directMessageId, so one of the branches of the logic was always comparing `undefined` to `undefined`, which, in javascript land, is `true`. nice.

      /**
       * this was the old logic:
       *
       * const videoLivesHere =
       *   localVideo.topicId === params.topicId ||
       *   localVideo.directMessageId === params.directMessageId;
       */

      // bugs caused by this logic issue: 7
      // hours spent trying to fix it: ???
      let videoLivesHere = false;
      if (params.topicId) {
        // handle topics
        videoLivesHere = localVideo.topicId === params.topicId;
      } else {
        // handle direct messages
        videoLivesHere = localVideo.directMessageId === params.directMessageId;
      }

      const videoAlreadyPartOfTimeline = videos.find(video => video.id === localVideo.id);

      // if video should be part of timeline, and isn't yet, add it
      if (videoLivesHere && !videoAlreadyPartOfTimeline) {
        videos = [localVideo, ...videos];
      }
    }

    const promiseVideos = videos
      .filter(video => {
        if (video.streams && video.streams.length) {
          return video;
        }

        return false;
      })
      .map(async item => {
        // if the video is not local, fetch the m3u8 information to calculate it's duration
        if (item.streams) {
          return fetch(item.streams[0].url.url.replace('master.m3u8', 'low.m3u8'))
            .then(resp => resp.text())
            .then(resp => {
              const parser = new Parser();
              parser.push(resp);
              parser.end();

              item.targetDuration = parser.manifest.segments
                .map(segment => segment.duration)
                .reduce((total, segment) => {
                  return total + segment;
                });

              return item;
            }, 0);
        } else {
          const time = await getBlobDuration(item.localPlayUrl);

          item.targetDuration = time;

          // the video is local. just return the video.
          return Promise.resolve(item);
        }
      });

    const completedVideos = await Promise.all(promiseVideos);

    // once the video timeline has been generated, start playing the new video. because the current function only runs when no video is already playing, this will not interrupt a currently-playing video
    if (playingVideo && playingVideo.id === props.match.params.videoId) {
      changeVideo(completedVideos[0]);
    }

    setTimeline(completedVideos);
    setTimelineCursor(data.conversationVideos.cursor);
  }

  // if the topicId, directMessageId, activeTopic, or localVideo changes, get new timeline videos
  useEffect(() => {
    getTimelineVideos();
  }, [params.topicId, params.directMessageId, params.videoId]);

  // if the localVideo changes, or there is a new video from pubnub, the timeline has new videos available, so fetch them when possible
  useEffect(() => {
    if (playingVideoRef.current && playingVideoRef.current.paused) {
      // the video is not currently playing, so it's okay to update it now
      getTimelineVideos();
    } else {
      // the video is playing, so wait for it to end before updating
      // the video has an onEnded event that checks for the state of timelineUpdateAvailable, so this is just setting that to true so the video itself says when it's okay to update.
      setTimelineUpdateAvailable(true);
    }
  }, [context.state.localVideo, context.state.videos.length, context.state.activeTopic]);

  // if the timeline changes (such as when we get results from the api), figure out which video to play
  useEffect(() => {
    // if a videoId is passed in the params, play that video
    const videoInParams = timeline.find(video => video.id === params.videoId);

    // if no video in url params, default to newest video
    const initialVideo = videoInParams || timeline[0];

    changeVideo(initialVideo);
  }, [timeline]);

  // if the timeline or playingVideo changes, make sure the timeline shows the video being played as 'watched'
  useEffect(() => {
    if (!timeline || !playingVideo) return;

    timeline.forEach(video => {
      if (video.id === playingVideo.id) video.isWatched = true;
    });

    setTimeline(timeline);
  }, [timeline, playingVideo]);

  // marks the video as watched both locally in the timeline as well as via the api so the server knows
  function markVideoAsWatched(video) {
    if (!video) return;

    Api.watchVideo(video.id).catch(e => {
      // an error here shouldn't interrupt the user experience since it's non critical
      console.log(e);
    });
  }

  // attemps to load more videos using the timelineCursor
  async function loadMoreVideos() {
    // if no cursor, we cant get any more videos
    if (!timelineCursor) return;

    // get more videos
    const parentId = params.directMessageId || params.topicId;
    const { data } = await Api.conversationVideos(parentId, timelineCursor);
    let { items, cursor } = data.conversationVideos;

    const nonFlaggedItems = items.filter(vid => !state.flaggedVideos.includes(vid.id));

    const promiseVideos = nonFlaggedItems
      .filter(video => {
        if (video.streams && video.streams.length) {
          return video;
        }

        return false;
      })
      .map(item => {
        return fetch(item.streams[0].url.url.replace('master.m3u8', 'low.m3u8'))
          .then(resp => resp.text())
          .then(resp => {
            const parser = new Parser();
            parser.push(resp);
            parser.end();

            item.targetDuration = parser.manifest.segments
              .map(segment => segment.duration)
              .reduce((total, segment) => {
                return total + segment;
              });

            return item;
          }, 0);
      });

    const completedVideos = await Promise.all(promiseVideos);

    // append the new videos to our current timeline list
    const newTimeline = [...timeline, ...completedVideos];

    // save the new timeline and new cursor
    setTimeline(newTimeline);
    setTimelineCursor(cursor);
  }

  async function reactToVideo(reactionType) {
    if (!playingVideo) return;

    // close reaction options box
    setShowReactionOptions(false);

    // The selected reaction type should fall from the screen
    setNewReactionType(reactionType);

    // update the local timeline to show the most recent reaction on the video
    let updatedTimeline = timeline.map(video => {
      if (video.id === playingVideo.id) {
        video.mostRecentReaction = reactionType;
      }
      return video;
    });
    setTimeline(updatedTimeline);

    // let server know there was a reaction
    await Api.addReaction({
      videoId: playingVideo.id,
      reactionType,
      offset: currentTime,
    });
  }

  const changeTime = useCallback(
    (newTime, exact) => {
      if (playingVideoRef.current && maxTime !== Infinity && newTime !== null && playingVideo) {
        let newCurrentTime;
        if (exact) {
          newCurrentTime = newTime || playingVideo.targetDuration;
          setCurrentTime(Math.floor(newCurrentTime));
          playingVideoRef.current.currentTime = Math.floor(newCurrentTime);
        } else {
          newCurrentTime = maxTime * newTime || playingVideo.targetDuration;
          setCurrentTime(Math.floor(newCurrentTime));
          playingVideoRef.current.currentTime = Math.floor(newCurrentTime);
        }
      }
    },
    [currentTime, setCurrentTime],
  );

  const formatTimeRemaining = () => {
    if (!playingVideo || !playingVideo.targetDuration || playingVideo.targetDuration === Infinity) {
      return '00:00';
    }

    const timeRemaining = playingVideo.targetDuration
      ? Math.floor(playingVideo.targetDuration - currentTime)
      : Math.floor(maxTime - currentTime);

    return moment.utc(timeRemaining * 1000).format('mm:ss');
  };

  // this function was _gratefully_ taken from https://github.com/evictor/get-blob-duration/blob/master/src/getBlobDuration.js
  // all you need to know is that Chrome's implementation of MediaRecorder (what we use to record video files) doesn't save the duration of the video, so this function makes a temporary <video /> tag, adds our blob url as it's source, and then does some hacky voodoo to get the total duration of the video. we're then setting that timestamp to the video item in the timeline so it follows the pattern that we have with all of our other video sources.
  // it allows us to get the duration of a video recorded through the MediaRecorder.
  async function getBlobDuration(blob) {
    const tempVideoEl = document.createElement('video');

    const durationP = new Promise(resolve =>
      tempVideoEl.addEventListener('loadedmetadata', () => {
        // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
        if (tempVideoEl.duration === Infinity) {
          tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
          tempVideoEl.ontimeupdate = () => {
            tempVideoEl.ontimeupdate = null;
            resolve(tempVideoEl.duration);
            tempVideoEl.currentTime = 0;
          };
        }
        // Normal behavior
        else resolve(tempVideoEl.duration);
      }),
    );

    tempVideoEl.src =
      typeof blob === 'string' || blob instanceof String ? blob : window.URL.createObjectURL(blob);

    return durationP;
  }

  function handleVideoSkip(evnt, skipBy) {
    evnt.preventDefault(); // prevent any unintentional page refreshed
    evnt.stopPropagation(); // prevent the video from auto-playing after clicking one of the skip buttons

    // if no current video, don't attempt to skip
    if (!playingVideoRef.current) {
      return null;
    }

    let newTime = 0;

    // change currentTime of playing video
    if (skipBy >= 0) {
      // skip forward 15 seconds, stopping at the max time (end of the video)
      if (currentTime + skipBy <= maxTime) {
        newTime = currentTime + skipBy;
      } else {
        newTime = maxTime;
      }
    } else {
      // skip back 15 seconds, stopping at 0 seconds (start of video)
      if (currentTime - skipBy >= 0) {
        newTime = currentTime + skipBy;
      } else {
        newTime = 0;
      }
    }

    changeTime(newTime, true);
  }

  return (
    <div className="Playback" ref={playbackComponentRef}>
      {newReactionType && (
        <ReactionAnimationModal
          reactionTypes={[newReactionType]}
          totalParticleCount={40}
          closeModal={() => setNewReactionType(null)}
        />
      )}

      {videoReactionTypes && (
        <ReactionAnimationModal
          reactionTypes={videoReactionTypes}
          totalParticleCount={40}
          closeModal={() => setVideoReactionTypes(null)}
        />
      )}

      {playingVideo && (
        <TagUsers
          showModal={showTagModal}
          toggleModal={bool => setShowTagModal(bool)}
          groupId={params.groupId}
          directMessageId={params.directMessageId}
          videoId={playingVideo.id}
        />
      )}

      {showElipsisModal && playingVideo && (
        <VideoOptionsModal
          isOpen={showElipsisModal}
          closeModal={({ videoDeleted }) => {
            setShowElipsisModal(false);

            // if the modal was closed by archiving the video, remove video from timeline
            if (videoDeleted) {
              getTimelineVideos();
            }
          }}
          showInviteModal={context.setShowGroupInviteModal}
          videoReactions={videoReactions}
          videoViews={videoViews}
          videoTags={videoTags}
          groupMembers={groupMembers}
          inviteUrl={state.activeGroup.inviteLink.link}
          tagUsers={context.tagUsers}
          untagUsers={context.untagUsers}
          videoId={playingVideo.id}
          topics={state.topics}
          shareVideoToTopic={context.shareVideoToTopic}
          isShareable={playingVideo.shareable}
          archiveVideo={context.archiveVideo}
          deleteMessage={context.deleteMessage}
          isAdmin={
            !directMessage &&
            (state.activeGroup.isAdmin || playingVideo.author.id === state.userData.id)
          }
          directMessage={
            directMessage
              ? {
                  userId: state.userData.id,
                  directMessageId: directMessage.directMessageId,
                  message: directMessage.messages.find(
                    message => message.video.id === playingVideo.id,
                  ),
                }
              : undefined
          }
        />
      )}

      <div className="video-player">
        <video
          className="video"
          onTimeUpdate={() => setCurrentTime(playingVideoRef.current.currentTime)}
          onEnded={async () => {
            // if new videos are available in the timeline, load the new videos
            if (timelineUpdateAvailable) {
              setTimelineUpdateAvailable(false);
              await getTimelineVideos();
            }
            // get index of video currently playing
            const videoIndex = timeline.findIndex(vid => vid.id === playingVideo.id);
            // if there is a newer video, navigate to that video when this current video has ended
            if (videoIndex > 0) {
              changeVideo(timeline[videoIndex - 1]);
            }
          }}
          id="video-player"
          ref={playingVideoRef}
          autoPlay={true}
          controls={false}
          src={
            playingVideo
              ? playingVideo.isLocal
                ? playingVideo.localPlayUrl // play locally recorded video file (while upload is processing)
                : playingVideo.streams && playingVideo.streams.length
                ? DetectRTC.browser.name === 'Safari'
                  ? playingVideo.streams[0].url.url // play HLS on safari
                  : playingVideo.streams[1].url.url // play MP4 on everything else
                : null
              : null
          }
        />
      </div>
      <div
        className="video-controls-overlay"
        onClick={() => {
          if (playingVideoRef.current.paused) {
            playingVideoRef.current.play();
            setIsPlaying(true);
          } else {
            playingVideoRef.current.pause();
            setIsPlaying(false);
          }
        }}
        onDoubleClick={() => {
          if (playingVideoRef.current.requestFullscreen) {
            playingVideoRef.current.requestFullscreen();
          } else if (playingVideoRef.current.mozRequestFullScreen) {
            /* Firefox */
            playingVideoRef.current.mozRequestFullScreen();
          } else if (playingVideoRef.current.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            playingVideoRef.current.webkitRequestFullscreen();
          } else if (playingVideoRef.current.msRequestFullscreen) {
            /* IE/Edge */
            playingVideoRef.current.msRequestFullscreen();
          }
        }}
      >
        <div className="navigation-container" onClick={evnt => evnt.stopPropagation()}>
          <div className="back-button">
            <img src={BackArrow} alt="" onClick={backToGroups} />
            <p onClick={backToGroups}>Back to {directMessage ? 'Messages' : 'Group'}</p>
          </div>
          <div className="topic-info">
            {topic && (
              // DMs won't show an image here
              <img
                src={(parent && parent.publicImageUrl ? parent.publicImageUrl : '') || ''}
                alt=""
              />
            )}
            <p>{parent && (parent.displayName || parent.name || '')}</p>
          </div>
        </div>

        {playingVideo && playingVideo.textOnVideo && (
          <div className="text-on-video-overlay">
            <p className="overlay-text" style={{ color: playingVideo.textOnVideo.color }}>
              {playingVideo.textOnVideo.text}
            </p>
          </div>
        )}

        <div className={`center-play-icon ${!isPlaying ? 'show' : ''}`}>
          <Back15 onClick={evnt => handleVideoSkip(evnt, -15)} />
          <PlayButton className="play" />
          <Forward15 onClick={evnt => handleVideoSkip(evnt, 15)} />
        </div>

        <Timeline
          timeline={timeline}
          timelineCursor={timelineCursor}
          playingVideo={playingVideo}
          changeVideo={changeVideo}
          loadMoreVideos={loadMoreVideos}
        />

        <div className="controls" onClick={evnt => evnt.stopPropagation()}>
          <div className="meta">
            <div className="empty" />
            <div className="publisher">
              {playingVideo && playingVideo.author && (
                <React.Fragment>
                  <p className="author">
                    {playingVideo.author.firstName} {playingVideo.author.lastName}
                  </p>
                  <p className="timestamp">{formatTimeDisplay(playingVideo.publishedAt)}</p>
                </React.Fragment>
              )}
            </div>

            <div className="attachment">
              {playingVideo && playingVideo.attachments
                ? playingVideo.attachments
                    .filter(attachment => attachment.type === 'INTERNET_LINK')
                    .map((attachment, index) => (
                      <div className="attachment-url" key={index}>
                        <Button
                          className="attach-url-btn"
                          iconLeft={() => (
                            <img
                              className="attach-url-btn-icon"
                              src={`https://s2.googleusercontent.com/s2/favicons?domain=${attachment.value}`}
                              alt="attach url to video"
                            />
                          )}
                          label={attachment.value.slice(8, 18) + '...'}
                          action={() => {
                            playingVideoRef.current.pause();
                            window.open(attachment.value, '_blank');
                          }}
                        />
                      </div>
                    ))
                : null}
            </div>
          </div>

          <div className="controls-action-container">
            <div className="actions">
              <div className="action-item">
                <div
                  className="action-item-logo"
                  onClick={() => {
                    if (playingVideoRef.current) {
                      playingVideoRef.current.pause();
                    }

                    setShowElipsisModal(!showElipsisModal);
                  }}
                >
                  <ElipsisReaction
                    title="Options for this video"
                    className="action-item-logo-img"
                  />
                </div>
              </div>
              <div className="action-item">
                {showReactionOptions && (
                  <div className={`reaction-options`}>
                    <img
                      className={`reaction-option`}
                      onClick={() => reactToVideo('HEART')}
                      title="I love this video"
                      alt="heart"
                      src={heartSvg}
                    />
                    <img
                      className={`reaction-option`}
                      onClick={() => reactToVideo('THUMBS_UP')}
                      title="I like this video"
                      alt="thumbs up"
                      src={thumbsUpSvg}
                    />
                    <img
                      className={`reaction-option`}
                      onClick={() => reactToVideo('LOL')}
                      title="I laughed at this video"
                      alt="LOL"
                      src={lolSvg}
                    />
                    <img
                      className={`reaction-option`}
                      onClick={() => reactToVideo('EXCLAMATION')}
                      title="Wow!"
                      alt="exclamation point"
                      src={exclamationSvg}
                    />
                    <img
                      className={`reaction-option`}
                      onClick={() => reactToVideo('QUESTION')}
                      title="I have a question about something in this video"
                      alt="question mark"
                      src={questionSvg}
                    />
                  </div>
                )}
                <div className="action-item-logo">
                  <SmileReaction
                    title="Add a reaction"
                    className="action-item-logo-img"
                    onClick={() => setShowReactionOptions(!showReactionOptions)}
                  />
                </div>
              </div>
              <div className="action-item">
                <div
                  title="Change video playback rate"
                  className="action-item-logo"
                  onClick={changePlaybackRate}
                >
                  <div className="action-item-logo-text">x{playbackRate.toString()}</div>
                </div>
              </div>
            </div>
            <div className="progress-bar">
              <div className="video-control-slider">
                <Slider
                  className="Slider"
                  isEnabled
                  direction={Direction.HORIZONTAL}
                  onChange={newValue => changeTime(newValue)}
                  onChangeEnd={endValue => changeTime(endValue)}
                >
                  <div
                    className="slider-bar"
                    style={{ width: `calc(${(currentTime / maxTime) * 100}%)` }}
                  />
                </Slider>
              </div>
              <div className="video-control-time">{formatTimeRemaining()}</div>
            </div>
            <div className="record">
              <Button
                className="record-button"
                iconLeft={() => <ReplyIcon className="reply-icon" />}
                label=""
                action={() => {
                  // navigate to record screen
                  const { groupId, topicId, directMessageId } = params;
                  if (directMessageId) {
                    props.history.push(`/group/${groupId}/directMessage/${directMessageId}/record`);
                  } else {
                    props.history.push(`/group/${groupId}/topic/${topicId}/record`);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Playback;
