import PubNub from 'pubnub';
import each from 'lodash/each';

let pubNub;
let update;

const PubNubManager = {
  ACTIONS: {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
  },
  EVENT_TYPES: {
    DIRECT_MESSAGE_EVENT: 'DIRECT_MESSAGE_EVENT',
    VIDEO_EVENT: 'VIDEO_EVENT',
  },
  init(userId, updateGlobalStateFromPubNub) {
    console.log(`init pubnub: ${userId}`);
    // console.log(`sub key: ${process.env.REACT_APP_SUB_KEY}`);
    // console.log(`pub key: ${process.env.REACT_APP_PUB_KEY}`);
    update = updateGlobalStateFromPubNub;
    pubNub = new PubNub({
      subscribeKey: process.env.REACT_APP_SUB_KEY,
      publishKey: process.env.REACT_APP_PUB_KEY,
      uuid: userId,
    });

    pubNub.setFilterExpression(`users contains '${userId}'`);

    pubNub.subscribe({
      // go to PubNub debug and put in the random channel it creates and you can send messages to this
      channels: ['EVENTS'],
    });

    pubNub.addListener({
      status: statusEvent => {
        console.log(statusEvent);
      },
      message: msg => {
        const message = JSON.parse(msg.message);
        const eventType = message.eventType;
        console.log(`pubnub message event: ${eventType}, action: ${message.message.action}`);
        if (message.message.action === PubNubManager.ACTIONS.CREATE) {
          PubNubManager.handleCreateEvent(message.message, message.eventType);
        } else if (message.message.action === PubNubManager.ACTIONS.UPDATE) {
          PubNubManager.handleUpdateEvent(message.message, message.eventType);
        } else if (message.message.action === PubNubManager.ACTIONS.DELETE) {
          // console.log(`Delete Event: ${JSON.stringify(message.message)}`);
          PubNubManager.handleDeleteEvent(message.message, message.eventType);
        } else {
          // Do Nothing with any other event type
        }
      },
    });
  },
  handleCreateEvent(message, eventType) {
    // add to Global State
    // console.log(`Create Event: `);
    let streamDupe;
    if (message.video) {
      each(message.video.streams, stream => {
        streamDupe = {
          previewGifUrl: {
            url: `${process.env.REACT_APP_S3_URL}${stream.previewGifKey}`,
          },
          thumbnailUrl: {
            url: `${process.env.REACT_APP_S3_URL}${stream.thumbnailKey}`,
          },
          key: `${process.env.REACT_APP_S3_URL}${stream.key}`,
        };
        stream.previewGifUrl = {
          url: `${process.env.REACT_APP_S3_URL}${stream.previewGifKey}`,
        };
        stream.key = `${process.env.REACT_APP_S3_URL}${stream.key}`;
        stream.thumbnailUrl = {
          url: `${process.env.REACT_APP_S3_URL}${stream.thumbnailKey}`,
        };
      });
      message.video.streams.push(streamDupe);
      update(message, PubNubManager.ACTIONS.CREATE, eventType);
    }
  },
  handleUpdateEvent(message, eventType) {
    // update
    // FYI wont update the videos on this until we need it
    // console.log(`Update Event: ${JSON.stringify(message)}`);
  },
  handleDeleteEvent(message, eventType) {
    // delete
    // FYI we are not publishing a video delete event on the backend.. so for now
    // we can keep this empty until we feel like it is necessary to implement on the
    // backend
    // console.log(`Delete Event: ${JSON.stringify(message)}`);
  },
};

export default PubNubManager;
