import React, { useContext, useEffect, useState, useCallback } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';

import LocalStore from '../../services/LocalStorage';

import SmallModal from '../SmallModal';
import Button from '../Button';
import ToggleSwitch from '../ToggleSwitch';
import InputField from '../InputField';
import Api from '../../services/ApiHandler';
import MyContext from '../../MyContext';

const emailValidator = require('email-validator');
import getFormattedPlatformName from '../../utils/getFormattedPlatformName';

function NotificationSettings(props) {
  const context = useContext(MyContext);
  const [user, setUser] = useState({});
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [valid, setValid] = useState(false);
  const [awaitingValidation, setAwaitingValidation] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [settings, setSettings] = useState({
    phone: false,
    email: false,
  });
  const [oldTokens, setOldTokens] = useState({
    email: null,
    phone: null,
  });

  const [continueWithoutNotifications, setContinueWithoutNotifications] = useState(false);

  useEffect(() => {
    (async function getTokens() {
      const tokens = await Api.getDeviceTokens();
      const deviceTokens = tokens.data.me.deviceTokens;
      const phoneNumber = tokens.data.me.phoneNumber;
      const emailAddress = tokens.data.me.email;

      try {
        setEmail(
          emailAddress ? emailAddress : deviceTokens.find(p => p.tokenType === 'EMAIL').deviceToken,
        );
      } catch (e) {
        // console.log(e);
      }

      setPhone(phoneNumber);
      setSettings({
        email: deviceTokens.some(e => e.tokenType === 'EMAIL'),
        phone: deviceTokens.some(p => p.tokenType === 'SMS'),
      });

      setUser(tokens.data.me);

      setOldTokens({
        email: emailAddress,
        phone: phoneNumber,
      });
    })();
  }, []);

  // `override` will be true if the user has selected `no notifications` and has confirmed it on the second page.
  const submit = useCallback(
    async override => {
      if (!settings.email && !settings.phone && !override) {
        setContinueWithoutNotifications(true);
        return null;
      }

      if (!awaitingValidation) {
        let stopExecution = false;

        if (phone !== user.phoneNumber) {
          // only phone number has changed
          await Api.authenticate({
            phoneNumber: phone,
          });

          stopExecution = true;
        } else if (email !== user.email) {
          // only email has changed
          await Api.authenticateWithEmail({
            email: email,
            name: `${context.state.userData.firstName} ${context.state.userData.lastName}`,
          });

          stopExecution = true;
        }

        if (stopExecution) {
          setAwaitingValidation(true);

          return;
        }
      } else {
        // currently waiting for auth code. lets try to update the user
        const hasPhoneChanged = phone !== user.phoneNumber;
        const hasEmailChanged = email !== user.email;

        // user has changed their phone or email, but has not provided an auth code
        if (!authCode && (hasPhoneChanged || hasEmailChanged)) {
          return null;
        }

        // make sure the phone and email the user has provided are both valid
        if (
          (hasPhoneChanged && !isValidPhoneNumber(phone)) ||
          (hasEmailChanged && !emailValidator.validate(email))
        ) {
          alert(
            'You have entered an invalid email or phone number. Please make sure to enter correct data.',
          );
          return null;
        }

        // user has provided all of the necessary information. update the user object
        const { data, error } = await Api.updateUser({
          email: hasEmailChanged ? email : undefined,
          phoneNumber: hasPhoneChanged ? phone : undefined,
          authToken: hasEmailChanged || hasPhoneChanged ? authCode : undefined,
        });

        if (error) {
          alert(error.message);
        } else {
          context.setGlobalState('userData', data.updateUser);
          LocalStore.setItem('userData', data.updateUser);

          setAwaitingValidation(false);
          setAuthCode('');
          setUser({
            ...user,
            email,
            phoneNumber: phone,
          });
        }
      }

      // save settings
      if (phone && isValidPhoneNumber(phone)) {
        await Api.registerDeviceToken({
          userId: context.state.userData.id,
          token: phone,
          tokenType: 'SMS',
        });
      }

      if (email && emailValidator.validate(email)) {
        await Api.registerDeviceToken({
          userId: context.state.userData.id,
          token: email,
          tokenType: 'EMAIL',
        });
      }

      if (oldTokens.email && email !== oldTokens.email) {
        await Api.removeDeviceToken({
          token: email,
        });
      }

      if (oldTokens.phone && phone !== oldTokens.phone) {
        await Api.removeDeviceToken({
          token: phone,
        });
      }

      props.close();
    },
    [settings, email, phone, oldTokens, awaitingValidation, authCode, user, context, props],
  );

  useEffect(() => {
    let isValid = true;

    if (settings.email) {
      isValid = emailValidator.validate(email);
    }

    if (!settings.email && !settings.phone) {
      isValid = true;
    }

    setValid(isValid);
  }, [settings, email]);

  return (
    <SmallModal {...props}>
      <div className="NotificationSettings">
        {!continueWithoutNotifications && !awaitingValidation && (
          <React.Fragment>
            <h1>Notifications</h1>
            <p>Choose how you'd like to receive notifications:</p>

            <ToggleSwitch
              label="Text / SMS"
              action={e => {
                setSettings({ ...settings, phone: e.target.checked });
              }}
              checked={settings.phone}
            />

            <InputField
              defaultCountry="US"
              placeholder="Enter mobile number"
              className="auth-input-parent"
              label="Mobile phone number"
              value={phone}
              onChange={setPhone}
              type="phone"
            />

            <div className="v-spacer"></div>

            <ToggleSwitch
              label="Email"
              action={e => {
                setSettings({ ...settings, email: e.target.checked });
              }}
              checked={settings.email}
            />

            <InputField label="Email address" type="email" value={email} onChange={setEmail} />

            <div className="v-spacer"></div>
            <div className="button-container">
              <Button
                label="Submit"
                className="submit-button"
                action={() => submit(false)}
                disabled={!valid}
              />
            </div>
          </React.Fragment>
        )}

        {continueWithoutNotifications && !awaitingValidation && (
          <div className="confirm-no-notifs">
            <p className="title">{getFormattedPlatformName()} is a messaging application.</p>

            <p className="sub-title">
              Not selecting a notification preference means you'll miss important updates. Are you
              sure you don't want to receive notifications?
            </p>

            <div className="button-container">
              <Button
                label="Change Preferences"
                action={() => setContinueWithoutNotifications(false)}
              />
              <Button
                label="I don't want notifications"
                specificity="critical"
                action={() => submit(true)}
              />
            </div>
          </div>
        )}

        {awaitingValidation && (
          <div className="confirm-change-contact">
            <p className="sub-title">
              We have sent a verification code to the{' '}
              {phone !== user.phoneNumber ? 'phone number' : 'email address'} you updated.
            </p>
            <br />
            <p className="sub-title">
              Please enter that code below to confirm that you want to update your contact
              information.
            </p>

            <InputField label="Verification Code" value={authCode} onChange={setAuthCode} />

            <div className="button-container">
              <div className={`btn cancel`} onClick={() => setAwaitingValidation(false)}>
                <p>&#10005;</p>
                <p>Cancel</p>
              </div>

              <Button
                label="Confirm Update"
                action={() => {
                  submit();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </SmallModal>
  );
}

export default NotificationSettings;
