import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import DetectRTC from 'detectrtc';
import autosize from 'autosize';
import domtoimage from 'dom-to-image';

import LocalStorage from '../services/LocalStorage';
import generateUuid from '../utils/generateUuid';

import MyContext from '../MyContext';
import { ViewContext } from '../providers';

import RequestPermissions from '../components/modals/RequestPermissions';
import AttachUrl from '../components/modals/AttachUrl';

import SmallModal from '../components/SmallModal';
import Dropdown from '../components/Dropdown';
import AudioLevel from '../components/AudioLevel';
import RecordingTimeCounter from '../components/RecordingTimeCounter';
import Button from '../components/Button';
import ImagePreview from '../components/ImagePreview';

import RTCRecorder from '../components/RTCRecorder';
import { VideoPreview } from '../components/VideoPreview';

import { ReactComponent as Close } from '../images/buttons/close.svg';
import { ReactComponent as Arrow } from '../images/navigation-icons/up-arrow.svg';
import { ReactComponent as Chevron } from '../images/navigation-icons/up-chevron.svg';
import { ReactComponent as CheckBox } from '../images/check-box.svg';
import { ReactComponent as FileUpload } from '../images/file-upload.svg';
import { ReactComponent as LinkIcon } from '../images/link.svg';
import { ReactComponent as AudioMedia } from '../images/video-controls/audio-media.svg';
import { ReactComponent as VideoMedia } from '../images/video-controls/video-media.svg';
import { ReactComponent as TextMedia } from '../images/video-controls/text-media.svg';

const Record = props => {
  const OVERLAY_TEXT_LIMIT = 80;
  const UPLOAD_TEXT_LIMIT = 280;
  const CHARACTERS_PER_LINE = 30;
  const textColors = {
    white: '#ffffff',
    yellow: '#eff003',
    red: '#f60078',
    blue: '#00b0e3',
    green: '#7dd321',
  };

  const context = useContext(MyContext);
  const { setShowSidebar } = useContext(ViewContext);
  const { state } = context;

  const location = useLocation();

  const audioPreview = useRef(null);
  const audioPlayer = useRef(null);
  const textArea = useRef(null);
  const textOverlay = useRef(null);
  const fileUpload = useRef(null);

  const [showAudioDropdown, setShowAudioDropdown] = useState(false);
  const [showVideoDropdown, setShowVideoDropdown] = useState(false);
  const [recording, setRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const startRecordingCb = useRef(null);
  const stopRecordingCb = useRef(null);
  const pauseRecordingCb = useRef(null);
  const resumeRecordingCb = useRef(null);
  const clearRecordingCb = useRef(null);
  const [uploadVideoDimensions, setUploadVideoDimensions] = useState(null);
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
  const videoPreviewRef = useRef(null);
  const [recordingState, setRecordingState] = useState('');
  const [mediaType, setMediaType] = useState('video');

  const preselectedVideo = LocalStorage.getItem('videoCapture');
  const preselectedAudio = LocalStorage.getItem('audioCapture');

  const videoCapture = useRef(null);
  const audioCapture = useRef(null);
  const showVideoDevicesMap = useRef([]);
  const showAudioDevicesMap = useRef([]);

  const [isPublishing, setIsPublishing] = useState(false);
  const [permissionsModal, setPermissionsModal] = useState(false);
  const [showDeviceModal, setShowDeviceModal] = useState(false);
  const [showAttachUrlModal, setShowAttachUrlModal] = useState(false);
  const [attachUrl, setAttachUrl] = useState('');
  const [showOverlayTextInput, setShowOverlayTextInput] = useState(false);

  const [textColor, setTextColor] = useState('white');
  const [uploadText, setUploadText] = useState('');
  const [overlayText, setOverlayText] = useState('');

  let parent;
  const topic =
    props.match.params.topicId &&
    state.topics.find(topic => topic.id === props.match.params.topicId);
  const directMessage =
    props.match.params.directMessageId ||
    state.directMessages.find(dm => dm.directMessageId === props.match.params.directMessageId);
  parent = topic || directMessage || null;

  // after upload button is pressed, this is set to true so the user doesn't click multiple times and upload the same video a bunch
  let videoIsUploading = false;

  // when called, triggers a re-render. this is useful for refs, because they don't reset state.
  // this is only being used when the component mounts to handle setting default devices based on previously selected devices. IT SHOULD NOT BE USED ANYWHERE ELSE AS IT IS A HACKY WORKAROUND
  const [forceUpdateState, setForceUpdateState] = useState(false);
  const forceUpdate = useCallback(() => setForceUpdateState(!forceUpdateState), []);

  useEffect(() => {
    if (textArea.current) {
      autosize(textArea.current);
    }

    if (textOverlay.current) {
      autosize(textOverlay.current);
    }
  }, [textArea.current, textOverlay.current]);

  const updateDeviceList = useCallback(callback => {
    DetectRTC.load(async () => {
      const hasMic = DetectRTC.isWebsiteHasMicrophonePermissions;
      const hasCam = DetectRTC.isWebsiteHasWebcamPermissions;

      if (!hasMic || !hasCam) {
        return setPermissionsModal(true);
      }

      let videoInputDeviceArr = await navigator.mediaDevices.enumerateDevices();
      let audioInputDeviceArr = await navigator.mediaDevices.enumerateDevices();

      // if we map through the devices and find that the user's "selected" device is no longer available, we should clear their selected device, requiring them to select a new one.
      let resetVideoCapture = true;
      let resetAudioCapture = true;

      // only show options for video inputs
      videoInputDeviceArr = videoInputDeviceArr
        .filter(inputDevice => inputDevice.kind === 'videoinput')
        .map(inputDevice => {
          // on mobile devices, selectors are hidden and only default devices are enabled
          if (DetectRTC.isMobileDevice && inputDevice.deviceId === 'default') {
            return inputDevice;
          }

          let newInputDevice = {
            label: inputDevice.label,
            deviceId: inputDevice.deviceId,
          };

          // make the label of the button user-friendly
          newInputDevice.label = newInputDevice.label.replace(/\s*\([A-z0-9]*:[A-z0-9]*\)/gi, '');
          return newInputDevice;
        });

      // only show options for audio inputs
      audioInputDeviceArr = audioInputDeviceArr
        .filter(inputDevice => inputDevice.kind === 'audioinput')
        .map(inputDevice => {
          if (audioCapture.current && audioCapture.current.deviceId === inputDevice.deviceId) {
            resetAudioCapture = false;
          }

          // on mobile devices, selectors are hidden and only default devices are enabled
          if (DetectRTC.isMobileDevice && inputDevice.deviceId === 'default') {
            return inputDevice;
          }

          let newInputDevice = {
            label: inputDevice.label,
            deviceId: inputDevice.deviceId,
          };

          // make the label of the button user-friendly
          newInputDevice.label = newInputDevice.label.replace(/\s*\([A-z0-9]*:[A-z0-9]*\)/gi, '');
          return newInputDevice;
        });

      if (DetectRTC.isMobileDevice) {
        videoCapture.current = videoInputDeviceArr[0];
        audioCapture.current = audioInputDeviceArr[0];

        return;
      }

      // manually add an option for screen capturing to the video list. This will open a modal that gives the user the choice of what on their screen to share
      videoInputDeviceArr.push({
        deviceId: 'SCREEN_CAPTURE',
        label: 'Screen Capture',
      });

      // for each video input create a functional button
      videoInputDeviceArr = videoInputDeviceArr.map((inputDevice, ind) => {
        const isCurrent =
          videoCapture.current && videoCapture.current.deviceId === inputDevice.deviceId;

        if (videoCapture.current && videoCapture.current.deviceId === inputDevice.deviceId) {
          resetVideoCapture = false;
        }

        return (
          <li
            key={ind}
            className="dropdown-item"
            onClick={async () => {
              LocalStorage.setItem('videoCapture', inputDevice);
              videoCapture.current = inputDevice;
              setShowVideoDropdown(false);

              if (videoCapture.current && audioCapture.current) {
                setShowDeviceModal(false);
              }
            }}
          >
            {inputDevice.label} <CheckBox className={`icon ${isCurrent ? 'selected' : ''}`} />
          </li>
        );
      });

      // for each audio input create a functional button
      audioInputDeviceArr = audioInputDeviceArr.map((inputDevice, ind) => {
        const isCurrent =
          audioCapture.current && audioCapture.current.deviceId === inputDevice.deviceId;

        return (
          <li
            key={ind}
            className="dropdown-item"
            onClick={async () => {
              LocalStorage.setItem('audioCapture', inputDevice);
              audioCapture.current = inputDevice;
              setShowAudioDropdown(false);

              if (videoCapture.current && audioCapture.current) {
                setShowDeviceModal(false);
              }
            }}
          >
            {inputDevice.label} <CheckBox className={`icon ${isCurrent ? 'selected' : ''}`} />
          </li>
        );
      });

      showVideoDevicesMap.current = videoInputDeviceArr;
      showAudioDevicesMap.current = audioInputDeviceArr;

      if (resetAudioCapture) {
        audioCapture.current = null;
        forceUpdate();
      }

      if (resetVideoCapture) {
        videoCapture.current = null;
        forceUpdate();
      }

      if (callback) {
        callback();
      }
    });
  }, []);

  // on page load, check to see if there are preselected video or audio devices. if there are, load those
  useEffect(() => {
    // hide sidebar on mount
    setShowSidebar(false);

    if (preselectedVideo) {
      videoCapture.current = preselectedVideo;
    }

    if (preselectedAudio) {
      audioCapture.current = preselectedAudio;
    }

    // if either of the input values changed, force a state update to trigger a re-render so the changes visually appear to the user
    // doing this additional if statement prevents the force update from happening twice (if both devices are pre-selected) or once even if there are no pre-selected devices
    if (preselectedVideo || preselectedAudio) {
      forceUpdate();
    }

    // check url location state to see if we need to display any modals or alerts
    if (location.state) {
      if (location.state.upload) {
        // display file upload box
        if (fileUpload.current) {
          fileUpload.current.click();
        }
      }
    }

    return () => {
      // show sidebar on unmount
      setShowSidebar(true);
    };
  }, []);

  useEffect(() => {
    // add an event listener for when the users input devices change. use this to update the list of devices they have available. if they are currently recording, stop the recording.
    navigator.mediaDevices.ondevicechange = () => {
      if (recording) {
        setRecording(false);
        stopRecordingCb.current();
      }

      // get current state of audio and video dropdown so we can reset the state after the new lists have been rendered
      const audioDropdown = showAudioDropdown;
      const videoDropdown = showVideoDropdown;

      setShowAudioDropdown(false);
      setShowVideoDropdown(false);

      updateDeviceList(() => {
        setShowAudioDropdown(audioDropdown);
        setShowVideoDropdown(videoDropdown);
      });
    };

    // remove the event listener on unmount
    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }, [showAudioDropdown, showVideoDropdown, recording, stopRecordingCb]);

  // Map over devices, and render a list item for each one.
  // The list items that are created will switch the device that is streaming
  useEffect(() => {
    updateDeviceList();
  }, [audioCapture.current, videoCapture.current, permissionsModal]);

  // get the loaded video dimensions. used for file upload
  useEffect(() => {
    if (videoPreviewRef.current) {
      videoPreviewRef.current.onloadedmetadata = () => {
        if (setUploadVideoDimensions) {
          setUploadVideoDimensions({
            height: videoPreviewRef.current.videoHeight,
            width: videoPreviewRef.current.videoWidth,
          });
        }
      };
    }
  }, [videoPreviewRef.current, mediaBlobUrl]);

  // when recording starts or stops, hide all dropdowns
  useEffect(() => {
    setShowAudioDropdown(false);
    setShowVideoDropdown(false);
  }, [recording]);

  useEffect(() => {
    setRecordingState(''); // reset recording state

    if (mediaType === 'text') {
      setRecordingState(' upload');
      setUploadText('');
    }
  }, [mediaType]);

  const backToPlayback = useCallback(() => {
    return props.history.goBack();
  });

  function getAudioDuration() {
    return new Promise(resolve => {
      if (!audioPlayer.current) {
        throw new Error('Audio Player not found');
      }

      // if the current audio file does not have a useable duration, make a new player with a timestamp set very far forward to force getting the total duration.
      // https://stackoverflow.com/a/41245574/13201938
      if (audioPlayer.current.duration === Infinity) {
        const _player = new Audio(mediaBlobUrl.url);
        _player.addEventListener(
          'durationchange',
          function() {
            if (this.duration !== Infinity) {
              _player.remove();

              resolve(this.duration);
            }
          },
          false,
        );

        _player.load();
        _player.currentTime = 24 * 60 * 60;
        _player.volume = 0;
        _player.play();
      } else {
        resolve(audioPlayer.current.duration);
      }
    });
  }

  const updateTextArea = useCallback(evnt => {
    let newStr = evnt.target.value;

    // cut string off at UPLOAD_TEXT_LIMIT (currently 280)
    if (newStr.length >= UPLOAD_TEXT_LIMIT) {
      newStr = newStr.substr(0, UPLOAD_TEXT_LIMIT);
    }

    // remove any newline characters
    newStr = newStr.replace(/\n/g, '');

    newStr = newStr.split('');

    let lines = [];
    let line = '';
    let word = '';

    // map over every character in the string
    newStr.forEach(char => {
      if (char === ' ') {
        // previous word is ended at a space
        if (line.length + word.length < CHARACTERS_PER_LINE) {
          // if word fits on current line, add it and start new word
          line += word + char;
          word = '';
        } else {
          // if word makes line too long, add current line first, then add word to next line
          lines.push(line);

          word += char;
          line = '';
        }
      } else {
        // if not a space, current word is not done, so add character to it
        word += char;
      }
    });

    // if there is still a word left over, add it to the line
    if (word) {
      line += word;
    }

    // if there is still a line with content, add it to the final string
    if (line) {
      lines.push(line);
    }

    // remake completed string
    newStr = lines.join('\n').substr(0, UPLOAD_TEXT_LIMIT);

    // set state with new string
    setUploadText(newStr);
  }, []);

  async function handleRecordButton() {
    const { directMessageId, groupId, topicId } = props.match.params;

    // if the recording constraints are not met, instead of recording, prompt the user to select their input devices.
    // CONSTRAINTS:
    // VIDEO:
    //   The user must have audio AND video inputs
    //     OR
    //   The user must have the video input set to Screen Capture
    // AUDIO:
    //   The user must have an audio input
    if (mediaType === 'video') {
      if (
        (!audioCapture.current && !videoCapture.current) ||
        (!audioCapture.current &&
          videoCapture.current &&
          videoCapture.current.deviceId !== 'SCREEN_CAPTURE')
      ) {
        setShowDeviceModal(true);
        return null;
      }
    } else if (mediaType === 'audio') {
      if (!audioCapture.current) {
        setShowDeviceModal(true);
        return null;
      }
    } else if (mediaType === 'text') {
      // no text has been entered
      if (!uploadText.length) {
        setRecordingState(' upload');
        return;
      }

      setIsPublishing(true);

      let duration = 0;

      if (uploadText.length <= 100) {
        duration = 3;
      } else if (uploadText.length <= 200) {
        duration = 5;
      } else {
        duration = 7;
      }

      const pngHeight = 720 * 2;
      const pngWidth = 1280 * 2;

      let image = await domtoimage.toPng(textArea.current, {
        height: pngHeight,
        width: pngWidth,
        style: {
          fontSize: '72px',
          lineHeight: '1.3',
          paddingTop: `${150 + pngHeight / 3 - textArea.current.clientHeight / 2}px`,
          marginLeft: '-100px', // before marginLeft is set, the text is centered. because of the way mobile is showing videos, we want to offset the text to the left so that part of the text isn't hidden on mobile behind the timeline
        },
      });

      image = await fetch(image).then(res => res.blob());

      // upload text file
      const textFile = {
        image: image,
        duration,
        mediaType: 'TEXT_AS_VIDEO',
        uuid: generateUuid(),
        type: directMessage ? 'directMessage' : undefined,
        groupId: groupId,
        directMessageId: directMessage ? directMessageId : undefined,
        topicId: directMessage ? undefined : topicId,
        uploadDimensions: {
          height: pngHeight,
          width: pngWidth,
        },
        groupName: context.state.activeGroup.name || 'Group',
        topicName: (topic && topic.name) || 'Topic',
      };

      await context.addMediaToUploadQueue({ text: textFile });

      clearRecordingCb.current();
      setRecordingState(' upload');
      setMediaBlobUrl(null);
      setUploadVideoDimensions(null);
      setAttachUrl('');
      setIsPublishing(false);
      setRecording(false);

      // redirect to the appropriate dm/topic playback screen
      let redirectUrl = `/group/${groupId}/topic/${topicId}/video`;
      if (directMessageId) redirectUrl = `/group/${groupId}/directMessage/${directMessageId}/video`;

      return props.history.push({
        pathname: redirectUrl,
      });
    }

    // start upload process
    if (mediaBlobUrl) {
      // if we're already uploading, end here
      if (videoIsUploading) return;

      // set to true so user can't hit upload multiple times in a row for same video
      videoIsUploading = true;

      // set loading spinner for user feedback
      setIsPublishing(true);
      setRecordingState(' upload');

      if (mediaType === 'video') {
        let video;
        if (directMessage) {
          video = {
            mediaType: 'VIDEO',
            directMessageId: directMessageId,
            type: 'directMessage',
            groupId: groupId,
            groupName: state.activeGroup.name,
            data: mediaBlobUrl.blob,
            localPlayUrl: mediaBlobUrl.url,
            uploadDimensions: uploadVideoDimensions ? uploadVideoDimensions : mediaBlobUrl.size,
            textOnVideo: overlayText.length
              ? { text: overlayText, color: textColors[textColor] }
              : undefined,
          };
        } else {
          video = {
            mediaType: 'VIDEO',
            topicId: topicId,
            topicName: topic?.name,
            groupId: groupId,
            groupName: state.activeGroup.name,
            data: mediaBlobUrl.blob,
            localPlayUrl: mediaBlobUrl.url,
            uploadDimensions: uploadVideoDimensions ? uploadVideoDimensions : mediaBlobUrl.size,
            textOnVideo: overlayText.length
              ? { text: overlayText, color: textColors[textColor] }
              : undefined,
          };
        }

        if (attachUrl) {
          video.attachments = [
            {
              type: 'INTERNET_LINK',
              value: attachUrl,
            },
          ];
        }

        await context.addMediaToUploadQueue({ video });

        // redirect to the appropriate dm/topic playback screen
        let redirectUrl = `/group/${groupId}/topic/${topicId}/video`;
        if (directMessageId)
          redirectUrl = `/group/${groupId}/directMessage/${directMessageId}/video`;
        return props.history.push({
          pathname: redirectUrl,
        });
      } else if (mediaType === 'audio') {
        let audio;

        // these numbers just seemed to work. they make the audio image pretty similar to what mobile is outputting
        const pngHeight = 720 * 0.7;
        const pngWidth = 1280 * 0.7;

        // NOTE: the text_as_video feature uses domtoimage.toPng but I found that toJpeg worked significantly better for this one. toPng made the profile image very blurry, but this works very well.
        let image = await domtoimage.toJpeg(audioPreview.current, {
          height: pngHeight,
          width: pngWidth,
          style: {
            transform: 'scale(1.1)', // this removes the horizontal border on the image
            border: '3px solid black', // this removes the bottom border on the image
            margin: '-10px',
            marginTop: `-50px`, // places the image in the correct vertical location
          },
        });

        image = await fetch(image).then(res => res.blob());

        if (directMessage) {
          audio = {
            image: image,
            audio: mediaBlobUrl.blob,
            mediaType: 'AUDIO_AS_VIDEO',
            uuid: generateUuid(),
            type: 'directMessage',
            directMessageId,
            groupId,
          };
        } else {
          audio = {
            image: image,
            audio: mediaBlobUrl.blob,
            mediaType: 'AUDIO_AS_VIDEO',
            uuid: generateUuid(),
            groupId,
            topicId,
          };
        }

        audio.duration = await getAudioDuration();
        audio.groupName = context.state.activeGroup.name || 'Group';
        audio.topicName = (topic && topic.name) || 'Topic';
        audio.textOnVideo = overlayText.length
          ? { text: overlayText, color: textColors[textColor] }
          : undefined;

        await context.addMediaToUploadQueue({ audio });

        setRecordingState('');
        setMediaBlobUrl(null);
        setUploadVideoDimensions(null);
        setAttachUrl('');
        setIsPublishing(false);
        setRecording(false);

        // redirect to the appropriate dm/topic playback screen
        let redirectUrl = `/group/${groupId}/topic/${topicId}/video`;
        if (directMessageId)
          redirectUrl = `/group/${groupId}/directMessage/${directMessageId}/video`;

        return props.history.push({
          pathname: redirectUrl,
        });
      }
    } else if (recording) {
      // stop recording
      setRecording(false);
      await stopRecordingCb.current();
    } else {
      // start recording
      setRecording(true);
      startRecordingCb.current(videoCapture.current, audioCapture.current, mediaType);
    }
  }

  return (
    <div className="Record">
      <RequestPermissions
        isOpen={permissionsModal}
        close={async () => {
          const hasCam = DetectRTC.isWebsiteHasWebcamPermissions;

          if (!hasCam) {
            alert('If you want to change these settings, please visit your browser settings.');
          }

          setPermissionsModal(false);
          setRecordingState('');
          setMediaBlobUrl(null);
        }}
      />

      <SmallModal
        isOpen={showDeviceModal}
        close={() => {
          setShowDeviceModal(false);
          setRecordingState('');
          setMediaBlobUrl(null);
        }}
      >
        <div className="SelectInputDeviceModal">
          <h1 className="title">Please select your input devices</h1>

          <p className="sub-title">
            In order to record a video, you need to select which audio and video devices you want to
            use.
          </p>

          <div className="inputs">
            <Dropdown
              className="dropdown-action"
              showDropdown={showAudioDropdown}
              trigger={
                <div
                  className="container"
                  onClick={() => {
                    if (!recording) {
                      setShowAudioDropdown(!showAudioDropdown);
                      setShowVideoDropdown(false);
                    }
                  }}
                >
                  <p className="selector">
                    {audioCapture.current ? audioCapture.current.label : 'Select Audio'}
                  </p>
                  <Chevron />
                </div>
              }
            >
              <ul>{showAudioDevicesMap.current}</ul>
            </Dropdown>

            <Dropdown
              className="dropdown-action"
              showDropdown={showVideoDropdown}
              trigger={
                <div
                  className="container"
                  onClick={() => {
                    if (!recording) {
                      setShowAudioDropdown(false);
                      setShowVideoDropdown(!showVideoDropdown);
                    }
                  }}
                >
                  <p className="selector">
                    {videoCapture.current ? videoCapture.current.label : 'Select Video'}
                  </p>
                  <Chevron />
                </div>
              }
            >
              <ul>{showVideoDevicesMap.current}</ul>
            </Dropdown>
          </div>
        </div>
      </SmallModal>

      <AttachUrl
        isOpen={showAttachUrlModal}
        attachedUrl={attachUrl}
        close={url => {
          if (url) {
            setAttachUrl(url);
          }

          setShowAttachUrlModal(false);
        }}
      />

      <div className="navigation-container" onClick={evnt => evnt.stopPropagation()}>
        <div className="back-button">
          <Close onClick={backToPlayback} />
          <p onClick={backToPlayback}>Cancel</p>
        </div>

        <div className="topic-info">
          {mediaBlobUrl && !recording ? (
            <p className="preview">Preview</p>
          ) : (
            <p>{parent && (parent.displayName || parent.name || '')}</p>
          )}
        </div>

        <div className="actions">
          {!recording && !mediaBlobUrl && (mediaType === 'audio' || mediaType === 'video') ? (
            <div className="upload-file" title="Upload a file">
              <label htmlFor="upload-file-btn">
                <FileUpload />
              </label>
              <input
                ref={fileUpload}
                type="file"
                accept={mediaType === 'video' ? 'video/mp4' : 'audio/mp3'}
                id="upload-file-btn"
                multiple={false}
                onChange={evnt => {
                  const file = evnt.target.files[0];
                  const url = URL.createObjectURL(file);

                  setRecordingState(' upload');

                  setMediaBlobUrl({
                    url,
                    blob: file,
                  });
                }}
              />
            </div>
          ) : (
            <div className="upload-file" />
          )}

          {!recording && mediaBlobUrl && (
            <div className={`add-text${showOverlayTextInput ? ' active' : ''}`}>
              <TextMedia
                title="Overlay text on this video"
                onClick={() => {
                  setOverlayText('');
                  setTextColor('white');
                  setShowOverlayTextInput(!showOverlayTextInput);
                }}
              />
            </div>
          )}

          {!recording && mediaBlobUrl && (
            <div className="attach-url">
              <LinkIcon title="Add URL to this video" onClick={() => setShowAttachUrlModal(true)} />
            </div>
          )}

          {!recording && attachUrl && (
            <Button
              className="attach-url-btn"
              iconLeft={() => (
                <img
                  className="attach-url-btn-icon"
                  src={`https://s2.googleusercontent.com/s2/favicons?domain=${attachUrl}`}
                  alt="website favicon"
                />
              )}
              iconRight={() => (
                <Close
                  className="attach-url-btn-close"
                  onClick={evnt => {
                    evnt.preventDefault();
                    evnt.stopPropagation();

                    setAttachUrl('');
                  }}
                />
              )}
              label={attachUrl.slice(8, 18) + '...'}
              action={() => window.open(attachUrl, '_blank')}
            />
          )}
        </div>
      </div>

      <div className="video-player">
        {!videoCapture.current && !audioCapture.current && !mediaBlobUrl ? (
          <h1>Waiting for input device...</h1>
        ) : (
          <RTCRecorder
            video={videoCapture.current}
            audio={audioCapture.current}
            mediaType={mediaType}
            onStop={blob => {
              setMediaBlobUrl(blob);
            }}
            render={({
              startRecording,
              stopRecording,
              pauseRecording,
              resumeRecording,
              clearRecording,
              mediaBlobUrl: mediaStream,
              previewStream,
            }) => {
              // pass the recording functions up a level so the other buttons in this component can use them
              if (
                !startRecordingCb.current ||
                !stopRecordingCb.current ||
                !pauseRecordingCb.current ||
                !resumeRecordingCb.current
              ) {
                startRecordingCb.current = startRecording;
                stopRecordingCb.current = stopRecording;
                pauseRecordingCb.current = pauseRecording;
                resumeRecordingCb.current = resumeRecording;
              }

              if (!mediaBlobUrl && mediaStream) {
                setMediaBlobUrl(mediaStream);
              }

              if (!clearRecordingCb.current) {
                clearRecordingCb.current = clearRecording;
              }

              return (
                <div className="record-preview-container">
                  {mediaType === 'video' && (
                    <React.Fragment>
                      {!mediaBlobUrl ? (
                        <VideoPreview
                          videoCapture={videoCapture.current}
                          stream={previewStream}
                          src={mediaBlobUrl}
                        />
                      ) : (
                        <video
                          className="VideoPlayback"
                          ref={videoPreviewRef}
                          src={mediaBlobUrl.url}
                          autoPlay={true}
                        />
                      )}
                    </React.Fragment>
                  )}

                  {mediaType === 'audio' && (
                    <React.Fragment>
                      {mediaBlobUrl && <audio src={mediaBlobUrl.url} ref={audioPlayer} autoPlay />}

                      <div id="audio-preview" ref={audioPreview}>
                        <ImagePreview
                          url={state.userData.profileImageUrl}
                          alt="User Profile"
                          className="user-image"
                        />

                        <p className="user-name">
                          {state.userData.firstName} {state.userData.lastName}
                        </p>
                      </div>
                    </React.Fragment>
                  )}

                  {mediaType === 'text' && (
                    <React.Fragment>
                      <div
                        id="text-preview"
                        onClick={() => {
                          if (textArea.current) {
                            textArea.current.focus();
                          }
                        }}
                        className={`color-${textColor}`}
                      >
                        <textarea
                          ref={textArea}
                          className="upload-text"
                          value={uploadText}
                          placeholder="Enter Text Here"
                          onChange={updateTextArea}
                        />

                        {!uploadText && (
                          <p className="upload-text-max">({UPLOAD_TEXT_LIMIT} character max)</p>
                        )}
                      </div>
                    </React.Fragment>
                  )}

                  {showOverlayTextInput && (
                    <React.Fragment>
                      <div className="add-text-overlay">
                        <textarea
                          ref={textOverlay}
                          className="input-text"
                          placeholder="Enter Text Here"
                          style={{ color: textColors[textColor] }}
                          value={overlayText}
                          onChange={evnt => {
                            let str = evnt.target.value;

                            if (!textOverlay.current) {
                              textOverlay.current = evnt.target;
                            }

                            if (str.length > OVERLAY_TEXT_LIMIT) {
                              str = str.substr(0, OVERLAY_TEXT_LIMIT);
                            }

                            setOverlayText(str);
                          }}
                        />
                        {!overlayText.length && (
                          <p className="max-chars">({OVERLAY_TEXT_LIMIT} characters max)</p>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </div>
              );
            }}
          />
        )}
      </div>

      <div className="controls" onClick={evnt => evnt.stopPropagation()}>
        <div className="input-selectors">
          {!mediaBlobUrl && mediaType !== 'text' && (
            <div className="levels">
              <AudioLevel audioDevice={audioCapture.current} />
            </div>
          )}

          {!recording && (!mediaBlobUrl || showOverlayTextInput) && (
            <React.Fragment>
              {(mediaType === 'video' || mediaType === 'audio') && !mediaBlobUrl && (
                <div
                  className={`audio container ${recording ? 'recording' : ''}`}
                  onClick={() => {
                    if (!recording) {
                      setShowAudioDropdown(!showAudioDropdown);
                      setShowVideoDropdown(false);
                    }
                  }}
                >
                  <p>Audio:</p>
                  <Dropdown
                    showDropdown={showAudioDropdown}
                    label={'Audio:'}
                    trigger={
                      <div className="container">
                        <p className="selector">
                          {audioCapture.current ? audioCapture.current.label : 'Select Audio'}
                        </p>
                        <Chevron />
                      </div>
                    }
                  >
                    <ul>{showAudioDevicesMap.current}</ul>
                  </Dropdown>
                </div>
              )}

              {mediaType === 'video' && !mediaBlobUrl && (
                <div
                  className={`video container ${recording ? 'recording' : ''}`}
                  onClick={() => {
                    if (!recording) {
                      setShowAudioDropdown(false);
                      setShowVideoDropdown(!showVideoDropdown);
                    }
                  }}
                >
                  <p>Video:</p>
                  <Dropdown
                    showDropdown={showVideoDropdown}
                    trigger={
                      <div className="container">
                        <p className="selector">
                          {videoCapture.current ? videoCapture.current.label : 'Select Video'}
                        </p>
                        <Chevron />
                      </div>
                    }
                  >
                    <ul>{showVideoDevicesMap.current}</ul>
                  </Dropdown>
                </div>
              )}

              {(mediaType === 'text' || showOverlayTextInput) && (
                <div className="text container">
                  {Object.entries(textColors).map((color, index) => (
                    <div
                      key={index}
                      className={`color-selector ${color[0]} ${
                        textColor === color[0] ? 'active' : ''
                      }`}
                      onClick={() => setTextColor(color[0])}
                    >
                      <div className="icon" />
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          )}
        </div>

        <div className="meta">
          {recording && <RecordingTimeCounter isRecording={recording} isPaused={paused} />}
        </div>

        <div className="record">
          {mediaBlobUrl && (
            <Button
              className="discard-button"
              label="Discard"
              action={async () => {
                clearRecordingCb.current();
                setRecordingState('');
                setMediaBlobUrl(null);
                setUploadVideoDimensions(null);
                setAttachUrl('');
              }}
            />
          )}

          {recording && (
            <Button
              className="pause-button"
              label={paused ? 'Resume' : 'Pause'}
              action={async () => {
                if (paused) {
                  setPaused(false);
                  resumeRecordingCb.current();
                } else {
                  setPaused(true);
                  pauseRecordingCb.current();
                }
              }}
            />
          )}

          {!recording && !mediaBlobUrl && (
            <div className="media-types-container">
              <AudioMedia
                className={`media-type${mediaType === 'audio' ? ' active' : ''}`}
                onClick={() => setMediaType('audio')}
              />
              <VideoMedia
                className={`media-type${mediaType === 'video' ? ' active' : ''}`}
                onClick={() => setMediaType('video')}
              />
              <TextMedia
                className={`media-type${mediaType === 'text' ? ' active' : ''}`}
                onClick={() => setMediaType('text')}
              />
            </div>
          )}

          <div
            className={`record-button${recordingState}${isPublishing ? ' loading' : ''}`}
            onClick={() => {
              const newState =
                recordingState === '' ? ' stop' : recordingState === ' stop' ? ' upload' : '';

              setRecordingState(newState);

              handleRecordButton();
            }}
          >
            <div className="inner-shape">
              {isPublishing ? <div className="loader" /> : <Arrow className="upload-arrow" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Record;
