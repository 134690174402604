import React, { useContext, useEffect, useCallback } from 'react';

import MyContext from '../MyContext';
import { ViewContext } from '../providers';

import Api from '../services/ApiHandler';
import LocalStorage from '../services/LocalStorage';
import UserHelper from '../services/UserHelpers';
import EdConnectLogo from '../images/branding/edconnect-logo.png';
import SayLogo from '../images/branding/say-logo.png';

import getFormattedPlatformName from '../utils/getFormattedPlatformName';

function PreAuthLanding(props) {
  const context = useContext(MyContext);
  const { showSidebar, setShowSidebar } = useContext(ViewContext);

  const { params } = props.match;
  const processJWT = useCallback(
    async jwt => {
      // set the JWT locally
      let JWT;
      let ok = false;
      if (jwt) {
        JWT = JSON.parse(jwt);
        await LocalStorage.setItem('JWT', JWT.token);
        // get user data
        const { data, error } = await Api.me();
        if (error) {
          console.log(error);
        } else {
          await LocalStorage.setItem('userData', data.me);
          context.setGlobalState('userData', data.me);
          context.setGlobalState('userName', UserHelper.getUserName(data.me));
          ok = true;
          try {
            context.setGlobalState('profileImage', data.me.profileImageUrl.url);
          } catch (e) {
            console.log(e);
            ok = true;
          }
        }
        return ok;
      }
    },
    [params],
  );

  useEffect(() => {
    (async function() {
      // check the JWT coming in
      const { jwt } = params;
      if (await processJWT(jwt)) {
        context.toggleLoggedIn();
        setShowSidebar(!showSidebar);
        await context.fetchSidebarGroups();

        const firstGroup = context.state.groups ? context.state.groups[0] : null;
        await context.setActiveGroup(firstGroup);
        const { videoId, groupId, topicId } = params;
        console.log(`videoId: ${videoId}, groupId: ${groupId}, topicId: ${topicId}`);
        let gotoUrl = `/group`;
        if (groupId) {
          gotoUrl += `/${groupId}`;
        }

        if (groupId && topicId) {
          gotoUrl += `/topic/${topicId}`;
        }

        if (groupId && topicId && videoId) {
          gotoUrl += `/video/${videoId}`;
        }
        console.log(gotoUrl);
        setTimeout(() => {
          window.location.href = gotoUrl;
        }, 1000);
      } else {
        // redirect to login
        props.history.push('/');
      }
    })();
  }, []);

  return (
    <div className="pre-auth-landing">
      <div className="container branding">
        <div className="background-tilted" />
        <div className="content">
          {process.env.REACT_APP_PLATFORM === 'say' ? (
            <img src={SayLogo} alt="Say | Virtual Classroom" className="logo" />
          ) : (
            <img src={EdConnectLogo} alt="EdConnect | Virtual Classroom" className="logo" />
          )}
          <div className="separator" />
          <p className="copy">
            Stay connected and keep the conversation real with {getFormattedPlatformName()}.
          </p>
        </div>
      </div>
      <div className="message-container">
        <p className="message">
          Welcome to {getFormattedPlatformName()}.
          <br />
          <br />
          Please wait while we get you all setup!
        </p>
      </div>
    </div>
  );
}

export default PreAuthLanding;
