import moment from 'moment';

function formatTimeDisplay(utcTimestamp) {
  const utcDateObj = moment.utc(utcTimestamp).toDate();
  // const localTime = moment(utcDateObj).local().format('YYYY-MM-DD HH:mm:ss');
  const localTime = moment(utcDateObj)
    .local()
    .format('MMM D, h:mm a');
  return localTime;
}

function formatHowLongAgo(utcTimestamp) {
  const yearsAgo = moment().diff(utcTimestamp, 'years');
  if (yearsAgo > 0) return formatHowLongAgoDisplay(yearsAgo, 'year');

  const monthsAgo = moment().diff(utcTimestamp, 'months');
  if (monthsAgo > 0) return formatHowLongAgoDisplay(monthsAgo, 'month');

  const daysAgo = moment().diff(utcTimestamp, 'days');
  if (daysAgo > 0) return formatHowLongAgoDisplay(daysAgo, 'day');

  const hoursAgo = moment().diff(utcTimestamp, 'hours');
  if (hoursAgo > 0) return formatHowLongAgoDisplay(hoursAgo, 'hour');

  const minutsAgo = moment().diff(utcTimestamp, 'minutes');
  if (minutsAgo > 0) return formatHowLongAgoDisplay(minutsAgo, 'minute');

  const secondsAgo = moment().diff(utcTimestamp, 'seconds');
  if (secondsAgo > 0) return formatHowLongAgoDisplay(secondsAgo, 'second');

  return 'now';
}

// used by the howLongAgo function above. Determines if there should be an 's' on the end of
// the time measurement i.e. 1 year ago -vs- 2 years ago
function formatHowLongAgoDisplay(count, timeMeasurement) {
  if (count !== 1) timeMeasurement += 's';
  return `${count} ${timeMeasurement} ago`;
}

export { formatTimeDisplay, formatHowLongAgo };
