import gql from 'graphql-tag';

const Mutations = {
  AUTHENTICATE: gql`
    mutation authenticate($input: AuthenticateInput!) {
      authenticate(input: $input)
    }
  `,
  AUTHENTICATE_WITH_EMAIL: gql`
    mutation authenticateWithEmail($input: AuthenticateWithEmailInput!) {
      authenticateWithEmail(input: $input)
    }
  `,
  LOGIN: gql`
    mutation webLogin($input: WebLoginInput!) {
      webLogin(input: $input) {
        token
        user {
          id
          firstName
          lastName
          email
          profileImageUrl {
            url
          }
          settings {
            usersVideosCanBeShared
          }
        }
      }
    }
  `,
  FLAG_VIDEO: gql`
    mutation flagVideo($input: FlagVideoInput!) {
      flagVideo(input: $input)
    }
  `,
  UNFLAG_VIDEO: gql`
    mutation unflagVideo($input: UnflagVideoInput!) {
      unflagVideo(input: $input)
    }
  `,
  JOIN_GROUP: gql`
    mutation joinGroup($id: String!) {
      joinGroup(id: $id)
    }
  `,
  CREATE_CONVERSATION: gql`
    mutation createConvo($input: ConversationInput!) {
      createConversation(conversation: $input) {
        conversation {
          id
          name
          isPinned
          lastVideoDate
          publicImageUrl
          previewVideos {
            id
            isWatched
            publishedAt
            streams {
              id
              type
              previewGifUrl {
                url
              }
              thumbnailUrl {
                url
              }
            }
          }

          creator {
            id
            lastName
            firstName
          }
        }
        publicImageUploadUrl {
          uploadUrl {
            url
          }
          downloadUrl
        }
      }
    }
  `,
  UPDATE_CONVERSATION: gql`
    mutation updateConversation($input: UpdateConversationInput!) {
      updateConversation(input: $input) {
        id
        name
        publicImageUrl
        settings {
          onlyAdminCanPostVideos
          adminMustApproveNewVideos
        }
        creator {
          id
          firstName
          profileImageUrl {
            url
          }
        }
      }
    }
  `,
  ARCHIVE_CONVERSATION: gql`
    mutation archiveConversation($id: String!) {
      archiveDynamicConversation(id: $id)
    }
  `,
  CREATE_GROUP: gql`
    mutation createGroup($input: CreateGroupInput!) {
      createGroup(group: $input) {
        group {
          id
          name
        }
        inviteLink {
          link
        }
        publicImageUploadUrl {
          uploadUrl {
            url
          }
          downloadUrl
        }
      }
    }
  `,
  ADD_USER_TO_GROUP: gql`
    mutation addGroupUser($input: AddGroupUserInput!) {
      addGroupUser(input: $input)
    }
  `,
  BLOCK_USER_FROM_GROUP: gql`
    mutation blockUser($input: BlockUserFromGroupInput!) {
      blockUserFromGroup(input: $input)
    }
  `,
  ADD_GROUP_ADMINS: gql`
    mutation addGroupAdmins($input: AddGroupAdminsInput!) {
      addGroupAdmins(input: $input)
    }
  `,
  REMOVE_USER_FROM_GROUP: gql`
    mutation removeUserFromGroup($input: RemoveGroupUserInput!) {
      removeGroupUser(input: $input)
    }
  `,
  REMOVE_ADMIN_USERS: gql`
    mutation removeAdminUser($input: RemoveGroupAdminsInput!) {
      removeGroupAdmins(input: $input)
    }
  `,
  ADD_REACTION: gql`
    mutation addReaction($input: AddReactionInput!) {
      addReaction(input: $input)
    }
  `,
  REMOVE_REACTION: gql`
    mutation removeReaction($input: RemoveReactionInput!) {
      removeReaction(input: $input)
    }
  `,
  MAKE_PENDING_VIDEO_ACTION: gql`
    mutation pendingVideoAction($vidAction: PendingVideoAction!, $vidId: String!) {
      makePendingVideoAction(action: $vidAction, id: $vidId)
    }
  `,
  ARCHIVE_VIDEO: gql`
    mutation archiveVideo($videoId: String!) {
      archiveVideo(id: $videoId)
    }
  `,
  DELETE_MESSAGE: gql`
    mutation deleteMessage($input: DeleteMessageInput!) {
      deleteMessage(input: $input)
    }
  `,
  UPDATE_USER: gql`
    mutation updateUser($input: UpdateUserInput!) {
      updateUser(input: $input) {
        id
        firstName
        lastName
        email
        profileImageUrl {
          url
        }
        settings {
          usersVideosCanBeShared
        }
      }
    }
  `,
  UPDATE_GROUP: gql`
    mutation updateGroup($input: UpdateGroupInput!) {
      updateGroup(group: $input) {
        group {
          id
          name
          description
          publicImageUrl
        }
      }
    }
  `,
  SET_USER_GROUP_SETTINGS: gql`
    mutation groupSettings($input: UserGroupSettingsInput!) {
      setUserGroupSettings(input: $input) {
        notificationsForTagged
        notificationsForAllVideos
        notificationsForReactions
        notificationsForHostVideos
      }
    }
  `,
  SET_ADMIN_GROUP_SETTINGS: gql`
    mutation setAdminSettings($input: GroupSettingsInput!) {
      setGroupSettings(input: $input) {
        adminMustApproveGroupMembers
        onlyAdminCanCreateConversations
      }
    }
  `,
  SET_CONVERSATION_SETTINGS: gql`
    mutation setConversationSettings($input: ConversationSettingsInput!) {
      setConversationSettings(input: $input) {
        adminMustApproveNewVideos
        onlyAdminCanPostVideos
      }
    }
  `,
  SET_MY_USER_SETTINGS: gql`
    mutation setMyUserSettings($input: SetMyUserSettingsInput!) {
      setMyUserSettings(input: $input) {
        usersVideosCanBeShared
      }
    }
  `,
  APPROVE_PENDING_GROUP_MEMBER: gql`
    mutation approvePendingMember($input: ApprovePendingGroupMembershipInput!) {
      approvePendingGroupMembership(input: $input)
    }
  `,
  DENY_PENDING_GROUP_MEMBER: gql`
    mutation denyPendingMember($input: DenyPendingGroupMembershipInput!) {
      denyPendingGroupMembership(input: $input)
    }
  `,
  CREATE_VIDEO_PROMISE: gql`
    mutation createVideoPromise($input: VideoPromiseInput!) {
      createVideoPromise(input: $input) {
        id
        uuid
        upload {
          url
          expires
        }
      }
    }
  `,
  TAG_USERS_IN_VIDEO: gql`
    mutation tagUsersInVideo($input: TagUsersInVideoInput!) {
      tagUsersInVideo(input: $input)
    }
  `,
  UNTAG_USERS_IN_VIDEO: gql`
    mutation removeTaggedUsersFromVideo($input: RemoveTaggedUsersFromVideoInput!) {
      removeTaggedUsersFromVideo(input: $input)
    }
  `,
  CREATE_DIRECT_MESSAGE: gql`
    mutation createDirectMessage($input: DirectMessageInput!) {
      createDirectMessage(input: $input) {
        directMessageId
        groupId
        userIds
      }
    }
  `,
  CREATE_MESSAGE: gql`
    mutation createMessage($input: MessageInput!) {
      createMessage(input: $input)
    }
  `,
  HIDE_DIRECT_MESSAGE: gql`
    mutation hideDirectMessage($input: HideDirectMessageInput!) {
      hideDirectMessage(input: $input)
    }
  `,
  SET_LAST_VISITED_FOR_GROUP: gql`
    mutation setLastVisited($groupId: String!) {
      setLastVisitedForGroup(groupId: $groupId)
    }
  `,
  WATCH_VIDEO: gql`
    mutation watchVideo($id: String!) {
      watchVideo(id: $id)
    }
  `,
  REGISTER_DEVICE_TOKEN: gql`
    mutation registerDeviceToken($input: RegisterDeviceTokenInput!) {
      registerDeviceToken(input: $input)
    }
  `,
  REMOVE_DEVICE_TOKEN: gql`
    mutation removeDeviceToken($input: RemoveDeviceTokenInput!) {
      removeDeviceToken(input: $input)
    }
  `,
  PIN_TOPIC: gql`
    mutation pinConversationSingle($input: PinConversationSingleInput!) {
      pinConversationSingle(input: $input) {
        id
        isPinned
      }
    }
  `,
  UNPIN_TOPIC: gql`
    mutation unpinConversation($id: String!) {
      unpinConversation(id: $id) {
        id
        isPinned
      }
    }
  `,
  CREATE_LTI_USER: gql`
    mutation createUsers($input: CreateUserInput!) {
      createUser(input: $input) {
        id
        firstName
        lastName
        email
        phoneNumber
      }
    }
  `,
  LTI_LOGIN: gql`
    mutation ltiLogin($input: LtiLoginInput!) {
      ltiLogin(input: $input) {
        expiration
        token
        user {
          id
          firstName
          lastName
          email
          profileImageUrl {
            url
          }
          settings {
            usersVideosCanBeShared
          }
        }
      }
    }
  `,
  SHARE_VIDEO_TO_CONVERSATION: gql`
    mutation shareVideoToConversation($input: ShareVideoToConversationInput!) {
      shareVideoToConversation(input: $input) {
        id
      }
    }
  `,
};

export default Mutations;
