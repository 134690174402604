import gql from 'graphql-tag';

const Queries = {
  GET_GROUP: gql`
    query getGroup($input: GroupInput!) {
      getGroup(input: $input) {
        id
        name
        description
        publicImageUrl
        vanityUrl
        vanityPath
        inviteLink {
          link
        }
        createdAt
        lastVideoDate
        lastVisitedAt
        creatorId
        settings {
          adminMustApproveGroupMembers
          onlyAdminCanCreateConversations
        }
        canCreateConversations
        isAdmin
        adminUsers {
          id
          firstName
          lastName
          email
          profileImageUrl {
            url
          }
        }
        isMember
        membershipStatus
        userSettings {
          notificationsForTagged
          notificationsForAllVideos
          notificationsForReactions
          notificationsForHostVideos
        }
        isDefaultGroup
      }
    }
  `,
  GET_GROUP_USERS: gql`
    query groupUsers($includeAdmins: Boolean, $cursor: String, $id: String!) {
      groupUsers(includeAdmins: $includeAdmins, cursor: $cursor, id: $id) {
        items {
          id
          firstName
          lastName
          profileImageUrl {
            url
          }
        }
        admins {
          id
          firstName
          lastName
          profileImageUrl {
            url
          }
        }
      }
    }
  `,
  GET_PENDING_GROUP_MEMBERS: gql`
    query getPendingGroupMembers($cursor: String, $groupId: String!) {
      pendingGroupMembers(cursor: $cursor, id: $groupId) {
        items {
          id
          firstName
          lastName
          profileImageUrl {
            url
          }
        }
      }
    }
  `,
  GET_DISC_GROUPS: gql`
    query discoverableGroups {
      discoverableGroups {
        id
        name
        description
        publicImageUrl
        vanityPath
        membershipStatus
      }
    }
  `,
  GET_GROUP_BY_VANITY_PATH: gql`
    query groupByVanityPath($vanityPath: String!) {
      groupByVanityPath(vanityPath: $vanityPath) {
        id
        name
        description
        publicImageUrl
        membershipStatus
        inviteLink {
          link
        }
      }
    }
  `,
  GET_GROUP_CONVERSATIONS: gql`
    query groupConversations($id: String!, $cursor: String) {
      groupConversations(cursor: $cursor, id: $id) {
        cursor
        items {
          id
          name
          isPinned
          lastVideoDate
          publicImageUrl
          previewVideos {
            id
            isWatched
            publishedAt
            streams {
              id
              type
              previewGifUrl {
                url
              }
              thumbnailUrl {
                url
              }
            }
          }
        }
      }
    }
  `,
  GET_MY_GROUPS_LIST: gql`
    query groupList {
      groupList {
        items {
          id
          name
          publicImageUrl
          hasNewContent
          isPending
        }
      }
    }
  `,
  GET_CONVERSATION_DETAILS: gql`
    query ConversationDetails($id: String!) {
      conversation(id: $id) {
        id
        name
        isPinned
        publicImageUrl
        settings {
          onlyAdminCanPostVideos
          adminMustApproveNewVideos
        }
        creator {
          id
          firstName
          profileImageUrl {
            url
          }
        }
        isGroupAdmin
        recentVideos {
          id
          isWatched
          streams {
            id
            type
            thumbnailUrl {
              url
            }
            url {
              url
            }
          }
        }
      }
    }
  `,
  ME: gql`
    query me {
      me {
        id
        firstName
        lastName
        email
        phoneNumber
        profileImageUrl {
          url
        }
        deviceTokens {
          tokenType
          deviceToken
        }
        settings {
          usersVideosCanBeShared
        }
      }
    }
  `,
  PUBLIC_IMAGE_URL: gql`
    query {
      publicImageUrl {
        uploadUrl {
          url
          expires
        }
        downloadUrl
        key
      }
    }
  `,
  CONVERSATION_FOLDERS: gql`
    query conversationFolders($parentId: String!) {
      conversationFolders(parentId: $parentId) {
        items {
          id
          name
          isPinned
          lastVideoDate
          publicImageUrl
          previewVideos {
            id
            isWatched
            publishedAt
            streams {
              id
              type
              previewGifUrl {
                url
              }
              thumbnailUrl {
                url
              }
            }
          }
        }
      }
    }
  `,
  CONVERSATION_VIDEOS: gql`
    query conversationVideos($id: String!, $cursor: String) {
      conversationVideos(id: $id, cursor: $cursor) {
        cursor
        items {
          id
          isWatched
          author {
            firstName
            lastName
            id
          }
          shareable
          textOnVideo {
            text
            color
          }
          publishedAt
          mostRecentReaction
          reactionCounts {
            QUESTION
            THUMBS_UP
            HEART
            EXCLAMATION
            LOL
          }
          thumbnailUrl {
            url
          }
          mediaType
          streams {
            id
            url {
              url
            }
            previewGifUrl {
              url
            }
            type
            format
          }
          attachments {
            type
            value
          }
        }
      }
    }
  `,
  GET_DIRECT_MESSAGES: gql`
    query myDirectMessages($groupId: String!, $cursor: String) {
      myDirectMessages(groupId: $groupId, cursor: $cursor) {
        cursor
        directMessages {
          directMessageId
          createdAt
          lastUpdatedAt
          groupId
          userId
          members {
            id
            firstName
            lastName
            profileImageUrl {
              url
            }
          }
          previewVideos {
            id
            isWatched
            streams {
              previewGifUrl {
                url
              }
              thumbnailUrl {
                url
              }
            }
          }
          recentVideos {
            id
            isWatched
            author {
              firstName
              lastName
            }
            thumbnailUrl {
              url
            }
            streams {
              id
              url {
                url
              }
              type
              format
            }
          }
          messages {
            messageId
            createdAt
            message
            authorId
            video {
              id
              isWatched
              streams {
                id
                key
                createdAt
                type
                thumbnailUrl {
                  url
                }
                previewGifUrl {
                  url
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_VIDEO_DETAILS: gql`
    query video($id: String!) {
      video(id: $id) {
        id
        isWatched
        thumbnailUrl {
          url
        }
        shareable
        textOnVideo {
          text
          color
        }
        mostRecentReaction
        reactionCounts {
          LOL
          EXCLAMATION
          QUESTION
          HEART
          THUMBS_UP
        }
        mediaType
        streams {
          id
          url {
            url
          }
          previewGifUrl {
            url
          }
        }
      }
    }
  `,
  DEVICE_TOKENS: gql`
    query me {
      me {
        id
        email
        phoneNumber
        deviceTokens {
          tokenType
          deviceToken
        }
      }
    }
  `,
  GROUP_FLAGGED_VIDEOS: gql`
    query groupFlaggedVideos($id: String!) {
      groupFlaggedVideos(id: $id) {
        items {
          id
        }
      }
    }
  `,
  GET_NOTIFICATIONS: gql`
    query {
      notifications {
        items {
          initiatingUserId
          eventId
          eventType
          title
          text
          createdAt
          groupId
          conversationId
          directMessageId
          initiatingVideoId
          initiatingUserProfileUrl
        }
      }
    }
  `,
  GET_VIDEO_REACTIONS: gql`
    query getVideoReactions($id: String!) {
      getVideoReactions(id: $id) {
        items {
          reactionType
          user {
            firstName
            lastName
          }
        }
      }
    }
  `,
  GET_VIDEO_VIEWS: gql`
    query getVideoViews($id: String!) {
      getVideoViews(id: $id) {
        items {
          user {
            firstName
            lastName
            profileImageUrl {
              url
            }
          }
        }
      }
    }
  `,
  GET_TAGGED_USERS: gql`
    query taggedUsersForVideo($id: String!) {
      taggedUsersForVideo(id: $id) {
        items {
          id
          firstName
          lastName
          profileImageUrl {
            url
          }
        }
      }
    }
  `,
};

export default Queries;
