import React, { useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Api from '../../services/ApiHandler';
import { S3Upload } from '../../services/UploaderService';

import { ViewContext, ConversationContext } from '../../providers';

import SmallModal from '../SmallModal';
import InputField from '../InputField';
import FileUpload from '../FileUpload';
import Button from '../Button';

import { ReactComponent as AddVideo } from '../../images/add-content/add-video.svg';
import { ReactComponent as AddFolder } from '../../images/add-content/add-folder.svg';
import { ReactComponent as AddPerson } from '../../images/add-content/add-person.svg';
import { ReactComponent as UploadVideo } from '../../images/add-content/upload-video.svg';

function AddContent({ close, inviteUrl, groupId }) {
  const history = useHistory();

  const { breadcrumbPath } = useContext(ViewContext);
  const { addNewConversation } = useContext(ConversationContext);

  const [currentView, setCurrentView] = useState('add content');
  const [file, setFile] = useState(null);
  const [folderName, setFolderName] = useState('');

  const handleCreateFolder = useCallback(async () => {
    const { data } = await Api.createConversation({
      name: folderName.trim(),
      groupId: groupId,
    });

    const { uploadUrl, downloadUrl } = data.createConversation.publicImageUploadUrl;

    if (file) {
      await S3Upload(file, uploadUrl.url);

      await Api.updateConversation({
        id: data.createConversation.conversation.id,
        name: folderName.trim(),
        publicImageUrl: downloadUrl || null,
      });
    }

    addNewConversation(data.createConversation.conversation);
    close();
  }, [file, folderName]);

  return (
    <SmallModal isOpen={true} close={close} shouldOverlayClose={true}>
      <div className="AddContent">
        <div className="header">
          {currentView !== 'add content' && (
            <button className="back" onClick={() => setCurrentView('add content')}>
              &#x3c; Back
            </button>
          )}

          <h1 className="title">
            {currentView
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
          </h1>
        </div>

        {/* INITIAL OPTIONS */}
        <ul className={`options ${currentView === 'add content' ? 'show' : 'hide'}`}>
          {breadcrumbPath.length > 1 && (
            <li
              className="option"
              onClick={() => {
                const path = (breadcrumbPath[breadcrumbPath.length - 1].path + '/record').replace(
                  '/conversation/',
                  '/topic/',
                );

                history.push(path);
              }}
            >
              <div className="meta">
                <AddVideo className="icon" />
                <p className="label">Add Video</p>
              </div>
            </li>
          )}

          <li className="option" onClick={() => setCurrentView('add folder')}>
            <div className="meta">
              <AddFolder className="icon" />
              <p className="label">Add Folder</p>
            </div>
          </li>

          {breadcrumbPath.length > 1 && (
            <li
              className="option"
              onClick={() => {
                const path = (breadcrumbPath[breadcrumbPath.length - 1].path + '/record').replace(
                  '/conversation/',
                  '/topic/',
                );

                history.push(path, {
                  upload: true,
                });
              }}
            >
              <div className="meta">
                <UploadVideo className="icon" />
                <p className="label">Upload Video</p>
              </div>
            </li>
          )}

          {inviteUrl && (
            <li className="option" onClick={() => setCurrentView('add person')}>
              <div className="meta">
                <AddPerson className="icon" />
                <p className="label">Add Person</p>
              </div>
            </li>
          )}
        </ul>

        {/* ADD FOLDER */}
        <ul className={`options ${currentView === 'add folder' ? 'show' : 'hide right'}`}>
          <FileUpload rounded onChange={setFile} />
          <InputField label="Folder Name" value={folderName} onChange={setFolderName} />
          <Button className="add-folder-btn" label="Create Folder" action={handleCreateFolder} />
        </ul>

        {/* ADD PERSON */}
        <div className={`view ${currentView === 'add person' ? 'show' : 'hide'}`}>
          <ul className="options">
            <li className="option noclick">
              <div className="meta column">
                <p className="description">
                  Copy the following URL and share it with anyone you want to invite to this group.
                </p>
                <InputField className="invite-url" value={inviteUrl} copiable />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </SmallModal>
  );
}

export default AddContent;
