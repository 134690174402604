import React from 'react';
import MyContext from '../../MyContext';
import PropTypes from 'prop-types';
import SmallModal from '../SmallModal';
import Api from '../../services/ApiHandler';
import Button from '../Button';
import ToggleSwitch from '../ToggleSwitch';
import FileUpload from '../FileUpload';
import InputField from '../InputField';
import { S3Upload } from '../../services/UploaderService';

const ADMIN_TOPIC_SETTINGS = {
  adminMustApproveNewVideos: 'Admins must approve new videos',
  onlyAdminCanPostVideos: 'Turn off replies',
};

class TopicSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topicId: props.topicId,
      publicImage: null,
      name: '',
      isPinned: false,
      settings: {
        onlyAdminCanPostVideos: false,
        adminMustApproveNewVideos: false,
      },
      creator: {},
      loading: false,
      deleteLoading: false,
      file: null,
      isAdmin: false,
      showDeleteConfirmation: false,
    };

    this.deleteTopic = this.deleteTopic.bind(this);
    this.updateTopic = this.updateTopic.bind(this);
  }

  async componentDidMount() {
    await this.getTopicDetails(this.state.topicId);
  }

  setTheState(key, val) {
    this.setState({
      [key]: val,
    });
  }

  async getTopicDetails(id) {
    const { data, error } = await Api.getConversationDetails(id, null);

    if (error) {
      alert(error.message);
    } else {
      this.setState({
        name: data.conversation.name,
        publicImage: data.conversation.publicImageUrl,
        settings: data.conversation.settings,
        creator: data.conversation.creator,
        isAdmin: data.conversation.isGroupAdmin,
        isPinned: data.conversation.isPinned,
      });
    }
  }

  async updateTopic() {
    this.setState({
      loading: true,
    });

    let imageUrl;
    if (this.state.file) {
      const profilePictureResponse = await Api.publicImageUrl();
      const uploadUrl = profilePictureResponse.data.publicImageUrl.uploadUrl.url;
      await S3Upload(this.state.file, uploadUrl);
      imageUrl = profilePictureResponse.data.publicImageUrl.downloadUrl;
    }

    const { data, error } = await Api.updateConversation({
      id: this.state.topicId,
      name: this.state.name,
      publicImageUrl: imageUrl ? imageUrl : this.state.publicImage,
    });

    const { err } = await Api.setConversationSettings({
      id: this.state.topicId,
      onlyAdminCanPostVideos: this.state.settings.onlyAdminCanPostVideos,
      adminMustApproveNewVideos: this.state.settings.adminMustApproveNewVideos,
    });

    if (error || err) {
      alert(error.message || err.message);
    } else {
      this.setState({
        name: data.updateConversation.name,
      });

      await this.context.fetchTopics(this.context.state.activeGroup.id);
    }

    this.setState({
      loading: false,
    });

    this.props.toggleModal();
  }

  async deleteTopic() {
    this.setState({
      deleteLoading: true,
    });

    const { error } = await Api.archiveConversation(this.state.topicId);

    if (error) {
      alert(`We couldn't archive this topic try again later: ${error.message}`);
    } else {
      try {
        await this.context.fetchTopics(this.context.state.activeGroup.id);
      } catch (e) {
        console.error(e);
      }
      this.props.toggleModal();
    }
  }

  async toggleTopicPinnedStatus() {
    this.setState({ loading: true });

    if (this.state.isPinned) {
      await Api.unpinTopic(this.state.topicId);
    } else {
      await Api.pinTopic(this.state.topicId);
    }

    await this.context.fetchTopics(this.context.state.activeGroup.id);
    this.props.toggleModal();
  }

  render() {
    const { onlyAdminCanPostVideos, adminMustApproveNewVideos } = this.state.settings;

    let disabled = true;
    const isAdmin = this.state.isAdmin;

    // current user did not create the topic
    if (this.context.state.userData.id === this.state.creator.id) {
      disabled = false;
    }

    // current user is not an admin
    if (isAdmin) {
      disabled = false;
    }

    return (
      <SmallModal
        isOpen={this.props.showModal}
        close={this.props.toggleModal}
        shouldOverlayClose={true}
      >
        <div className="TopicSettingsModal">
          <h1 className="title">Topic Settings</h1>

          <FileUpload
            originalImg={this.state.publicImage ? this.state.publicImage : ''}
            onChange={img => this.setTheState('file', img)}
            disabled={disabled}
          />

          <InputField
            label="Topic Name"
            value={this.state.name}
            disabled={disabled}
            onChange={val => this.setTheState('name', val)}
            placeholder="Team Project - Term 3"
          />

          {this.state.isAdmin && (
            <div className="pin-topic-section">
              <hr />
              <Button
                label={this.state.isPinned ? 'Unpin Topic' : 'Pin Topic'}
                action={() => this.toggleTopicPinnedStatus()}
                loading={this.state.loading}
              />
            </div>
          )}

          <hr />

          <div className="topic-creator">
            <img
              className="topic-creator-image"
              src={this.state.creator.profileImageUrl ? this.state.creator.profileImageUrl.url : ''}
              alt=""
            />
            <div className="topic-creator-sub-container">
              <label className="topic-creator-name">{this.state.creator.firstName}</label>
              <label className="topic-creator-title">Topic Creator</label>
            </div>
          </div>

          <hr />

          {isAdmin ? (
            <div className="toggles">
              <ToggleSwitch
                label={ADMIN_TOPIC_SETTINGS.onlyAdminCanPostVideos}
                checked={onlyAdminCanPostVideos}
                disabled={disabled}
                action={evnt => {
                  this.setState({
                    settings: {
                      ...this.state.settings,
                      onlyAdminCanPostVideos: evnt.target.checked,
                    },
                  });
                }}
              />

              <ToggleSwitch
                label={ADMIN_TOPIC_SETTINGS.adminMustApproveNewVideos}
                checked={adminMustApproveNewVideos}
                disabled={disabled}
                action={evnt => {
                  this.setState({
                    settings: {
                      ...this.state.settings,
                      adminMustApproveNewVideos: evnt.target.checked,
                    },
                  });
                }}
              />
              <hr />
            </div>
          ) : (
            <div className="toggles" />
          )}

          <div className="button-container">
            {this.state.showDeleteConfirmation && (
              <div className="action-confirmation">
                <p>Delete this topic?</p>
                <div className="button-wrapper">
                  <Button
                    label="no"
                    action={() => this.setState({ showDeleteConfirmation: false })}
                    loading={this.state.deleteLoading}
                  />
                  <Button
                    label="yes"
                    action={this.deleteTopic}
                    loading={this.state.deleteLoading}
                  />
                </div>
              </div>
            )}

            {this.state.showUpdateConfirmation && (
              <div className="action-confirmation">
                <p>Save settings?</p>
                <div className="button-wrapper">
                  <Button
                    label="no"
                    action={() => this.setState({ showUpdateConfirmation: false })}
                    loading={this.state.loading}
                  />
                  <Button label="yes" action={this.updateTopic} loading={this.state.loading} />
                </div>
              </div>
            )}

            {isAdmin ? (
              <div
                className="btn delete-topic"
                onClick={() => this.setState({ showDeleteConfirmation: true })}
              >
                <p>&#10005;</p>
                <p>Delete Topic</p>
              </div>
            ) : (
              ''
            )}

            {!disabled ? (
              <Button
                label="Update"
                disabled={!this.state.name}
                action={() => this.setState({ showUpdateConfirmation: true })}
                className="btn update-topic"
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </SmallModal>
    );
  }
}

TopicSettings.contextType = MyContext;

TopicSettings.propTypes = {
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  topicId: PropTypes.string.isRequired,
};

export default TopicSettings;
