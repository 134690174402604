const S3Upload = async (file, signedRequest) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          console.log(`UploaderService: status: ${xhr.status}`);
          console.log(`UploaderService: xhr: ${JSON.stringify(xhr)}`);
          resolve(xhr.response);
        } else {
          console.error('UploaderService: could not open file for upload');
          reject(`UploaderService: Error Uploading File: ${xhr.error}`);
        }
      }
    };
    xhr.send(file);
  });
};

const TranscoderUpload = async (media, url) => {
  const formData = new FormData();

  Object.entries(media).forEach(mediaObject => {
    formData.append(mediaObject[0], mediaObject[1]);
  });

  return await fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    body: formData,
  })
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.error(err.message);
    });
};

export { S3Upload, TranscoderUpload };
