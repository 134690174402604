/**
 * @param {string} props.children -> The content displayed (and centered) within the modal. This is passed in like the normal `children` prop.
 * @param {boolean} props.shouldCloseOnEsc -> If true, the user can press the `escape` key to close the modal. defaults true
 * @param {boolean} props.shouldCloseOnOverlayClick -> If true, the user can click outside of the modal to close it. defaults true
 * @param {boolean} props.showModal -> if true, the modal is open. otherwise the modal is closed.
 * @param {function} props.toggleModal -> open or close the modal
 * @param {string} props.className -> Add additional (and overwriting) styles to the modal
 *
 * @description This is the modal component. It builds upon the `react-modal` component brought in via NPM. By default, it centers the children elements with css flexbox.
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import CloseButton from '../images/buttons/close.png';

function Modal({
  children,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  showModal,
  toggleModal,
  className,
}) {
  return (
    <ReactModal
      className={`Modal ${className}`}
      overlayClassName="Overlay"
      isOpen={showModal}
      onRequestClose={() => toggleModal(false)}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <img
        onClick={() => toggleModal(false)}
        className="modal-close-btn"
        src={CloseButton}
        alt="close modal"
      />
      <div className="modal-content">{children}</div>
    </ReactModal>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf([PropTypes.node]), PropTypes.node]).isRequired,
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  className: PropTypes.string,
};

Modal.defaultProps = {
  shouldCloseOnEsc: true,
  shouldCloseOnOverlayClick: true,
};

export default Modal;
