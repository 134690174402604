/*
    This component displays the video timeline, as well as handling the scroll logic for deciding when to 
    get more videos for the timeline. However, the timeline array saved in state, as well as the actual 
    logic for making the server calls lives in the parent component. This component just recieves that info
    in the props and displays it

    Props:
    - timeline: this list of videos on parent state that we should display (NOTE: if we get topics with thousands
      of videos, we can start virtualizing what is actually rendered on the screen (react-window or 
      react-virtualized are good options, or we could build our own solution for that if needed)
    - timelineCursor: the cursor for more timeline videos saved on parent state. If we have a cursor, that means
      there are more video to load, and so we display a loading icon at the top of the timeline so the user knows 
      what's happening
    - playingVideo: the video object saved on parent state of the video that is currently playing. We use this for
      formatting (the playing video has a white border in the timeline)
    - changeVideo: this is a function that lives in the parent component that updates which video is actually playing
    - loadMoreVideos: a function in the parent component that loads more timeline videos. This component tells the parent
      when it's time  to fire that off (based on when the user scrolls to the top of the timeline)
*/

import React, { useRef } from 'react';
import LoadingSpinner from '../images/spinner.gif';

// reaction icons
import heartRedSvg from '../images/reactions/heart-red.svg';
import thumbsUpBlueSvg from '../images/reactions/thumbs-up-blue.svg';
import lolOrangeSvg from '../images/reactions/lol-orange.svg';
import exclamationPurpleSvg from '../images/reactions/exclamation-purple.svg';
import questionGreenSvg from '../images/reactions/question-green.svg';

function Timeline({ timeline, timelineCursor, playingVideo, changeVideo, loadMoreVideos }) {
  const lastVideoRef = useRef(null);

  function handleTimelineScroll(e) {
    // videos are displayed backwards, so check if the bottom of the last video is below the top of the screen
    const lastVideoShowing = lastVideoRef.current.getBoundingClientRect().bottom >= 0;

    if (lastVideoShowing) {
      loadMoreVideos();
    }
  }

  function buildMostRecentReactionForVideo(video) {
    if (!video || !video.mostRecentReaction) return null;

    const reactionImageSrcMap = {
      HEART: heartRedSvg,
      THUMBS_UP: thumbsUpBlueSvg,
      LOL: lolOrangeSvg,
      EXCLAMATION: exclamationPurpleSvg,
      QUESTION: questionGreenSvg,
    };

    const reactionImageSrc = reactionImageSrcMap[video.mostRecentReaction];
    return (
      <img className={`most-recent-reaction`} alt="most recent reaction" src={reactionImageSrc} />
    );
  }

  const timelineMap = timeline.map((video, index) => {
    let thumbnailUrl = '';
    if (video && video.thumbnailUrl && video.thumbnailUrl.url) {
      thumbnailUrl = video.thumbnailUrl.url;
    }

    // last video gets a ref so we can tell when it scrolls into view (to load more videos)
    const isLastVideo = index === timeline.length - 1;

    // build most recent reaction image
    const mostRecentReactionImage = buildMostRecentReactionForVideo(video);

    return (
      <div
        className={`video-item
          ${
            playingVideo && video.id === playingVideo.id
              ? 'playing'
              : video.isWatched
              ? ''
              : 'blue-border'
          }
        `}
        ref={isLastVideo ? lastVideoRef : null}
        key={index}
        onClick={() => changeVideo(video)}
      >
        {mostRecentReactionImage}
        <img src={thumbnailUrl} alt="user uploaded video" />
      </div>
    );
  });

  // loading icon at the top of the timeline so user knows more videos are loading if they scroll up there
  const loadingMoreVideosIcon = (
    <div className="video-item">
      <img src={LoadingSpinner} alt="loading" />
    </div>
  );

  return (
    <div
      className="timeline-container"
      onClick={evnt => evnt.stopPropagation()}
      onScroll={handleTimelineScroll}
    >
      {timelineMap}
      {/* only display the icon if we have a cursor */}
      {timelineCursor ? loadingMoreVideosIcon : null}
    </div>
  );
}

export default Timeline;
