/**
 * API for Local Storage to use throughout the App
 * @type {{getItem: Api.getItem, setItem: Api.setItem}}
 */
const Api = {
  setItem: (key, value) => {
    if (process.env.REACT_APP_PLATFORM === 'say') {
      localStorage.setItem(`say:${key}`, JSON.stringify(value));
    } else {
      localStorage.setItem(`edConnect:${key}`, JSON.stringify(value));
    }
  },
  getItem: key => {
    let item;

    if (process.env.REACT_APP_PLATFORM === 'say') {
      item = localStorage.getItem(`say:${key}`);
    } else {
      item = localStorage.getItem(`edConnect:${key}`);
    }

    let results = null;
    try {
      results = JSON.parse(item);
    } catch (e) {
      console.log(e);
    }
    return results;
  },
  removeItem: key => {
    if (process.env.REACT_APP_PLATFORM === 'say') {
      return localStorage.removeItem(`say:${key}`);
    } else {
      return localStorage.removeItem(`edConnect:${key}`);
    }
  },
};

export default Api;
