/**
 * @param {string|boolean} props.error -> If present, there was an error with the data the user put into the field. Show proper aria/visual states. if a string is passed in, the string will display instead of the default error message
 * @param {string} props.label -> The text that shows up in the top-left part of the input field
 * @param {string} props.placeholder -> The text that shows up inside the textbox before there is a value
 * @param {boolean} props.required -> If true, add a small red star next to the label. Right now there is not functionality to ensure data is entered, or to validate data, though those things should be added sometime
 * @param {boolean} props.disabled -> If true, the input field will be grayed out, and no data will be able to be entered
 * @param {boolean} props.multiline -> convert the element from `input` to `textarea`. You cannot currently specify how many lines the textarea takes.
 * @param {string} props.value -> The current input value. This will update with each character typed
 * @param {function} props.onChange -> The function that runs each time a character is typed inside of the input field. The function received the `value` directly, NOT the `event` of the input
 * @param {function} props.onFocus -> The function that runs each time the input gains focus
 * @param {string} props.type -> Input type for the field. ie `email`,`date`, `number`
 * @param {string} props.className -> Add additional class names to input field
 * @param {bool} props.copiable -> Adds a `copy` button in the input field that can be clicked to capture the contents. This has no effect on multiline fields.
 * @param {node} props.iconRight -> Add an icon to the right-side of the input field (where the copiable button usually is)
 *
 * @description This is the input field component. It handles both `input` and `textarea` fields. It has common styling and functionality, so should be used any time user input is required.
 */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';

// This function was taken from https://www.davedrinks.coffee/how-do-i-use-two-react-refs/
// It's purpose is to allow multiple refs on the same element. This was added when I was working on accessibility things to make sure that input fields were correctly being focused when the user tried to submit a form but had an error (the first input field with an error should focus so the user doesn't have to scroll to find which piece of entered data was incorrect), but to also allow our custom InputField component to have a local ref for the copiable button fields.
// I don't know how it works, only that it does 💪
const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return inst => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

const InputField = forwardRef(
  (
    {
      error,
      label,
      placeholder,
      required,
      disabled,
      multiline,
      value,
      onChange,
      onFocus,
      type,
      className,
      copiable,
      iconRight,
      ...props
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = React.useState('');
    const inputField = React.useRef(null);

    return (
      <div className={`InputField ${multiline ? 'TextArea' : ''} ${className || ''}`}>
        {label && (
          <label className="input-field-label" htmlFor={`${label}-input`}>
            {label} {required && <span className="required">*</span>}{' '}
            {error && (
              <p className="input-error" id={label}>
                {typeof error === 'string' ? error : 'There was an issue with this field'}
              </p>
            )}
          </label>
        )}

        {multiline ? (
          <textarea
            className={`text-area ${disabled ? 'disabled' : ''}`}
            id={`${label}-input`}
            value={value || inputValue}
            onChange={evnt => {
              if (onChange) {
                onChange(evnt.target.value);
              }

              setInputValue(evnt.target.value);
            }}
            ref={mergeRefs(inputField, ref)}
            required={required}
            disabled={disabled}
            onFocus={onFocus}
            aria-describedby={label}
            aria-required={required}
            aria-invalid={!!error}
            {...props}
          />
        ) : type === 'phone' ? (
          <PhoneInput
            value={value || inputValue}
            id={`${label}-input`}
            onChange={value => {
              // fields that are copiable are not editable
              if (copiable) {
                return null;
              }

              if (onChange) {
                onChange(value);
              }

              setInputValue(value);
            }}
            ref={ref}
            defaultCountry="US"
            flags={flags}
            required={required}
            disabled={disabled}
            className={`phone ${disabled ? 'disabled' : ''}`}
            aria-describedby={label}
            aria-required={required}
            aria-invalid={!!error}
            {...props}
          />
        ) : (
          <input
            className={`input-field ${disabled ? 'disabled' : ''}`}
            id={`${label}-input`}
            value={value || inputValue}
            placeholder={placeholder}
            type={type}
            ref={mergeRefs(inputField, ref)}
            onChange={evnt => {
              let text = evnt.target.value;

              // fields that are copiable are not editable
              if (copiable) {
                return null;
              }

              if (type === 'email') {
                text = text.toLowerCase();
              }

              if (onChange) {
                onChange(text);
              }

              setInputValue(text);
            }}
            required={required}
            disabled={disabled}
            onFocus={onFocus}
            aria-describedby={label}
            aria-required={required}
            aria-invalid={!!error}
            {...props}
          />
        )}

        {copiable && !multiline && (
          <div className="copy-btn">
            <button
              onClick={() => {
                inputField.current.select();
                document.execCommand('copy');
              }}
            >
              Copy
            </button>
          </div>
        )}

        {iconRight && !multiline && <div className="icon-right">{iconRight}</div>}
      </div>
    );
  },
);

InputField.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  copiable: PropTypes.bool,
  iconRight: PropTypes.node,
};

export default InputField;
