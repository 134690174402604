import React, { useContext, useState, useEffect, useCallback } from 'react';
import DetectRTC from 'detectrtc';

import Context from '../MyContext';
import { ViewContext, ConversationContext } from '../providers';

import Header from '../components/Header';
import GroupMessagePreview from '../components/GroupMessagePreview';

function Group({ location, history }) {
  const context = useContext(Context);
  const { setShowSidebar } = useContext(ViewContext);
  const { conversationFolders, conversationVideos, currentConversation } = useContext(
    ConversationContext,
  );

  const [selectedView, setSelectedView] = useState('TOPICS');
  const [newDM, setNewDM] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    (async () => {
      const { activeGroup } = context.state;

      if (!activeGroup || !Object.keys(activeGroup).length) {
        history.push('/');

        return null;
      }

      if (activeGroup.members) {
        setMembers(activeGroup.members);
      } else {
        const members = await context.fetchGroupUsers(false, null, activeGroup.id);

        setMembers(members);
      }

      // set view if needed
      if (location.state && location.state.view === 'DIRECT_MESSAGES') {
        setSelectedView(location.state.view);
      }

      fetchConversations();
    })();
  }, [context.state.activeGroup]);

  function fetchConversations() {
    let conversations = [];

    if (selectedView === 'TOPICS') {
      conversations = conversationFolders;
    } else {
      conversations = context.state.directMessages;
    }

    handleNewDirectMessageIndicator();

    // format direct message conversation information so the GroupMessagePreview component doesn't need any conditional logic in rendering
    if (selectedView === 'DIRECT_MESSAGES') {
      conversations = conversations.map(conversation => {
        conversation.lastVideoDate = conversation.lastUpdatedAt;

        conversation.previewVideos = conversation.previewVideos
          .map(video => {
            const conversationVideo = conversation.messages.find(vid => {
              if (video && vid && vid.video && vid.video.id === video.id) {
                return true;
              }

              return false;
            });

            video.publishedAt = conversationVideo ? conversationVideo.createdAt : null;

            if (video.publishedAt) {
              return video;
            }
          })
          .filter(video => video);

        return conversation;
      });
    }

    setConversations(conversations);
  }

  function handleNewDirectMessageIndicator() {
    if (!newDM) {
      context.state.directMessages.forEach(message => {
        message.previewVideos.forEach(video => {
          if (!video.isWatched) {
            setNewDM(true);
          }
        });
      });
    }
  }

  // when the page loads on desktop, the sidebar should be open
  useEffect(() => {
    DetectRTC.load(() => {
      if (!DetectRTC.isMobileDevice) {
        setShowSidebar(true);
      }
    });
  }, []);

  // when the user clicks between topics and direct messages, render the correct videos
  useEffect(() => {
    fetchConversations();
  }, [selectedView, context.state.topics, context.state.directMessages, conversationFolders]);

  return (
    <div className="Group">
      <Header
        activeGroup={context.state.activeGroup}
        memberCount={members ? members.length : 1}
        setSelectedView={setSelectedView}
        selectedView={selectedView}
        hasNewDM={newDM}
      />

      <GroupMessagePreview
        currentConversation={currentConversation}
        conversations={conversations}
        videos={conversationVideos}
        view={selectedView}
        members={members}
      />
    </div>
  );
}

export default Group;
