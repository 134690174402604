import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '../images/buttons/close.svg';

function SmallModal({ isOpen, close, shouldOverlayClose, children, position = 10, noWidth, tall }) {
  const modalRoot = document.getElementById('modal-root');

  return isOpen
    ? ReactDOM.createPortal(
        <div
          className={`SmallModal${noWidth ? ' no-width' : ''}`}
          style={{ zIndex: 10000000 - position }}
        >
          <div
            className="overlay"
            onClick={() => {
              if (shouldOverlayClose) {
                close();
              }
            }}
          />

          <div title="Close Modal" className="close-button" onClick={close}>
            <CloseIcon />
            <p>Close</p>
          </div>

          <div className={`content${tall ? ' tall' : ''}`}>
            <div className="children">{children}</div>
          </div>
        </div>,
        modalRoot,
      )
    : '';
}

SmallModal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  close: PropTypes.func.isRequired,
  shouldOverlayClose: PropTypes.bool,
  children: PropTypes.node.isRequired,
  position: PropTypes.number,
  noWidth: PropTypes.bool,
  tall: PropTypes.bool,
};

SmallModal.defaultProps = {
  isOpen: false,
  shouldOverlayClose: false,
};

export default SmallModal;
