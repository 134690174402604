import React, { useState, useEffect, useCallback } from 'react';
import DecibelMeter from 'decibel-meter';
import DetectRTC from 'detectrtc';

function AudioLevel({ audioDevice, newLevel }) {
  const meter = new DecibelMeter();

  const [audioLevel, setAudioLevel] = useState(0);

  const changeMeterDisplay = useCallback(
    newLevel => {
      if (newLevel >= 100) {
        if (audioLevel !== 5) {
          setAudioLevel(5);
        }
      } else if (newLevel >= 80) {
        if (audioLevel !== 4) {
          setAudioLevel(4);
        }
      } else if (newLevel >= 60) {
        if (audioLevel !== 3) {
          setAudioLevel(3);
        }
      } else if (newLevel >= 40) {
        if (audioLevel !== 2) {
          setAudioLevel(2);
        }
      } else if (newLevel >= 20) {
        if (audioLevel !== 1) {
          setAudioLevel(1);
        }
      } else {
        if (audioLevel !== 0) {
          setAudioLevel(0);
        }
      }
    },
    [newLevel],
  );

  useEffect(() => {
    (async () => {
      if (audioDevice) {
        if (meter && meter.listening) {
          await meter.disconnect();
        }

        DetectRTC.load(async () => {
          if (DetectRTC.isWebsiteHasMicrophonePermissions) {
            const devices = await navigator.mediaDevices.enumerateDevices();

            devices
              .filter(device => device.kind === 'audioinput')
              .forEach(device => {
                // this will prevent the meter from trying to listen to a device that no longer exists (external devices)
                if (device.deviceId === audioDevice.deviceId) {
                  meter.listenTo(audioDevice.deviceId, (_, percent) => changeMeterDisplay(percent));
                }
              });
          }
        });
      }
    })();
  }, [audioDevice, changeMeterDisplay]);

  return (
    <div className="AudioLevels">
      <div className={`volume${audioLevel >= 5 ? ' active' : ''}`} />
      <div className={`volume${audioLevel >= 4 ? ' active' : ''}`} />
      <div className={`volume${audioLevel >= 3 ? ' active' : ''}`} />
      <div className={`volume${audioLevel >= 2 ? ' active' : ''}`} />
      <div className={`volume${audioLevel >= 1 ? ' active' : ''}`} />
    </div>
  );
}

export default AudioLevel;
