import React from 'react';
import MyContext from '../../MyContext';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import Button from '../Button';
import InputField from '../InputField';

class TagUsers extends React.Component {
  state = {
    users: [],
    filteredUsers: [],
    selectedUsers: [],
    loading: false,
    search: '',
  };

  async componentDidMount() {
    if (this.props.directMessageId) {
      await this.getDirectMessageMembers(this.props.directMessageId);
    } else {
      await this.getGroupUsers(this.props.groupId);
    }
  }

  async getDirectMessageMembers(directMessageId) {
    if (!this.context.state.directMessages || !this.context.state.directMessages.length) {
      await this.context.getDirectMessages();
    }
    const dm =
      this.context.state.directMessages.find(dm => dm.directMessageId === directMessageId) || {};
    this.setState({ users: dm.members || [] });
  }

  async getGroupUsers(groupId) {
    let users = this.context.state.users;

    if (!users || !users.length) {
      this.setState({ loading: true });
      users = await this.context.fetchGroupUsers(false, null, groupId);
      this.setState({ loading: false });
    }

    this.setState({
      users: users || [],
    });
  }

  tagUsers = async () => {
    this.setState({ loading: true });
    const userIds = this.state.selectedUsers.map(user => user.id);
    await this.context.tagUsers(this.props.videoId, userIds);
    this.setState({ loading: false });
    this.props.toggleModal(false);
  };

  filterUsers = value => {
    let filteredUsers = this.state.users;

    if (value) {
      filteredUsers = filteredUsers.filter(user => {
        const name = (user.firstName || '') + (user.lastName || '');

        if (name.toLowerCase().includes(value.toLowerCase())) {
          return true;
        }

        return false;
      });
    }

    this.setState({
      search: value,
      filteredUsers: filteredUsers,
    });
  };

  render() {
    const { search, users, filteredUsers } = this.state;
    const usersArray = search ? filteredUsers : users;

    const mapUsers = usersArray.map(user => {
      const isSelected = this.state.selectedUsers.includes(user);
      const img = user.profileImageUrl;

      return (
        <tr key={user.id}>
          <td
            onClick={() => {
              if (isSelected) {
                const newSelected = this.state.selectedUsers.filter(usr => usr.id !== user.id);

                this.setState({
                  selectedUsers: newSelected,
                });
              } else {
                this.setState({
                  selectedUsers: [...this.state.selectedUsers, user],
                });
              }
            }}
            className={isSelected ? 'selected' : ''}
          >
            <img src={img} className="user-profile-picture" alt="" />
            {`${user.firstName || ''} ${user.lastName || ''}`}
            <span className={`selected-icon ${isSelected ? 'selected' : ''}`}>&#10003;</span>
          </td>
        </tr>
      );
    });

    return (
      <Modal
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        className="TagUsersModal"
      >
        <h3>Tag Users</h3>
        <InputField
          className="tag-users-search"
          label="Search Users"
          onChange={this.filterUsers}
          value={this.state.search}
        />
        <div className="table-container">
          <table className="tag-users-table">
            <tbody>
              {search && !filteredUsers.length ? (
                <tr>
                  <td>No Users Found</td>
                </tr>
              ) : (
                mapUsers
              )}
            </tbody>
          </table>
        </div>
        <Button
          label="Tag Users"
          action={this.tagUsers}
          loading={this.state.loading}
          disabled={!this.state.selectedUsers.length}
        />
      </Modal>
    );
  }
}

TagUsers.contextType = MyContext;

TagUsers.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
};

export default TagUsers;
